import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PlayerHeader } from 'component/header';

export const VideoPlayerPage = () => {
  const { id } = useParams();
  const [src, setSrc] = useState<string>();

  useLayoutEffect(() => {
    if (!id) return;

    setSrc(`${process.env.REACT_APP_CF_ENDPOINT}/videos/${id}.mp4`);
  }, [id]);

  return (
    <Container>
      <PlayerHeader />
      <VideoContainer>
        <Video controls src={src} />
      </VideoContainer>
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 100px);
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;
const Video = styled.video`
  height: 80vh;
`;
