import React, {
  DragEvent,
  DragEventHandler,
  TouchEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress, Slider } from '@mui/material';
import { curry, debounce, isNil, min } from 'lodash-es';
import { observer } from 'mobx-react';
import { AudioApi } from 'shutterstock-api';
import { useStores } from 'store';
import styled from 'styled-components';

import { NonSelectable } from 'util/css';
import { isMobile } from 'util/mobile';
import { Search } from 'component/input';
import { useSearchParamStore } from 'store/router';
import { userStore } from 'store/user';

interface ITrack {
  title: string;
  description: string;
  url: string;
  thumbnail: string;
  duration: number;
}

/** 마우스 드래그 또는 다운을 했을 때 사용하는 데이터 */
class MouseData {
  /** 마우스 이벤트에서 얻은 clientX좌표 */ clientX = 0;
  /** 마우스 이벤트에서 얻은 clientY좌표 */ clientY = 0;
  /** 해당 엘리먼트를 기준으로 상대적인 mouse x 좌표 */ mouseX = 0;
  /** 해당 엘리먼트를 기준으로 상대적인 mouse y 좌표 */ mouseY = 0;
  /** 해당 엘리먼트를 기준으로 상대적인 mouse x 시작 좌표 */ mouseStartX = 0;
  /** 해당 엘리먼트를 기준으로 상대적인 mouse y 시작 좌표 */ mouseStartY = 0;
  /** 마우스 다운 또는 드래그 시작을 기준으로 지정된 클라이언트 좌표 */ startClientX = 0;
  /** 마우스 다운 또는 드래그 시작을 기준으로 지정된 클라이언트 좌표 */ startClientY = 0;
  /** 마우스 다운 여부 */ isMouseDown = false;
  /** 해당 엘리먼트의 영역을 기준으로 마우스 다운 시작 좌표가 X축으로 얼마나 퍼센트 만큼 떨어져있는지에 대한 값 */ percentPositionStartX = 0;
  /** 해당 엘리먼트의 영역을 기준으로 마우스 다운 시작 좌표가 Y축으로 얼마나 퍼센트 만큼 떨어져있는지에 대한 값 */ percentPositionStartY = 0;
  /** 해당 엘리먼트의 영역을 기준으로 X축으로 얼마나 퍼센트 만큼 떨어져있는지에 대한 값 */ percentPositionX = 0;
  /** 해당 엘리먼트의 영역을 기준으로 Y축으로 얼마나 퍼센트 만큼 떨어져있는지에 대한 값 */ percentPositionY = 0;

  /** 마우스가 누른것을 간접적으로 파악하기 위한 변수 (그래서 음악 타이틀로 구분하는것) */ musicTitle =
    '';

  /**
   * 마우스를 다운 또는 드래그 시작시에 지정되는 좌표 설정 (어디를 시작지점으로 하는지 지정하는 함수임)
   * (마우스 이벤트에서 얻은 클라이언트 좌표와, 해당 엘리먼트를 입력해주세요.)
   *
   * @param eventTarget 이벤트의 타겟 (참고: 여기서 들어오는것은 해당 HTMLDOM객체이나 타입을 정확하게 지정할 수 없음)
   */
  setMouseStartXY(
    eventTarget: EventTarget | any,
    clientX: number,
    clientY: number,
  ) {
    this.isMouseDown = true;
    this.startClientX = clientX;
    this.startClientY = clientY;

    // if (eventElement.getBoundingClientRect)

    let rect = eventTarget.getBoundingClientRect();
    this.mouseStartX = clientX - rect.left;
    this.mouseStartY = clientY - rect.top;

    this.percentPositionStartX = (this.mouseStartX / rect.width) * 100;
    this.percentPositionStartY = (this.mouseStartY / rect.height) * 100;
  }

  /**
   * 마우스의 클라이언트 좌표 설정 (마우스 이벤트에서 얻은 클라이언트 좌표와, 해당 엘리먼트를 입력해주세요.)
   * @param eventTarget 이벤트의 타겟 (참고: 여기서 들어오는것은 해당 HTMLDOM객체이나 타입을 정확하게 지정할 수 없음)
   */
  setMouseClientXY(
    eventTarget: EventTarget | any,
    clientX: number,
    clientY: number,
  ) {
    this.clientX = clientX;
    this.clientY = clientY;

    let rect = eventTarget.getBoundingClientRect();
    this.mouseX = clientX - rect.left;
    this.mouseY = clientY - rect.top;

    this.percentPositionX = (this.mouseX / rect.width) * 100;
    this.percentPositionY = (this.mouseY / rect.height) * 100;
  }

  setMouseEnd() {
    this.isMouseDown = false;
  }
}

interface ShutterStockMenuProps {}
export const ShutterStockMenu = observer(({}: ShutterStockMenuProps) => {
  const [params, setParams] = useSearchParamStore();
  const { uiStore, videoStore, timelineStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const q = params.q ?? '';
  const [tracks, setTracks] = useState<ITrack[]>([]);
  const [expand, setExpand] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);

  const mobile = isMobile();
  const previewAudio = useRef<HTMLAudioElement>(new Audio());
  const mouseData = useRef<MouseData>(new MouseData());
  const [forceUpdate, setForceUpdate] = useState(0);
  const watermark = useRef<HTMLAudioElement>(new Audio('/watermark.mp3'));

  /** 선택 안함 */ const NOT_SELECT = 'Not Select';
  /** bpmFrom: 해당 bpm이상 (bpmFrom ~ bpmTo) */ const bpmFromRef = useRef(60);
  /** bpmTo: 해당 bpm이하 (bpmFrom ~ bpmTo) */ const bpmToRef = useRef(240);
  const [selectMenu, setSelectMenu] = useState('');
  const selectGenre = useRef(NOT_SELECT);
  const selectMoods = useRef(NOT_SELECT);

  const bpmFromInputRef = useRef<HTMLInputElement>(null);
  const mouseDataBpm = useRef<MouseData>(new MouseData());

  /** 비어있는 이미지 (드래그 미리보기 이미지 제거 용도로 사용됨) */
  const blankImage = useRef<HTMLImageElement>(null);

  const MENU_GENRE = 'genre';
  const MENU_MOODS = 'moods';
  const MENU_BPM = 'bpm';

  /** 장르 목록 (검색 가능한 전체 범위) */
  const genreList = [
    NOT_SELECT,
    'Dance / Techno',
    'Dance/Electronic',
    'All Vocals',
    'Pop',
    'Production / Film Scores',
    'Pop/Rock',
    'Production',
    'Samples / Effects',
    'Games',
    'Oohs & Aahs',
    'Corporate',
    'Electronic',
    'Rock',
    'Electro Pop',
    'Folk',
    'Indie Pop',
    'Vocals',
    'Choir / Group',
    'R&B',
    'Hip Hop',
    'Chill Out',
    'World',
    'R&B/Soul',
    'Lead Vocals',
    'Trailer',
    'Classical',
    'World/International',
    'Holiday',
    'Jazz',
    'Kids / Children',
    'Country',
    'New Age',
    'Latin',
    'Children',
    'Easy Listening',
    'Masterworks',
    'Audio Logos',
    'Blues',
    'Punk/Metal',
    'Dubstep',
    'News',
    'Piano / Solo Instrumental',
    'Reggae',
    'Reggae/Ska',
    'Piano/Solo Instrumental',
    'Bluegrass',
    'Independent',
  ];

  /** 무드 목록 (검색 가능한 전체 범위) */
  const moodsList = [
    NOT_SELECT,
    'Feel Good',
    'Confident',
    'Happy',
    'Bright',
    'Fun',
    'Playful',
    'Uplifting',
    'Inspiring',
    'Optimistic',
    'Fashion / Lifestyle',
    'Epic / Orchestral',
    'Sophisticated',
    'Stylish',
    'Gentle / Light',
    'Mellow',
    'Intense',
    'Calm',
    'Epic',
    'Fragile',
    'Adventure / Discovery',
    'Dramatic',
    'Happy / Cheerful',
    'Action / Sports',
    'Cinematic',
    'Hopeful',
    'Arousing',
    'Sci-Fi / Future',
    'Energetic',
    'Carefree',
    'Cheerful',
    'Merry',
    'Romantic / Sentimental',
    'Love',
    'Tender',
    'Romantic',
    'Sexy / Sensual',
    'Sad / Nostalgic',
    'Sensual',
    'Sexy',
    'Dark / Somber',
    'Dreamy',
    'Ethereal',
    'Underscores',
    'Bleak',
    'Dark',
    'Bittersweet',
    'Heartbroken',
    'Melancholic',
    'Reflective',
    'Remorseful',
    'Sad',
    'Wistful',
    'Magical / Mystical',
    'Suspense / Drama',
    'Mysterious',
    'Fiery',
    'Worry',
    'Aggressive',
    'Relaxation / Meditation',
    'Angry',
    'Serene',
    'Atmospheric',
    'Meditative',
    'Soothing',
    'Comedy / Funny',
    'Military / Patriotic',
    'Crime / Thriller / Spy',
    'Quirky',
    'Wedding',
    'Aerobics / Workout',
    'Silly',
    'Horror / Scary',
    'Religious / Christian',
    'Eerie',
    'Fear',
    'Terror',
    'Spiritual',
    'Strange / Bizarre',
  ];

  /**
   * 메뉴 설정 (장르, 무드를 설정할 때 사용하는 함수)
   *
   * 메뉴 값은, 상수로 정의되어있음. MENU_ 변수 참고
   */
  const selectMenuInput = (menu: string, targetText: string) => {
    if (menu === MENU_GENRE) {
      selectGenre.current = targetText;
    } else if (menu === MENU_MOODS) {
      selectMoods.current = targetText;
    }

    search(q);
    selectMenuCall();
  };

  /** 메뉴를 호출해서 세부 목록을 보여줌, 메뉴가 공백일경우, 기존 메뉴가 사라짐 */
  const selectMenuCall = (menu: string = '') => {
    if (selectMenu === menu) {
      setSelectMenu('');
    } else {
      setSelectMenu(menu);
    }
  };

  const inputBpm = (bpmFrom: number, bpmTo: number) => {
    bpmToRef.current = bpmTo;
    bpmFromRef.current = bpmFrom;
    bpmMinMaxChange();
    setForceUpdate(forceUpdate + 1);
  };

  function GenreListComponent() {
    return (
      <TrackButtonMenu>
        {/* GenreComponent의 리스트 값들을 이용하여 객체를 만듬 */}
        {genreList.map((value, index) => {
          return (
            <TrackButtonMenuList
              onClick={() => selectMenuInput(MENU_GENRE, value)}
              key={index}
            >
              {' '}
              {value === selectGenre.current ? '>> ' + value : value}{' '}
            </TrackButtonMenuList>
          );
        })}
      </TrackButtonMenu>
    );
  }

  function MoodsListComponent() {
    return (
      <TrackButtonMenu>
        {moodsList.map((value, index) => {
          return (
            <TrackButtonMenuList
              onClick={() => selectMenuInput(MENU_MOODS, value)}
              key={index}
            >
              {' '}
              {value === selectGenre.current ? '>> ' + value : value}{' '}
            </TrackButtonMenuList>
          );
        })}
      </TrackButtonMenu>
    );
  }

  function BpmComponentTest21() {
    return (
      <TrackButtonMenu>
        <Slider
          defaultValue={bpmFromRef.current}
          max={300}
          min={0}
          onChangeCommitted={(e, value) => {
            if (typeof value === 'number') {
              inputBpm(value, bpmToRef.current);
            }
          }}
        />
        <Slider
          defaultValue={bpmToRef.current}
          max={300}
          min={0}
          onChangeCommitted={(e, value) => {
            if (typeof value === 'number') {
              inputBpm(bpmFromRef.current, value);
            }
          }}
        />
        <span>
          BPM: {bpmFromRef.current} ~ {bpmToRef.current}
        </span>
        <button
          type="button"
          style={{ backgroundColor: 'lightsteelblue' }}
          onClick={() => selectMenuInput('', '')}
        >
          SUBMIT
        </button>
      </TrackButtonMenu>
    );
  }

  const onBeginHoverTrack = (track: ITrack) => {
    if (!previewAudio.current.paused) {
      previewAudio.current.pause();
    }
    previewAudio.current.src = track.url;

    // audio.play를 할 때 promise가 리턴되고, catch부분을 처리하지 않으면 오류메세지가 발생됩니다.
    // 물론 무시할 수 있지만, 거슬린다면 catch 부분을 처리해주셔야 합니다.
    previewAudio.current.play().catch(() => {
      // 이 메세지는 지워도 됩니다. 다만 설명을 위해 남겨두었습니다.
      // console.log('현재 오디오를 재생할 수 없습니다. 잠시 기다려주세요.')
    });

    watermark.current.loop = true;
    watermark.current.play().catch(() => {});

    // 사용자의 배경 음악 설정을 임시로 제거하고, 현재 트랙에 맞는 배경 정보를 넣도록 처리
    videoStore._updateMixer(
      [],
      timelineStore.effects,
      isNil(userStore.user) || userStore.user!.level === 0,
    );

    // videoStore.pause(); // 사용자의 요청에 따라 다른 트랙이 미리듣기되어도 비디오가 정지되지 않습니다.
  };
  const onEndHoverTrack = () => {
    previewAudio.current.src = '';
    previewAudio.current.pause();
    watermark.current.pause();
    watermark.current.currentTime = 0;

    // 사용자의 배경 음악 설정을 다시 복원
    videoStore._updateMixer(
      timelineStore.backgroundSounds,
      timelineStore.effects,
      isNil(userStore.user) || userStore.user!.level === 0,
    );
  };

  /** 타임라인 데이터들을 살펴본 후 비어있는 공간의 시작지점을 계산합니다.  */
  const getTimelineBlankTime = () => {
    // 지금까지 등록된 타임라인스토어의 데이터들을, 시작 시간 순서대로 정렬
    timelineStore.backgroundSounds.sort((a, b) => {
      if (a.start < b.start) {
        return -1;
      } else if (a.start === b.start) {
        return 0;
      } else {
        return 1;
      }
    });

    // 그리고, 비어있는 공간의 시작시간과 끝 시간을 계산
    let blankStart = 0;
    let blankEnd = Number.MAX_SAFE_INTEGER; // 임의의 최대 숫자
    for (let i = 0; i < timelineStore.backgroundSounds.length; i++) {
      let current = timelineStore.backgroundSounds[i];
      if (timelineStore.backgroundSounds.length === 1) {
        blankStart = current.end;
        break;
      }

      // 시작시간이 0인경우, 해당 구간은 공간이 채워져있으므로 다음 구간으로 이동
      if (current.start === 0) {
        blankStart = current.end;
      } else if (current.start === blankStart) {
        // 시작시간과 블랭크 시작시간이 같다면,
        // 비어있지 않은 공간이므로 다음 구간으로 이동
        blankStart = current.end;
      } else if (i < timelineStore.backgroundSounds.length - 1) {
        // i가 마지막이 아닌 경우
        // 시작시간과 블랭크 시작시간이 다르다면, 여기서는 비어있는공간임
        // 앞에서 시간순으로 정렬했으므로, 다음 구간의 시작지점부터 공간이 채워지는걸 알 수 있고
        // 이에따라 블랭크의 끝 지점은 다음 구간의 시작지점이 됨
        // 이 시점에서 더이상 루프를 돌 필요는 없음
        blankEnd = current.start;
        break;
      } else {
        // i가 마지막인경우, 두가지 옵션을 처리함.
        // 이전에 공간이 비어있는경우, 그 경우에는 이전공간을 처리함.
        if (current.start > blankStart) {
          blankEnd = current.start;
        } else {
          // 아닌경우 다음 비어있는 공간의 시작이 해당 음악의 마지막으로 변경
          blankEnd = current.end;
        }
        break;
      }
    }

    return {
      blankStart,
      blankEnd,
    };
  };

  const onClickItem = async (x: ITrack) => {
    const videoDuration = videoStore.getDuration();

    // const last =
    //   timelineStore.backgroundSounds.length === 0
    //     ? 0
    //     : Math.max(...timelineStore.backgroundSounds.map(x => x.end));

    /** 비어있는 공간의 시간 */
    const blankTime = getTimelineBlankTime();

    /** 음악의 재생 길이: 영상의 길이를 초과할 수 없음 */
    let targetDuration =
      x.duration < videoDuration ? x.duration : videoDuration;

    // 동영상의 길이를 초과한경우, 그만큼 타겟 길이를 줄임
    if (blankTime.blankStart + targetDuration > videoDuration) {
      targetDuration = videoDuration - blankTime.blankStart;
      if (targetDuration < 1) {
        console.warn(
          'shutterStockMenu: 음악을 더 넣을 공간이 없습니다. 다른 음악을 삭제하거나 길이를 조정해주세요.',
        );
        return;
      }
    }

    // 다음 블랭크 구간의 길이를 초과한경우, 블랭크 구간을 초과하지 않도록 길이를 다시 변경
    if (blankTime.blankStart + targetDuration > blankTime.blankEnd) {
      targetDuration = blankTime.blankEnd - blankTime.blankStart;
      if (targetDuration < 1) {
        console.warn(
          'shutterStockMenu: 음악을 더 넣을 공간이 없습니다. 다른 음악을 삭제하거나 길이를 조정해주세요.',
        );
        return;
      }
    }

    console.log(
      'onclickItem -> blankStart: ' +
        blankTime.blankStart +
        ', blankEnd: ' +
        blankTime.blankEnd +
        ', videoDuration: ' +
        videoDuration +
        ', targetDuration(Music): ' +
        targetDuration +
        ', baseDuration(Music): ' +
        x.duration,
    );

    timelineStore.addBackgroundSound({
      name: x.title,
      volume: 0.25,
      start: blankTime.blankStart,
      end: blankTime.blankStart + targetDuration,
      musicDuration: x.duration,
      url: x.url,
      channel: 0,
      rangeStart: 0,
      rangeEnd: targetDuration,
      rangeDuration: targetDuration,
      rangeEndback: 0,
      fadeIn: 3,
      fadeOut: 3,
    });

    if (mobile) {
      uiStore.showSidebar = false;
    }
  };

  const durationToMinuteSecond = (value: number) => {
    let minute = Math.floor(value / 60);
    let second = value % 60;
    let secondText = ('' + second).padStart(2, '0');

    return minute + ':' + secondText;
  };

  const getTrackDurationText = (value: number, title: string) => {
    let timeText = durationToMinuteSecond(value);
    let dragText = '';

    // mouse가 눌린 상태이고, musicTitle이 마우스가 눌렸을 때 지정된것 과 같다면, 표시 형식을 변경
    if (
      mouseData.current.isMouseDown &&
      title === mouseData.current.musicTitle
    ) {
      // 퍼센트로 환산한 값을 시간값으로 변경
      let start = Math.floor(
        (value / 100) * mouseData.current.percentPositionStartX,
      );
      let end = Math.floor((value / 100) * mouseData.current.percentPositionX);

      // 최대 최소 제한 (start, end가 서로 반전될 수도 있으므로, 전부 검사함)
      if (start < 0) start = 0;
      if (end < 0) end = 0;
      if (start > value) start = value;
      if (end > value) end = value;

      // 텍스트 추가 (start, end의 크기에 따라 서로 다르게 출력)
      if (start <= end) {
        dragText =
          '(' +
          durationToMinuteSecond(start) +
          ' ~ ' +
          durationToMinuteSecond(end) +
          ')';
      } else {
        dragText =
          '(' +
          durationToMinuteSecond(end) +
          ' ~ ' +
          durationToMinuteSecond(start) +
          ')';
      }
    }

    return timeText + dragText;
  };

  const loadMore = useCallback(
    async (q: string, page: number) => {
      const PerPage = 20;

      if (isLoading) {
        return;
      }

      try {
        setIsLoading(true);
        bpmMinMaxChange(); // 만약 bpm의 최대 최소가 반전되어있다면, 이를 강제로 변경함.
        const audioApi = new AudioApi();
        const queryParams = {
          query: q,
          view: 'full',
          sort: 'score',
          page,
          per_page: PerPage,
          bpm_from: bpmFromRef.current,
          bpm_to: bpmToRef.current,

          // 참고: 장르 선택은, 배열로 전달해야 하는데, 값이 올바르지 않으면 아무런 검색결과가 없습니다.
          // 만약, 장르가 없다면, 배열이 아닌 string값을 넣어주세요.
          // NOT_SELECT 로 선택된 경우, 장르는 없는것으로 처리
          // 장르는 영어만 인정됩니다. (한글 사용 불가능)
          // moods도 장르랑 동일한 방식임
          genre:
            selectGenre.current === NOT_SELECT ? '' : [selectGenre.current],
          moods:
            selectMoods.current === NOT_SELECT ? '' : [selectMoods.current],
        };

        const { data } = await audioApi.searchTracks(queryParams);

        setPage(page);
        setHasNextPage(data.length === PerPage);
        setTracks(prev => [
          ...prev,
          ...data.map((x: any) => ({
            title: x.title,
            description: x.description,
            url: x.assets.preview_mp3.url,
            thumbnail: x.assets.waveform.url,
            duration: x.duration,
          })),
        ]);

        console.log(data);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading],
  );

  const search = useCallback(
    debounce(async (q: string) => {
      setPage(1);
      setHasNextPage(true);
      setTracks([]);

      loadMore(q, 1);
    }, 500),
    [],
  );

  // 어떤 이유인지는 모르겠으나, setState를 사용한 변수들은 다른곳에서는 인식을 못하고
  // 이 useEffect 함수 내에서만 제대로 인식됨
  useEffect(() => {
    search(q);
  }, [q]);

  useEffect(() => {
    async function createGenre() {
      // 장르 캐싱 및, 랜덤한 장르 선택 (테스트용 코드)
      // const audioApi = new AudioApi();
      // const { data } = await audioApi.listGenres({language: 'en'});
      // const data3 = await audioApi.listMoods({language: 'en'});
      // setgenreList(data);
      // console.log(data)
      // console.log(data3)
      // 배열을 텍스트로 바로 복사붙여넣기 위해 만든 코드
      // let text = '['
      // for (let i = 0; i < data3.data.length; i++) {
      //   let split = data3.data[i].split('>')
      //   if (split.length === 1) {
      //     text += `'${split[0]}', `
      //   }
      //   if (i % 6 === 0) {
      //     text += '\n'
      //   }
      // }
      // text += ']'
      // console.log(text)
    }
    createGenre();

    // 오디오 crossOrigin 무시하도록 변경 (처음 실행 시만 동작)
    previewAudio.current.crossOrigin = 'anonymous';

    return () => {
      // 컴포넌트가 닫히면 오디오 정지
      previewAudio.current.pause();
      previewAudio.current.src = '';
      watermark.current.pause();
      watermark.current.src = '';
    };
  }, []);

  const onDragStart = (e: DragEvent, title: string) => {
    if (e.currentTarget != null) {
      mouseData.current.setMouseStartXY(e.currentTarget, e.clientX, e.clientY);
      mouseData.current.musicTitle = title;
    }

    if (blankImage.current != null) {
      e.dataTransfer.setDragImage(blankImage.current, 0, 0); // 파형 이미지 제거
    }

    setForceUpdate(forceUpdate + 1);
  };

  const onDrag = (e: DragEvent) => {
    if (!mouseData.current.isMouseDown) return;

    if (blankImage.current != null) {
      e.dataTransfer.setDragImage(blankImage.current, 0, 0); // 파형 이미지 제거
    }

    if (e.currentTarget != null) {
      mouseData.current.setMouseClientXY(e.currentTarget, e.clientX, e.clientY);
    }
    setForceUpdate(forceUpdate + 1);
  };

  const onDragEnd = () => {
    mouseData.current.setMouseEnd();
    setForceUpdate(forceUpdate + 1);

    // 기준이 되는 음악의 위치를 재생함 (로드 문제 때문에 값이 NaN이 될 수 있으므로, 이 경우 임의의 값을 넣음)
    let currentTime =
      (mouseData.current.percentPositionStartX / 100) *
      previewAudio.current.duration;
    if (isNaN(currentTime)) currentTime = 0;
    else if (currentTime < 0) currentTime = 0;
    else if (currentTime > previewAudio.current.duration)
      currentTime = previewAudio.current.duration - 1;

    previewAudio.current.currentTime = currentTime;
    if (previewAudio.current.paused) {
      previewAudio.current.play().catch(() => {});
    }

    if (watermark.current.paused) {
      watermark.current.play();
    }
  };

  const onTouchStart = (e: TouchEvent, title: string, track: ITrack) => {
    previewAudio.current.src = track.url;

    if (e.currentTarget != null && e.touches.length >= 1) {
      mouseData.current.setMouseStartXY(
        e.currentTarget,
        e.touches[0].clientX,
        e.touches[0].clientY,
      );
      mouseData.current.musicTitle = title;
    }

    setForceUpdate(forceUpdate + 1);
  };

  const onTouchMove = (e: TouchEvent) => {
    if (!mouseData.current.isMouseDown) return;

    if (e.currentTarget != null && e.touches.length >= 1) {
      mouseData.current.setMouseClientXY(
        e.currentTarget,
        e.touches[0].clientX,
        e.touches[0].clientY,
      );
    }

    setForceUpdate(forceUpdate + 1);
  };

  const onTouchEnd = () => {
    onDragEnd(); // 어차피 onDragEnd랑 역할이 같음
  };

  /** 마우스로 누른것과 같은 트랙인지를 확인함 */
  const getSameTrackTitle = (title: string) => {
    if (
      mouseData.current.isMouseDown &&
      title === mouseData.current.musicTitle
    ) {
      return true;
    } else {
      return false;
    }
  };

  /** 트랙 드래그 범위를 얻습니다. (css width, left 값을 얻어옴) */
  const getTrackDragRange = () => {
    const start = mouseData.current.percentPositionStartX;
    const end = mouseData.current.percentPositionX;
    let left = start <= end ? start : end;
    let width = start <= end ? end - start : start - end;

    return {
      left,
      width,
    };
  };

  const onMouseEnter = (x: ITrack) => {
    onBeginHoverTrack(x);
  };

  /** bpm이 변경되었을 때, bpmTo ~ bpmFrom의 값이 서로 반전되어있다면, 이를 보정함 */
  const bpmMinMaxChange = () => {
    if (bpmFromRef.current == null) return;
    if (bpmToRef.current == null) return;

    const bpmTo = Number(bpmToRef.current);
    const bpmFrom = Number(bpmFromRef.current);

    // 반전되어있는경우 (최대/최소가 서로 뒤바뀐 경우)만, 값을 보정
    if (bpmTo < bpmFrom) {
      bpmFromRef.current = bpmTo;
      bpmToRef.current = bpmFrom;
    }
  };

  return (
    <Container expand={expand}>
      <Search value={q} onChange={newQ => setParams({ q: newQ })} />

      {/* 드래그 시 이미지를 보이지 않게 하기 위한 이미지 태그 (임의 영역에 강제로 배치시킴, 보여지지 않음.) */}
      <img
        width={1}
        height={1}
        ref={blankImage}
        style={{ position: 'fixed', visibility: 'hidden' }}
      ></img>

      {/* 버튼 영역 */}
      <div>
        <TrackButton onClick={() => selectMenuCall(MENU_GENRE)}>
          {MENU_GENRE}
        </TrackButton>
        <TrackButton onClick={() => selectMenuCall(MENU_MOODS)}>
          {MENU_MOODS}
        </TrackButton>
        <TrackButton onClick={() => selectMenuCall(MENU_BPM)}>
          {MENU_BPM}
        </TrackButton>
      </div>

      <InfiniteScroll
        pageStart={0}
        loadMore={() => loadMore(q, page + 1)}
        hasMore={hasNextPage}
        loader={
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        }
        useWindow={false}
        style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
      >
        {tracks.map((x: ITrack) => (
          <TrackContainer
            key={x.title}
            onClick={() => {
              onEndHoverTrack();
              onClickItem(x);
            }}
            draggable={true}
            onDrag={e => onDrag(e)}
            onDragStart={e => onDragStart(e, x.title)}
            onDragEnd={e => onDragEnd()}
            onTouchStart={e => {
              // onBeginHoverTrack(x)
              onTouchStart(e, x.title, x);
            }}
            onTouchMove={e => onTouchMove(e)}
            onTouchEnd={e => onTouchEnd()}
            {...(mobile
              ? {}
              : {
                  onMouseEnter: () => onMouseEnter(x),
                  onMouseLeave: () => onEndHoverTrack(),
                })}
          >
            <TrackDragRange
              style={{
                visibility: getSameTrackTitle(x.title) ? 'visible' : 'hidden',
                left: getTrackDragRange().left + '%',
                width: getTrackDragRange().width + '%',
              }}
            ></TrackDragRange>
            <TrackImage crossOrigin="anonymous" src={x.thumbnail} />
            <TrackName>{x.title}</TrackName>
            <TrackDuration>
              {getTrackDurationText(x.duration, x.title)}
            </TrackDuration>
          </TrackContainer>
        ))}
      </InfiniteScroll>

      {/* 리스트 표시 영역 */}
      {selectMenu === MENU_GENRE && <GenreListComponent></GenreListComponent>}
      {selectMenu === MENU_MOODS && <MoodsListComponent></MoodsListComponent>}
      {selectMenu === MENU_BPM && <BpmComponentTest21></BpmComponentTest21>}
    </Container>
  );
});

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  gap: 6px;

  background: black;

  padding: 8px;

  overflow: auto;
`;

const TrackContainer = styled.div`
  position: relative;
  display: flex;

  width: 100%;
  height: 180px;

  background: white;

  border-radius: 8px;

  align-items: center;
  justify-content: center;

  ${NonSelectable}
`;

const TrackImage = styled.img`
  width: 90%;
  height: 160px;

  object-fit: cover;
  transition: all 0.2s ease;
  cursor: pointer;

  ${NonSelectable}

  :hover {
    filter: contrast(1.2);
    transform: scale(0.995);
  }
`;

const TrackName = styled.div`
  position: absolute;
  max-width: calc(100% - 32px);
  left: 8px;
  bottom: 8px;

  color: white;

  border-radius: 8px;
  background: rgba(0, 0, 0, 0.7);

  padding: 4px 8px;
`;

const TrackDuration = styled.div`
  position: absolute;
  max-width: calc(100% - 32px);
  right: 8px;
  bottom: 8px;

  color: white;

  border-radius: 8px;
  background: rgba(0, 0, 0, 0.7);

  padding: 4px 8px;
`;

const TrackDragRange = styled.div`
  position: absolute;
  border-radius: 8px;
  background: orange;
  opacity: 0.6;
  width: 100%;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;

  padding: 20px 20px;
`;

const TrackButton = styled.button`
  width: 33%;
  padding: 4px;
  background-color: orange;
  transition: 0.4s;

  &:hover {
    background-color: blue;
  }
`;

const TrackButtonMenu = styled.div`
  background-color: LightSteelBlue;
  transition: 0.5s;
  position: absolute;
  top: 80px;
  display: flex;
  flex-direction: column;
  max-height: 70%;
  overflow: scroll;
  opacity: 1;
  ${isMobile()
    ? `left: 0%; width: 100%`
    : `left: 72px; width: calc(390px - 64px)`}
`;

const TrackButtonMenuList = styled.div`
  background-color: LightSteelBlue;
  transition: 0.2s;
  color: black;
  padding-left: 2px;
  border: solid 1px darkblue;

  &:hover {
    background-color: MidnightBlue;
    color: white;
  }
`;
