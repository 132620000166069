import React, { useState } from 'react';
import { RemoveCircleOutlineOutlined as RemoveIcon } from '@mui/icons-material';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateLuxon from '@mui/lab/AdapterLuxon';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Slider,
  TextField,
} from '@mui/material';
import { DateTime } from 'luxon';
import styled from 'styled-components';

interface AudioClipSettingModalProps {
  show: boolean;
  start: DateTime;
  end: DateTime;
  volume: number;
  mainAudioVolume: number;
  onClose: () => void;
  onChange: (start: DateTime, end: DateTime, volume: number, mainAudioVolume: number) => void;
  onRemove: () => void;
}

/**
 * 더이상 사용되지 않음, 이 모달은 DateTime에 의존되어있으므로, number 데이터를 활용하지 못합니다.
 * 
 * 이 모달을 사용하는 ResizableTimeline이 더이상 사용되지 않습니다.
 * @deprecated
 */
export const AudioClipSettingModal = ({
  show,
  start: initialStart,
  end: initialEnd,
  volume: initialVolume,
  mainAudioVolume: initialMainVolume,
  onClose,
  onChange,
  onRemove,
}: AudioClipSettingModalProps) => {
  const [start, setStart] = useState(initialStart);
  const [end, setEnd] = useState(initialEnd);
  const [volume, setVolume] = useState(initialVolume ?? 1);
  const [mainAudioVolume, setMainAudioVolume] = useState(initialMainVolume ?? 1);

  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={{ width: 400, background: 'white' }}>
        <div style={{ height: '16px' }} />
        <LocalizationProvider dateAdapter={AdapterDateLuxon}>
          <TimeContainer>
            <TimePicker
              ampm={false}
              views={['minutes', 'seconds']}
              inputFormat="mm:ss"
              mask="__:__"
              label="Start"
              value={start}
              onChange={(newValue: any) => {
                setStart(newValue);
              }}
              renderInput={params => <TextField size="small" {...params} />}
            />
            &nbsp;~&nbsp;
            <TimePicker
              ampm={false}
              views={['minutes', 'seconds']}
              inputFormat="mm:ss"
              mask="__:__"
              label="End"
              value={end}
              onChange={(newValue: any) => {
                setEnd(newValue);
              }}
              renderInput={params => <TextField size="small" {...params} />}
            />
          </TimeContainer>
        </LocalizationProvider>
        <div style={{ height: '16px' }} />

        <div>Volume</div>
        <div style={{ padding: '8px' }}>
          <Slider
            value={volume}
            onChange={(e, newVolume) => setVolume(+newVolume)}
            min={0}
            max={1}
            step={0.01}
          />
        </div>

        <div>Temp: Main Audio Volume</div>
        <div style={{ padding: '8px' }}>
          <Slider
            value={mainAudioVolume}
            onChange={(e, newVolume) => setMainAudioVolume(+newVolume)}
            min={0}
            max={1}
            step={0.01}
          />
        </div>

        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flex: 1 }} />
          <Button
            color="error"
            variant="text"
            onClick={() => {
              onRemove();
              onClose();
            }}
          >
            Delete
          </Button>
          <div style={{ width: 12 }} />
          <Button
            variant="text"
            onClick={() => {
              onChange(start, end, volume, mainAudioVolume);
              onClose();
            }}
          >
            Apply
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
`;
