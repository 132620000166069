import { downloadFileUrl } from './download';

export const saveFile = (blobOrURL, name = 'output.mp4') => {
  if (typeof blobOrURL === 'string') {
    downloadFileUrl(blobOrURL, name);
  } else {
    const blob = blobOrURL;

    const url = window.URL.createObjectURL(blob);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  }
};
