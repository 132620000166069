import React, { useEffect, useRef, useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useObserver } from 'mobx-react';
import { IUploadedVideo } from 'model';
import { useStores } from 'store';
import styled, { css } from 'styled-components';

import { getAuthTokenFromCookie, getCookie } from 'util/cookie';
import { downloadFileUrl } from 'util/download';
import { request } from 'util/http-client';
import Confirm from 'component/popup/Confirm';
import { timelineStore } from 'store/timeline';
import { videoStore } from 'store/video';

interface LoadingPropsInter {
  show: boolean;
}
export const HistoryMobile = ({ show }: LoadingPropsInter) => {
  const [isBlock, setIsBlock] = useState(show);
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [removeOk, setRemoveOk] = useState(false);
  const [q, setQ] = useState('');
  const [list, setList] = useState<IUploadedVideo[]>([]);

  const allCheckBoxRef = useRef<HTMLInputElement>(null);
  const checkBoxArrayRef = useRef<HTMLInputElement[]>([]);

  const { uiPopup, uiHistory } = useStores();

  // header checkbox 클릭 시, 하위 데이터 리스트 체크 박스 선택
  const allCheckDeleteCheckBoxOnClickHandle = () => {
    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        if (!element) return;
        element.checked = isAllCheck ? false : true;
        setIsAllCheck(isAllCheck ? false : true);
      });
    }

    let isAllChecked = !isAllCheck ? true : false;
    allCheckBoxRef.current!.checked = isAllChecked;
  };

  // 데이터 리스트 중 체크 박스 클릭 시, 전체 선택 여부에 따라 header checkbox 해제 또는 선택
  const listCheckBoxOnClickHandle = (key?: number) => {
    let isAllChecked = true;

    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        // 전체 선택이 안되는 조건일 시, 전체 선택 체크 박스 False 처리
        if (element && !element.checked) isAllChecked = false;

        // 체크박스를 직접 클릭할 경우
        if (key) {
          if (element && Number(element.value) == key) {
            element.checked = element.checked ? false : true;
          }
        }
      });
    }

    allCheckBoxRef.current!.checked = !isAllChecked ? false : true;
  };

  // confirm 컴포넌트 내, 확인|취소 상태 확인을 위해 하위로 내린 함수
  const confirmButtonOnClick = (isOk: boolean) => {
    isOk ? setRemoveOk(true) : confirmClose();
  };

  // 삭제 버튼 클릭 시 동작 및 삭제된 데이터를 제외한 리스트 출력을 위한 set useState
  const removeMusicList = () => {
    // 선택된 리스트의 key List 배열
    let checkedRefValue: string[] = [];

    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        if (element && element.checked) checkedRefValue.push(element.value);
      });
    }

    if (checkedRefValue.length < 1) {
      alert('Please select the list you want to delete.');
      return;
    }

    !removeOk ? uiPopup.showLoading() : uiPopup.hideLoading();
  };

  const onClickApply = (item: any) => {
    timelineStore.load(JSON.parse(item.data));

    uiHistory.hideLoading();
  };

  // Row 단위 리스트 클릭 시, 해당 줄의 체크박스 체크가 연동 되도록 처리
  const rowListRemoveCheckOnClick = (key: number) => {
    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        if (element && Number(element.value) == key) {
          element.checked = element.checked ? false : true;
        }
      });
    }

    listCheckBoxOnClickHandle();
  };

  // removeOk 상태 값 변경에 따라 실행되는 함수이며
  // 실제 리스트에서 데이터를 삭제 후 재 출력하는 함수이다.
  const removeAction = async () => {
    if (removeOk) {
      // 삭제 API 연결

      // 선택되지 않은 임시 리스트 배열
      let listTempArray: any = [];

      // 선택된 리스트의 key List 배열
      let checkedRefValue: string[] = [];

      if (checkBoxArrayRef.current.length > 0) {
        checkBoxArrayRef.current.forEach(element => {
          if (element && element.checked) checkedRefValue.push(element.value);
        });
      }

      list.map((item: any) => {
        let isMatch = false;
        checkedRefValue.forEach(keys => {
          if (item.key == keys) isMatch = true;
        });
        if (!isMatch) listTempArray.push(item);
      });

      if (checkBoxArrayRef.current.length > 0)
        checkBoxArrayRef.current.forEach(element => {
          if (element) element.checked = false;
        });
      if (listTempArray.length < 1) allCheckBoxRef.current!.checked = false;

      // 삭제 API 연결
      const memberId = getCookie('SU_TOKEN');

      if (!memberId) {
        alert('User Auth Invalid! Try Again Please.');
        window.location.href = 'https://surroundio.org';
      }

      try {
        for (const id of checkedRefValue) {
          await request('POST', '/history/delete', {
            vid: id,
          });
        }

        setList(listTempArray);
        setRemoveOk(false);
        alert('Successfully processed.');
      } catch (error) {
        alert('A temporary error has occurred. History Delete Error');
        window.location.reload();
      }
    }
  };

  const historyUrlOnClickDownloadHandle = async (url: string) => {
    //window.open(url, '_blank');

    await downloadFileUrl(url, videoStore.video);
  };

  const confirmClose = () => {
    setRemoveOk(false);
    uiPopup.hideLoading();
  };

  useEffect(() => {
    const authToken = getAuthTokenFromCookie();

    if (!authToken) {
      alert('User Auth Invalid! Try Again Please.');
      window.location.href = 'https://surroundio.org';
    }

    const data: any = request('GET', '/history');

    data.then((res: any) => setList(res.data));

    setIsBlock(show);

    if (removeOk) {
      removeAction();
      uiPopup.hideLoading();
    }
  }, [show, removeOk]);

  /**
   * Table의 각 요소에 대한 타이틀 이름
   *
   * tableNameList[0]은 CheckBox지만, 실제로 표시되는것은 CheckBox 형태의 박스이므로, 이 이름을 사용할 필요가 없습니다.
   *
   * tableNameList[1]번부터 차례대로 사용하시면 됩니다.
   * tableName은 해당 변수의 값을 참고하세요.
   */
  const tableNameList = [
    'CheckBox',
    'No',
    'Image',
    'Video',
    'Date',
    'Download',
  ];

  /**
   * Table의 각 요소에 대해 x축의 width의 %(퍼센트)가 얼마인지를 지정하는 요소
   *
   * 지정된 width값은 이 변수의 배열의 값을 참고하세요.
   *
   * 참고: 테이블의 표시되는 너비 값은 모든 구간 합쳐서 100%를 초과해서는 안됩니다.
   * */
  const styleXLineWidth = [5, 14, 40, 25, 10];

  return useObserver(() => (
    <Container isBlock={isBlock}>
      <Confirm
        {...{
          block: uiPopup.loading,
          header: 'Delete History',
          content: 'You cannot download this work again. \n Are you sure?',
          confirmButtonOnClick,
        }}
      />
      <BacgroundOpacity />

      <RegisterMusicBoardContainer>
        <Table>
          <DeleteButtonSection>
            <button onClick={removeMusicList}>Delete</button>
          </DeleteButtonSection>

          <TableHeader>
            <TableColumn setPercentColumnWidth={styleXLineWidth[0]}>
              <input
                ref={allCheckBoxRef}
                type="checkbox"
                onClick={allCheckDeleteCheckBoxOnClickHandle}
              />
            </TableColumn>

            <TableColumn setPercentColumnWidth={styleXLineWidth[2]}>
              <span>{tableNameList[2]}</span>
            </TableColumn>
            <TableColumn setPercentColumnWidth={styleXLineWidth[3]}>
              <span>{tableNameList[3]}</span>
            </TableColumn>
            <TableColumn setPercentColumnWidth={styleXLineWidth[4]}>
              <span>{tableNameList[4]}</span>
            </TableColumn>
            <TableColumn setPercentColumnWidth={styleXLineWidth[5]}>
              <span>{tableNameList[5]}</span>
            </TableColumn>
            {/* effect 부분을 출력되지 않도록 변경해서 맨 마지막 부분을 지웠습니다. */}
            {/* <TableColumn setPercentColumnWidth={styleXLineWidth[6]}>
              <span>{tableNameList[6]}</span>
            </TableColumn> */}
          </TableHeader>

          <TableData>
            {list && list.length > 0
              ? list
                  .filter(x => x.title.includes(q) || x.description.includes(q))
                  .map((item: any, index: number) => {
                    return (
                      <TableRow key={index} onClick={() => onClickApply(item)}>
                        <div />
                        <TableColumn setPercentColumnWidth={styleXLineWidth[0]}>
                          <input
                            type="checkbox"
                            ref={ele =>
                              (checkBoxArrayRef.current[index] = ele!)
                            }
                            onClick={() =>
                              listCheckBoxOnClickHandle(item.videoId)
                            }
                            value={item.videoId}
                          />
                        </TableColumn>

                        <TableColumn setPercentColumnWidth={styleXLineWidth[1]}>
                          <img
                            crossOrigin="anonymous"
                            src={item.thumbnailUrl}
                          />
                        </TableColumn>
                        <TableColumn setPercentColumnWidth={styleXLineWidth[2]}>
                          <TableColumnTextBox>
                            <span>{item.title}</span>
                          </TableColumnTextBox>
                        </TableColumn>
                        {/* 모바일 cloud 레이아웃 정보 공간을 확보하기 위해 effect 부분을 지웠습니다. */}
                        {/* <TableColumn
                          setPercentColumnWidth={styleXLineWidth[4]}
                          onClick={onClickDescription}
                        >
                          <span>{item.description}</span>
                        </TableColumn> */}
                        <TableColumn setPercentColumnWidth={styleXLineWidth[3]}>
                          <span title={item.created_at}>
                            {item.created_at.split('T')[0]}
                          </span>
                        </TableColumn>
                        <TableColumn setPercentColumnWidth={styleXLineWidth[4]}>
                          <CloudDownloadIcon
                            color="primary"
                            onClick={() =>
                              historyUrlOnClickDownloadHandle(item.videoUrl)
                            }
                          />
                        </TableColumn>
                        <div />
                      </TableRow>
                    );
                  })
              : ''}
          </TableData>
        </Table>
      </RegisterMusicBoardContainer>
    </Container>
  ));
};

const Container = styled.div<any>`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: calc(100% - 64px);

  z-index: 311;

  ${({ isBlock }) =>
    isBlock == false
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}

  justify-content: center;
`;

const BacgroundOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.6;
`;

const RegisterMusicBoardContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  padding-top: 50px;
`;

const Table = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TableHeader = styled.div`
  width: 100%;
  height: 40px;
  background-color: black;
  overflow-y: auto;

  display: flex;
  flex-direction: row;

  margin-bottom: 10px;
  border-radius: 5px;
`;

const TableRow = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  max-height: 48px;

  display: grid;
  grid-template-columns: 0px 10px 40px 1fr 80px 50px 0px;

  margin-bottom: 3px;

  border-bottom: thin solid white;
  background-color: #ffffff;
  border-radius: 5px;

  gap: 8px;

  &:hover {
    box-shadow: 2px 2px 5px 1px #888282;
    z-index: 1;
  }
`;

const TableColumn = styled.div<any>`
  min-width: ${props => {
    return props.setPercentColumnWidth;
  }}%;
  max-height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-weight: bold;
  font-size: 11px;

  img {
    width: 100%;
    height: 50%;

    object-fit: cover;
  }
`;

const TableColumnTextBox = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  align-items: center;
`;

const TableData = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;

  span {
    color: black;
  }

  .download {
    color: blue;
  }
`;

const DeleteButtonSection = styled.div`
  width: 97%;

  display: flex;
  justify-content: right;

  button {
    width: 90px;
    height: 35px;
    margin-bottom: 10px;

    border: none;
    border-radius: 5px;

    background-color: #d33e3e;
    color: white;

    font-weight: bold;
    box-shadow: none;

    &:hover {
      cursor: pointer;
      background-color: red;
    }
  }
`;
