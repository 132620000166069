import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TimelineItemBase } from 'react-calendar-timeline';
import { TimelineResolution } from 'constant';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled from 'styled-components';

import { isMobile, isTestMode, mobile, string2color } from 'util/index';
import { ResizableTimeline } from './ResizableTimeline';
import { TimelineView } from './timelineView';
import { AudioClipSettingModalVer2, audioClipSettingModalVer2ChangeData } from 'component/popup/AudioClipSettingModalVer2';
import { AudioModalMain } from 'component/popup/AudioModalMain';

/** 
 * audioSettingmodal을 사용할 때 사용되는 타입
 * 
 * 각각, music(음악 설정), main(메인 오디오 설정), ''(아무것도 표시하지 않음)
 */
type modalType = 'music' | 'main' | '';
interface TimelineProps {}
export const Timeline = observer(({}: TimelineProps) => {
  const { timelineStore, videoStore, uiStore } = useStores();
  const [forceUpdate, setForceUpdate] = useState(0);

  /** 타임 라인 표시 용도 */
  const timelineView = useRef<TimelineView>(new TimelineView('100%', '20%'));
  const [audioSettingModalType, setShowAudioSettingModal] = useState<modalType>('');

  /** 레이어 리스트 목록 (번호랑 대응시키기 위해서) */
  const timelineViewLayerList = {
    MENU: 0,
    TIME: 1,
    MASTERING: 2,
    MUSIC: 3,
  }

  /** 
   * container ref, useCallback을 사용하여 timelineView를 표시하도록 변경
   * 
   * 이것은, 모바일에서 sideMenu를 열고 닫았을 때, div객체의 참조가 무효가 되어
   * timelineView가 표시되지 않아 강제 렌더링 하기 위해 만든 함수입니다.
   * 
   * 따라서, useCallback을 이용해 렌더링이 된 후에 div를 참조시킨 후, 
   * 해당 div에 timelineView 객체의 내용을 포함시켜, div 내부가 초기화 될 때마다
   * 자식 노드를 계속 추가하도록 했습니다.
   * 
   * 이 현상은 해당 콘테이너를 전부 가리는 모바일 버전에서만 나오며, PC버전에서는 아무 상관 없습니다.
   * 
   * 참고: 나중에 확인해보니, 이 함수 중간에 모바일여부를 확인하고 강제 return 하는 조건문이 있었습니다.
   * 아마도, timelineView가 사이드메뉴를 열고 닫을 때 사라진 이유가 그 조건문 때문인 것 같습니다. 해당 조건문은 삭제되었지만,
   * 이 코드는 그대로 유지됩니다.
   */
  const container = useCallback((target: HTMLDivElement) => {
    if (target != null) {
      // 레이어의 내부 데이터가 없다면, 새로운 레이어를 생성하여 초기화합니다.
      if (timelineView.current.layer.length === 0) {
        // 레이어 구조: 총 4개
        // 레이어 0: Menu 메뉴 표시, 멀티라인 1, 오버랩 가능
        timelineView.current.createLayer(1, '100%', '15%', '#FFE0BC');
        timelineView.current.setLayerTitle(timelineViewLayerList.MENU, 'Menu', '12%', '100%', 'black', 'pink');
        timelineView.current.setLayerSubElementDragPossible(timelineViewLayerList.MENU, false);

        // 1: Time 시간표시, 멀티라인 1, 드래그 불가능 
        timelineView.current.createLayer(1, '100%', '15%', '#FFF9DD');
        timelineView.current.setLayerTitle(timelineViewLayerList.TIME, 'Time', '12%', '100%', 'black', 'pink');
        timelineView.current.setLayerSubElementDragPossible(timelineViewLayerList.TIME, false); // 드래그 불가능

        // 2: Mastering 이펙트 표시, 10라인까지 표시 가능 (이 수는 무한정 늘릴 수도 있음), 드래그 불가능
        timelineView.current.createLayer(10, '100%', '55%', '#DDFFE0');
        timelineView.current.setLayerTitle(timelineViewLayerList.MASTERING, 'Mastring', '12%', '100%', 'black', 'pink');
        timelineView.current.setLayerSubElementDragPossible(timelineViewLayerList.MASTERING, false); // 이펙트 시간 길이 조절 불가능

        // 3: Music 음악 표시, 멀티라인 없음, 오버랩(겹치기) 불가능
        timelineView.current.createLayer(0, '100%', '15%', '#DDEEFF');
        timelineView.current.setLayerTitle(timelineViewLayerList.MUSIC, 'Music', '12%', '100%', 'black', 'pink');

        // 메뉴 레이어 데이터 추가
        timelineView.current.layerAddSubElementObj(timelineViewLayerList.MENU, {
            id: '?',
            textContent: 'MENU BUTTON',
            cssColor: 'white',
            cssBackgroundColor: 'orange'
        },{
            position: 0,
            length: 100,
            lengthMax: 100,
            lengthStart: 0,
            lengthEndback: 0
          }
        )

      }

      // 해당 타겟(div 태그) 내에 자식 엘리먼트가 없다면, timelineView의 엘리먼트를 직접 추가합니다.
      if (target.childElementCount === 0) {
        target.appendChild(timelineView.current.getBaseElement())
      }
    }
  }, [])

  /** 
   * 경고: 이 변수는 강제 렌더링을 위해 필요한 변수입니다. 
   * 
   * 이 변수를 지우게 되면, timelineStore에서 이펙트 또는 배경음악을 추가할 때
   * 화면 갱신이 이루어지지 않습니다.
  */
  const elements = [
    ...timelineStore.effects.map((x, idx) => ({
      id: x.id,
      itemId: idx,
      type: 'effect',
      group: x.group,
      title: `${x.effect.name}`,
      start: x.start * TimelineResolution,
      end: x.end * TimelineResolution,
      effect: x,
      itemProps: {
        style: {
          background: string2color(x.effect.name ?? ''),
        },
      },
    })),
    ...timelineStore.backgroundSounds.map((x, idx) => ({
      id: x.id,
      itemId: x.id,
      type: 'backgroundSound',
      group: 1,
      title: `#${x.name}`,
      volume: x.volume,
      start: x.start * TimelineResolution,
      end: x.end * TimelineResolution,
      effect: x,
      itemProps: {
        style: {
          background: string2color(x.name ?? ''),
        },
      },
    })),
  ];
  
  /** @deprecated 어떤이유에서인지, 강제로 일정시간마다 갱신시키면 Click이벤트를 사용할 수 없음 */
  const useInterval = (callback: Function, delay: number) => {
    const savedCallback = useRef(callback);

    useEffect(() => {
      savedCallback.current = callback;
    });

    useEffect(() => {
      // cf. delay 인자에 null 값을 전달할 경우 타이머를 멈출 수 있음
      if (delay === null) return;
      
      const timer = setInterval(() => savedCallback.current(), delay);
      return () => clearInterval(timer);
    }, [delay]);
  }

  const REDMETER_ID = 'redmeterid'

  /** 시간 레이어 내부의 redmeter를 변경합니다. */
  const timeLineViewChangeLayerTimeRedMeter = () => {
    // 시간 레이어
    let layer = timelineView.current.getLayerReturnData(timelineViewLayerList.TIME)
    if (layer == null) return

    // timelineViewChangeLayerTime()
    for (let i = 0; i < layer.length; i++) {
      let current = layer[i]
      if (current.id === REDMETER_ID) {
        let targetPosition = videoStore.videoElement.currentTime / videoStore.getDuration() * 100;
        timelineView.current.layer[timelineViewLayerList.TIME].subElement[i].percent.position = targetPosition;
      }
    }

    setForceUpdate(forceUpdate + 1);
  }

  // 화면 갱신용 useEffect
  useEffect(() => {
    timelineViewChange()
  }, )

  /** 타임라인스토어에 있는 데이터를 타임라인 뷰에 처리 */
  const timelineViewChange = () => {
    timelineViewChangeLayerTime()
    timelineViewChangeLayerEffect()
    timelineViewChangeLayerMusic()
  }

  const durationToMinuteSecond = (value: number) => {
    let minute = Math.floor(value / 60);
    let second = value % 60;

    return minute + ':' + second;
  };

  const timelineViewChangeLayerTime = () => {
    timelineView.current.layerSubElementDeleteAll(timelineViewLayerList.TIME) // 레이어 서브 엘리먼트 제거
    // 그 후 다시 레이어 서브엘리먼트 생성

    // 시간 처리
    if (videoStore == null) return;
    if (!videoStore.getDuration) return;

    let duration = videoStore.getDuration();
    if (duration == null) return;

    timelineView.current.setLayerSubElementDragPossible(timelineViewLayerList.TIME, false) // 시간 드래그 불가능
    for (let i = 0; i < 10; i++) {
      let number = Math.floor(duration / 10) * i
      timelineView.current.layerAddSubElement(timelineViewLayerList.TIME, durationToMinuteSecond(number), i * 10, 10, 'blue', '')
    }

    let targetPosition = videoStore.videoElement.currentTime / videoStore.getDuration() * 100;
    if (isNaN(targetPosition)) targetPosition = 0;

    // 현재 시간에 따른, 막대바 추가
    timelineView.current.layerAddSubElementObj(timelineViewLayerList.TIME, {
      id: REDMETER_ID,
      textContent: '|',
      cssBackgroundColor: 'red',
      cssColor: undefined
    }, {
      position: targetPosition,
      length: 0.5,
      lengthMax: 0.5,
      lengthStart: 0,
      lengthEndback: 0
    })
  }

  const timelineViewChangeLayerEffect = () => {
    timelineView.current.layerSubElementDeleteAll(timelineViewLayerList.MASTERING) // 레이어 서브 엘리먼트 제거
    // 그 후 다시 레이어 서브엘리먼트 생성

    // 이펙트 처리 (비디오 시간이 0이면, 0나누기 방지를 위해 함수 종료)
    const videoDuration = videoStore.getDuration()
    if (videoDuration == null || videoDuration === 0) return

    for (let i = 0; i < timelineStore.effects.length; i++) {
      const current = timelineStore.effects[i]
      const duration = current.end - current.start
      const positionLengthMax = duration / videoDuration * 100

      // 이펙트는 전체 영역에 출력되므로, 따로 크기를 추가로 계산할 필요는 없음
      let a = timelineView.current.layerAddSubElementObj(timelineViewLayerList.MASTERING, {
        id: current.id,
        textContent: current.effect.name,
        cssColor: 'white',
        cssBackgroundColor: string2color(current.effect.name ?? ''),
      }, {
        lengthMax: positionLengthMax,
        length: positionLengthMax,
        lengthStart: 0,
        lengthEndback: 0,
        position: 0,
      })
    }
  }

  const timelineViewChangeLayerMusic = () => {
    timelineView.current.layerSubElementDeleteAll(timelineViewLayerList.MUSIC) // 레이어 서브 엘리먼트 제거
    // 그 후 다시 레이어 서브엘리먼트 생성

    const videoDuration = videoStore.getDuration()
    if (videoDuration == null && videoDuration === 0) return

    for (let i = 0; i < timelineStore.backgroundSounds.length; i++) {
      const current = timelineStore.backgroundSounds[i]
      const duration = current.musicDuration
      const musicDuration = current.rangeEnd - current.rangeStart
      const percentPosition = current.start / videoDuration * 100
      
      // 퍼센트 길이는 최대 100% 제한
      const percentLength = musicDuration < videoDuration ? musicDuration / videoDuration * 100 : 100
      const percentLengthMax = duration < videoDuration ? duration / videoDuration * 100 : 100
      const percentLengthStart = current.rangeStart / videoDuration * 100
      const percentLengthEndback = current.rangeEndback / videoDuration * 100
      const backgroundLength = current.end - current.start
      const percentData = timelineView.current.getCalcurateValueToPercent(videoDuration, current.start, backgroundLength, current.rangeStart, percentLengthEndback)

      timelineView.current.layerAddSubElementObj(timelineViewLayerList.MUSIC, {
        id: current.id,
        textContent: current.name,
        cssColor: 'white',
        cssBackgroundColor: string2color(current.name ?? ''),
      }, {
        lengthMax: percentLengthMax,
        length: percentLength,
        lengthStart: percentLengthStart,
        lengthEndback: percentLengthEndback,
        position: percentPosition
      })
    }
  }

  const onMouseUp = () => {
    // 변경된 값에 대하여 처음부터 다시 제작
    timelineViewToStoreLayerMastering()
    timelineViewToStoreLayerMusic()

    timelineStore._updateBackgroundSound() // 이펙트 및 배경사운드 변경
    setForceUpdate(forceUpdate + 1) // 화면 강제 갱신
  }

  const timelineViewToStoreLayerMastering = () => {
    // for문을 이용하여, 퍼센트 구간을 다시 측정하고, 
    // 이 퍼센트 값을 이용하여 레이어를 처음부터 재설정

    // 가지고 있는 데이터의 위치가 변경되면 변경된 위치를 근거로 재배치
    const currentLayerData = timelineView.current.getLayerReturnData(timelineViewLayerList.MASTERING)
    if (currentLayerData == null) return

    const videoDuration = videoStore.getDuration()
    if (videoDuration == null || videoDuration === 0) return

    const effectList = timelineStore.effects
    if (effectList.length === 0) return

    const effectIdList = getEffectIdList();
    if (effectIdList == null) return

    for (let i = 0; i < currentLayerData.length; i++) {
      const current = currentLayerData[i]
      const index = effectIdList.indexOf(current.id)
      if (index === -1) continue

      console.log(index, current.textContent)
      const start = current.percentPosition * videoDuration / 100
      const duration = current.percentLength * videoDuration / 100
      const effect = effectList[i]

      effect.start = start
      effect.end = start + duration
    }
  }

  const getEffectIdList = () => {
    const effectList = timelineStore.effects
    if (effectList.length === 0) return

    let effectNameList = []
    for (let i = 0; i < effectList.length; i++) {
      effectNameList.push(effectList[i].id)
    }

    return effectNameList
  }

  const getBackgroundsIdList = () => {
    const backgroundSoundList = timelineStore.backgroundSounds
    if (timelineStore.backgroundSounds.length === 0) return

    let bgNameList = []
    for (let i = 0; i < backgroundSoundList.length; i++) {
      const current = backgroundSoundList[i]
      bgNameList.push(current.id)
    }

    return bgNameList
  }
  
  const timelineViewToStoreLayerMusic = () => {
    // 가지고 있는 데이터의 위치가 변경되면 변경된 위치를 근거로 재배치
    const currentLayerData = timelineView.current.getLayerReturnData(timelineViewLayerList.MUSIC)
    if (currentLayerData == null) return

    const videoDuration = videoStore.getDuration()
    if (videoDuration == null || videoDuration === 0) return
    
    const backgroundSoundList = timelineStore.backgroundSounds
    const bgIdList = getBackgroundsIdList()
    if (bgIdList == null) return

    for (let i = 0; i < currentLayerData.length; i++) {
      const current = currentLayerData[i]

      // 참고: 배경 음악은 변수명으로 관리되므로 실제로 배경 음악이 달라질 일은 없음
      const backgroundIndex = bgIdList.indexOf(current.id)
      if (backgroundIndex === -1) continue // 해당하는 배경음악이름이 없으면 무시함

      const background = backgroundSoundList[backgroundIndex]
      const percentData = timelineView.current.getCalcuratePercentToValue(videoDuration, current.percentPosition, current.percentLength, current.percentLengthMax, current.percentLengthStart, current.percentLengthEndback)

      // backgroundSound 정보 입력
      background.start = percentData.valuePosition
      background.end = percentData.valuePosition + percentData.valueLength
      background.rangeStart = percentData.valueElementStart
      background.rangeEnd = percentData.valueElementStart + percentData.valueLength
      background.rangeEndback = percentData.valueElementEndback
      background.rangeDuration = background.rangeEnd - background.rangeStart
      const backgroundLength = background.end - background.start

      // fade 시간 조정 (임시 코드, 수정 필요)
      if (backgroundLength < 6) {
        background.fadeIn = backgroundLength / 2
        background.fadeOut = backgroundLength / 2
      }
    }
  }

  const onClick = () => {
    const click = timelineView.current.lastClickTargetData
    if (click.layerNumber === timelineViewLayerList.MENU) {
      setShowAudioSettingModal('main')
      timelineView.current.resetLastClickTargetData() // 마지막 클릭 데이터 제거 (중복, 미스클릭 방지)
    } else if (click.layerNumber === timelineViewLayerList.MASTERING) {
      // effect를 클릭했을 때, 관련 처리가 필요함
      onDeleteEffect()
      timelineView.current.resetLastClickTargetData() // 마지막 클릭 데이터 제거 (중복, 미스클릭 방지)
    } else if (click.layerNumber === timelineViewLayerList.MUSIC) {
      let subElement = timelineView.current.layer[click.layerNumber].subElement[click.subElementNumber]
      // 참고: 여기서는 마지막 클릭 데이터를 제거하지 않습니다. 왜냐하면, onBackgroundSound 설정에 영향을 줘야 하기 때문입니다.
      // 모달 작업이 끝나면, 그 때 마지막 클릭 데이터가 제거됩니다.
      if (subElement != null) {
        // 클릭된 서브엘리먼트가 존재해야만 타임 모달이 표시됨
        setShowAudioSettingModal('music')
      }
    }
    
    setForceUpdate(forceUpdate + 1) // 화면 강제 갱신
  }

  const onDeleteEffect = () => {
    // 타임라인 뷰에서 마지막으로 클릭한 객체를 가져옴
    let click = timelineView.current.lastClickTargetData

    // 삭제할 대상을 찾은 후, 그 대상이 있으면 삭제
    let removeTarget = timelineStore.effects[click.subElementNumber]
    if (removeTarget != null) {
      timelineStore.removeEffect(removeTarget.id)
    }

    setForceUpdate(forceUpdate + 1) // 화면 강제 갱신
  }

  const onDeleteBackgroundSound = () => {
    let click = timelineView.current.lastClickTargetData

    let idList = getBackgroundsIdList()
    if (idList == null) return

    const backgroundIndex = idList.indexOf(click.id)
    let removeTarget = timelineStore.backgroundSounds[backgroundIndex]
    if (removeTarget != null) {
      timelineStore.removeBackgroundSound(removeTarget.id)
    }

    timelineView.current.resetLastClickTargetData() // 마지막 클릭 데이터 제거 (중복, 미스클릭 방지)
    setForceUpdate(forceUpdate + 1)
  }

  const onChangeMain = (args: number) => {
    if (videoStore.mixer) {
      videoStore.mixer.setMainAudioGainValue(args)
    }
  }

  const onChangeMusic = (args: audioClipSettingModalVer2ChangeData) => {
    // 참고 이 변수들의 args는 audioClipSettingModalVer2의 onChange 함수를 참고하세요.
    // 변수 목록
    // type: modalType, start: number, end: number, volume: number, mainAudioVolume: number
    let click = timelineView.current.lastClickTargetData

    let idList = getBackgroundsIdList()
    if (idList != null) {
      const backgroundIndex = idList.indexOf(click.id)
      let target = timelineStore.backgroundSounds[backgroundIndex]
      if (target != null) {
        target.volume = args.volume
        target.start = args.start
        target.end = args.end
        target.rangeStart = args.rangeStart
        target.rangeEnd = args.rangeEnd
        target.fadeIn = args.fadeIn
        target.fadeOut = args.fadeOut
      }
    }

    // 오디오 변경에 따라 강제 업데이트
    timelineStore._updateBackgroundSound()
    timelineView.current.resetLastClickTargetData()
  }

  /** 현재 활성화된 아이템의 값을 가져옴 */
  const getActiveItem = () => {
    let returnData = {
      start: 0,
      end: 0,
      volume: 1,
      fadeIn: 0,
      fadeOut: 0,
      rangeStart: 0,
      rangeEnd: 0,
      musicDuration: 0,
    }

    const videoDuration = videoStore.getDuration()
    if (videoDuration == null || videoDuration === 0) {
      return returnData
    }

    const currentTarget = timelineView.current.lastClickTargetData
    if (currentTarget.layerNumber === timelineViewLayerList.MUSIC) {
      let idList = getBackgroundsIdList()
      if (idList == null) return returnData
      
      const backgroundIndex = idList.indexOf(currentTarget.id)
      let target = timelineStore.backgroundSounds[backgroundIndex]
      if (target == null) return returnData
        
      returnData.start = target.start
      returnData.end = target.end
      returnData.fadeIn = target.fadeIn
      returnData.fadeOut = target.fadeOut
      returnData.rangeStart = target.rangeStart
      returnData.rangeEnd = target.rangeEnd
      returnData.musicDuration = target.musicDuration
      returnData.volume = target.volume
    }

    return returnData
  }

  return (
    <Container>
      {/* 이벤트 버블링을 이용해서, 내부에서 일어난 dragEnd 이벤트를 통해
      상위 엘리먼트에 전송해서, 내부에 데이터가 변경됨을 감지함 */}
      <div style={{width:'100%', height:'100%'}} ref={container} onDragEnd={onMouseUp} onTouchEnd={onMouseUp} onClick={onClick}></div>

      {/* 메인 설정 */}
      {audioSettingModalType === 'main' && <AudioModalMain
        show={true}
        volume={videoStore.mixer != null ? videoStore.mixer.getMainAudioGainValue() : 1}
        onChange={(args) => {onChangeMain(args)}}
        onClose={() => {setShowAudioSettingModal('')}}
      />}

      {/* 음악 설정 */}
      {audioSettingModalType === 'music' && <AudioClipSettingModalVer2
      show={true}
      onRemove={() => {onDeleteBackgroundSound()}} 
      onClose={() => {setShowAudioSettingModal('')}}
      onChange={(args) => {onChangeMusic(args)}}
      start={getActiveItem().start}
      end={getActiveItem().end}
      volume={getActiveItem().volume}
      maxAudioTime={getActiveItem().musicDuration}
      maxVideoTime={videoStore.getDuration()}
      rangeStart={getActiveItem().rangeStart}
      rangeEnd={getActiveItem().rangeEnd}
      fadeIn={getActiveItem().fadeIn}
      fadeOut={getActiveItem().fadeOut}
      />}
    </Container>
  )
});

const Container = styled.div`
  position: absolute;
  width: calc(100vw - 64px);
  top: 80%;
  height: 100%;
  width: 100%;

  @media (max-width: 480px) {
    width: 100vw;
  }
`;


