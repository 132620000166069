import React, { useEffect, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";

import { firestore } from "firebase-app";

const ShouldSortByRandom = true;

export const useEffects = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const effects = await getDocs(query(collection(firestore, "effect")));
      const data = ShouldSortByRandom ? effects.docs.sort(() => Math.random() > 0.5 ? -1 : 1) : effects;

      setData(
        // data에 map 속성이 없습니다.
        //@ts-ignore
        data.map((x: any) => {
          const d = x.data();

          return {
            ...d,
            data: JSON.parse(d.data || "{}"),
          };
        })
      );
    })();
  }, []);

  return data;
};
