import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Slider,
  TextField,
  
} from '@mui/material';
import styled from 'styled-components';

interface AudioClipSettingModalProps {
  /** 모달을 보여주는 용도 */ show: boolean;
  volume: number;
  onClose: () => void;
  onChange: (volume: number) => void;
}
export const AudioModalMain = ({
  show,
  volume: initialVolume,
  onClose,
  onChange,
}: AudioClipSettingModalProps) => {
  // 상태 갱신용 및 데이터 전달용
  const [mainAudioVolume, setMainAudioVolume] = useState(initialVolume ?? 1);

  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={{ width: 400, background: 'white' }}>
        <div>Main Audio Volume</div>
        <div style={{ padding: '8px' }}>
          <Slider
            value={mainAudioVolume}
            onChange={(e, newVolume) => {setMainAudioVolume(+newVolume)}}
            min={0}
            max={1}
            step={0.01}
          />
        </div>

        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flex: 1 }} />
          <div style={{ width: 12 }} />
          <Button
            variant="text"
            onClick={() => {
              onChange(mainAudioVolume);
              onClose();
            }}
          >
            Apply
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
