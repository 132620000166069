import S3 from 'aws-sdk/clients/s3';

export const uploadFile = async (path: string, blob: Blob) => {
  const upload = new S3.ManagedUpload({
    params: {
      Bucket: 'yinca',
      Key: path,
      Body: blob,
    },
  });

  return upload.promise();
};
