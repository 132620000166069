import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import RobotIcon from '@/asset/robot.png';

interface SearchProps {
  hasAIButton?: boolean;
  value: string;
  onChange: (x: string) => void;
}
export const Search = ({
  hasAIButton = false,
  value,
  onChange,
  ...props
}: SearchProps) => {
  return (
    <SSearch style={{ height: '36px' }} {...props}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>

      <StyledInputBase
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
      />

      {hasAIButton && (
        <Right>
          <RobotButton src={RobotIcon} />
        </Right>
      )}
    </SSearch>
  );
};

const SSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.75),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Right = styled('div')(() => ({
  position: 'absolute',
  right: '6px',
  top: '4px',
  cursor: 'pointer',
}));
const RobotButton = styled('img')(() => ({
  width: '26px',
  height: '26px',
}));
