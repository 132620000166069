import Lottie from 'react-lottie';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled from 'styled-components';

//@ts-expect-error
import * as loadingLottie from '@/asset/lottie/loading.json';

//@ts-expect-error
import * as spinnerLottie from '@/asset/lottie/spinner.json';

export const Overlay = observer(() => {
  const { videoStore, uiStore } = useStores();

  // overlay를 re-rendering 하기 위해 loadingPercent 상태값 변화를 추적하도록 추가했습니다.
  if (
    videoStore.saving &&
    videoStore.saveMessage &&
    videoStore.loadingPercent
  ) {
    return <SavingIndicator />;
  }
  if (uiStore.loading) return <LoadingIndicator />;

  return null;
});

const LoadingIndicator = () => {
  return (
    <OverlayContainer>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: spinnerLottie.default,
        }}
        height={100}
        width={100}
      />
    </OverlayContainer>
  );
};

const SavingIndicator = () => {
  const { videoStore } = useStores();

  // console.log('SAve');

  return (
    <OverlayContainer>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingLottie.default,
        }}
        height={300}
        width={300}
      />
      {videoStore.saveMessage}

      <div style={{ textAlign: 'center' }}>
        {videoStore.loadingPercent.toFixed(2) + '%'}
      </div>

      <div
        style={{
          width: '32%',
          backgroundColor: '#A1C7E0EF',
          border: '2px solid #026E81',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            width: videoStore.loadingProgress?.currentPercent + '%',
            backgroundColor: '#0099DD',
            borderRadius: '8px',
          }}
        >
          &nbsp;
          {/* (어떠한 텍스트도 없다면) 배경색이 출력되지 않습니다. 그래서 &nbps; 를 추가했습니다. */}
          {/* {videoStore.loadingProgress?.currentPercent} */}
        </div>
      </div>
    </OverlayContainer>
  );
};

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;

  gap: 20px;
  color: white;
  font-weight: bold;
  font-size: 22px;

  background: rgba(0, 0, 0, 0.45);

  align-items: center;
  justify-content: center;

  z-index: 1000;
`;
