import React, { useEffect, useState } from 'react';
import { Button, Slider } from '@mui/material';
import { observer } from 'mobx-react';
import { eqFilters } from 'sfx';
import { useStores } from 'store';
import styled from 'styled-components';

import { Speakers } from 'component/graph';

interface EqMenuProps {}
export const EqMenu = observer(({}: EqMenuProps) => {
  const { videoStore } = useStores();
  const [expand, setExpand] = useState(false);
  const [values, setValues] = useState([1, 1, 1, 1, 1]);

  const onChange = (idx: number, e: any) => {
    const v = e.target.value;
    eqFilters[idx].gain.value = v;
    values[idx] = v;
    setValues([...values]);
  };
  const onClickReset = () => {
    const initialValues = [1, 1, 1, 1, 1];
    initialValues.map((x, idx) => (eqFilters[idx].gain.value = x));
    setValues(initialValues);
  };
  const onClickSave = () => {};

  return (
    <Container expand={expand}>
      <EqSliders>
        {[0, 1, 2, 3, 4].map(x => (
          <Slider
            marks
            orientation="vertical"
            step={1}
            min={-10}
            max={10}
            value={values[x]}
            onChange={e => onChange(x, e)}
          />
        ))}
      </EqSliders>
      <Buttons>
        <Button variant="contained" color="secondary" onClick={onClickReset}>
          Reset
        </Button>
        <Button variant="contained" onClick={onClickSave}>
          Save
        </Button>
      </Buttons>
      <div style={{ height: '12px' }} />

      <Speakers />
    </Container>
  );
});

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  gap: 6px;

  background: black;

  padding: 32px 16px;
  padding-left: 8px;

  overflow: auto;
`;
const EqSliders = styled.div`
  display: flex;
  gap: 20px;

  align-items: center;
  justify-content: center;

  height: 160px;

  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;

  padding: 32px 8px;
`;
const Buttons = styled.div`
  display: flex;
  gap: 20px;

  justify-content: flex-end;

  margin-top: 8px;
`;
