import React, { useState } from 'react';
import { RemoveCircleOutlineOutlined as RemoveIcon } from '@mui/icons-material';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateLuxon from '@mui/lab/AdapterLuxon';
import { Box, Button, IconButton, Modal, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import styled from 'styled-components';

interface TimeRangeModalProps {
  show: boolean;
  start: DateTime;
  end: DateTime;
  onClose: () => void;
  onChange: (start: DateTime, end: DateTime) => void;
  onRemove: () => void;
}
export const TimeRangeModal = ({
  show,
  start: initialStart,
  end: initialEnd,
  onClose,
  onChange,
  onRemove,
}: TimeRangeModalProps) => {
  const [start, setStart] = useState(initialStart);
  const [end, setEnd] = useState(initialEnd);

  console.log(start, end);

  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={{ width: 400, background: 'white' }}>
        <div style={{ height: '16px' }} />
        <LocalizationProvider dateAdapter={AdapterDateLuxon}>
          <TimeContainer>
            <TimePicker
              ampm={false}
              views={['minutes', 'seconds']}
              inputFormat="mm:ss"
              mask="__:__"
              label="Start"
              value={start}
              onChange={(newValue: any) => {
                setStart(newValue);
              }}
              renderInput={params => <TextField size="small" {...params} />}
            />
            &nbsp;~&nbsp;
            <TimePicker
              ampm={false}
              views={['minutes', 'seconds']}
              inputFormat="mm:ss"
              mask="__:__"
              label="End"
              value={end}
              onChange={(newValue: any) => {
                setEnd(newValue);
              }}
              renderInput={params => <TextField size="small" {...params} />}
            />
          </TimeContainer>
        </LocalizationProvider>
        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flex: 1 }} />
          <Button
            color="error"
            variant="text"
            onClick={() => {
              onRemove();
              onClose();
            }}
          >
            Delete
          </Button>
          <div style={{ width: 12 }} />
          <Button
            variant="text"
            onClick={() => {
              onChange(start, end);
              onClose();
            }}
          >
            Apply
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
`;
