import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
} from '@chatscope/chat-ui-kit-react';

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { uniqBy, uniqueId } from 'lodash-es';
import { useStores } from 'store';
import styled from 'styled-components';

import { isTestMode } from 'util/testmode';

interface IMessage {
  message: string;
  isMine: boolean;
  type?: 'loading';
  image?: string;
  effect?: any;
}

export const ChatPrompt = () => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const { uiStore, timelineStore, videoStore } = useStores();

  const onClose = () => {
    uiStore.showChatPrompt = false;
  };

  const onSubmit = async (_: any, message: string) => {
    setMessages(prev => [
      ...prev,
      {
        message,
        isMine: true,
      },
      {
        message: '',
        type: 'loading',
        isMine: false,
      },
    ]);

    const { effects } = await (
      await fetch(
        `https://encoder-api.surroundio.org/query?q=${encodeURIComponent(
          message,
        )}`,
        {
          method: 'GET',
        },
      )
    ).json();

    console.log(effects);

    try {
      setMessages(prev => prev.filter(x => x.type !== 'loading'));

      if (effects.length === 0) {
        throw new Error('검색된 이펙트가 없습니다.');
      }

      uniqBy(effects, 'name')
        .slice(0, 3)
        .forEach((x: any) => {
          setMessages(prev => [
            ...prev,
            {
              image: x.image,
              message: x.name,
              effect: x.effect,
              isMine: false,
            },
          ]);
        });
    } catch (e) {
      console.error(e);

      setMessages(prev => [
        ...prev,
        {
          message: '인식할 수 없는 내용이에요',
          isMine: false,
        },
      ]);
    }
  };

  const onClickMessage = (message: IMessage) => {
    const last = Math.max(0, ...timelineStore.effects.map(x => x.end));

    uiStore.showChatPrompt = false;

    timelineStore.addEffect({
      effect: {
        //@ts-expect-error
        id: uniqueId(), // what is this?
        name: message.message,
        nodes: message.effect,
      },
      group: 0,
      start: last,
      end: last + (isTestMode() ? 30 : videoStore.getDuration()),
    });
  };

  return (
    <Container onClick={onClose}>
      <ChatBox onClick={e => e.stopPropagation()}>
        <ChatContainer>
          <MessageList style={{ paddingTop: '12px' }}>
            {messages.map((x, index) => (
              <Message
                key={index}
                model={{
                  position: 0,
                  message: x.message,
                  sentTime: 'just now',
                  direction: x.isMine ? 'outgoing' : 'incoming',
                }}
                style={{ cursor: 'pointer' }}
                onClick={() => onClickMessage(x)}
              >
                {x.type === 'loading' && (
                  <Message.CustomContent>
                    <ReactLoading
                      width={32}
                      height={32}
                      type="bubbles"
                      color="rgb(53, 126, 221)"
                    />
                  </Message.CustomContent>
                )}
                {x.image && (
                  <Message.CustomContent>
                    {/*}
                    <img
                      crossOrigin="anonymous"
                      src={x.image}
                      alt="effect image"
                      style={{
                        width: '200px',
                        height: '140px',
                        objectFit: 'cover',
                      }}
                    />
                    */}

                    <div>{x.message}</div>
                  </Message.CustomContent>
                )}
              </Message>
            ))}
          </MessageList>
          <MessageInput
            autoFocus
            attachButton={false}
            placeholder="Type message here"
            onSend={onSubmit}
          />
        </ChatContainer>
      </ChatBox>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  display: flex;

  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;

  background: rgba(0, 0, 0, 0.4);

  align-items: center;
  justify-content: center;

  z-index: 1000;
`;

const ChatBox = styled(MainContainer)`
  width: 50%;
  max-width: 640px;
  height: 50%;

  @media (max-width: 480px) {
    width: calc(100vw - 40px);
  }
`;
