import { DocumentData, DocumentSnapshot } from 'firebase/firestore';
import { observable } from 'mobx';

export interface IOfflineRenderStore {
  progress: number;
  fireDoc: DocumentSnapshot<DocumentData> | null;
}
export const offlineRenderStore = observable<IOfflineRenderStore>({
  progress: 0,
  fireDoc: null,
});
