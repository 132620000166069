import React, { useEffect, useRef, useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DateTime } from 'luxon';
import { useObserver } from 'mobx-react';
import { IUploadedVideo } from 'model';
import { useStores } from 'store';
import styled, { css } from 'styled-components';

import { getAuthTokenFromCookie, getCookie } from 'util/cookie';
import { downloadFileUrl } from 'util/download';
import { request } from 'util/http-client';
import { Search } from 'component/input';
import Confirm from 'component/popup/Confirm';
import { timelineStore } from 'store/timeline';
import { videoStore } from 'store/video';

interface HistoryDesktopProps {
  show: boolean;
}
export const HistoryDesktop = ({ show }: HistoryDesktopProps) => {
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [removeOk, setRemoveOk] = useState(false);
  const [q, setQ] = useState('');
  const [list, setList] = useState<IUploadedVideo[]>([]);
  const [checked, setChecked] = useState<string[]>([]);

  const allCheckBoxRef = useRef<HTMLInputElement>(null);
  const checkBoxArrayRef = useRef<HTMLInputElement[]>([]);

  const { uiPopup, uiHistory } = useStores();

  /** 테이블에 표시할 이름의 리스트 */
  const tableNameArray = [
    '',
    'No',
    'Thumnail',
    'Video',
    'Effect',
    'Audio',
    'Date',
    'Link',
    'Download',
  ];

  /** 테이블에 표시할 영역의 너비에 대한 percent 값 (총합이 100을 넘으면 안됨) */
  const tableWidthPercentArray = [4, 4, 8, 20, 16, 16, 12, 10, 10];

  // header checkbox 클릭 시, 하위 데이터 리스트 체크 박스 선택
  const allCheckDeleteCheckBoxOnClickHandle = () => {
    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        if (!element) return;
        element.checked = isAllCheck ? false : true;
        setIsAllCheck(isAllCheck ? false : true);
      });
    }

    let isAllChecked = !isAllCheck ? true : false;
    allCheckBoxRef.current!.checked = isAllChecked;
  };

  // 데이터 리스트 중 체크 박스 클릭 시, 전체 선택 여부에 따라 header checkbox 해제 또는 선택
  const listCheckBoxOnClickHandle = (key?: number) => {
    let isAllChecked = true;

    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        // 전체 선택이 안되는 조건일 시, 전체 선택 체크 박스 False 처리
        if (element && !element.checked) isAllChecked = false;

        // 체크박스를 직접 클릭할 경우
        if (key) {
          if (element && Number(element.value) == key) {
            element.checked = element.checked ? false : true;
          }
        }
      });
    }

    allCheckBoxRef.current!.checked = !isAllChecked ? false : true;
  };

  // confirm 컴포넌트 내, 확인|취소 상태 확인을 위해 하위로 내린 함수
  const confirmButtonOnClick = (isOk: boolean) => {
    isOk ? setRemoveOk(true) : onClose();
  };

  // 삭제 버튼 클릭 시 동작 및 삭제된 데이터를 제외한 리스트 출력을 위한 set useState
  const removeMusicList = () => {
    // 선택된 리스트의 key List 배열
    let checkedRefValue: string[] = [];

    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        if (element && element.checked) checkedRefValue.push(element.value);
      });
    }

    if (checkedRefValue.length < 1) {
      alert('Please select the list you want to delete.');
      return;
    }

    !removeOk ? uiPopup.showLoading() : uiPopup.hideLoading();
  };

  const onClickApply = (item: any) => {
    timelineStore.load(JSON.parse(item.data));

    uiHistory.hideLoading();
  };

  const rowListRemoveCheckOnClick = (videoId: string) => {
    setChecked(
      checked.includes(videoId)
        ? checked.filter(x => x !== videoId)
        : [...checked, videoId],
    );

    listCheckBoxOnClickHandle();
  };

  const removeAction = async () => {
    if (removeOk) {
      const memberId = getCookie('SU_TOKEN');

      if (!memberId) {
        alert('User Auth Invalid! Try Again Please.');
        window.location.href = 'https://surroundio.org';
      }

      try {
        for (const id of checked) {
          await request('POST', '/history/delete', {
            vid: id,
          });
        }

        setRemoveOk(false);
        alert('Successfully processed.');
      } catch (error) {
        alert('A temporary error has occurred. History Delete Error');
        window.location.reload();
      }
    }
  };

  const onClickDownload = async (url: string) => {
    await downloadFileUrl(url, videoStore.video);
  };

  const onClickBGM = (item: IUploadedVideo) => {
    uiHistory.hideLoading();
  };

  const onClose = () => {
    setRemoveOk(false);
    uiPopup.hideLoading();
  };

  useEffect(() => {
    const authToken = getAuthTokenFromCookie();

    if (!authToken) {
      alert('User Auth Invalid! Try Again Please.');
      //window.location.href = 'https://surroundio.org';
    }

    const data: any = request('GET', '/history');

    data.then((res: any) => setList(res.data));

    if (removeOk) {
      removeAction();
      uiPopup.hideLoading();
    }
  }, [show, removeOk]);

  return useObserver(() =>
    show ? (
      <Wrapper>
        <Container
          show={show}
          onClick={() => {
            uiHistory.hideLoading();
          }}
        >
          {/* delete 영역을 눌렀을 때는 delete화면만 해제됩니다. 
      Confirm 컨테이너 안에 onClick을 정의할 수 없어 div태그로 감싸서 만들었습니다. */}
          <div onClick={e => e.stopPropagation()}>
            <Confirm
              {...{
                block: uiPopup.loading,
                header: 'Delete History',
                content:
                  'You cannot download this work again. \n Are you sure?',
                confirmButtonOnClick,
              }}
            />
          </div>

          <BacgroundOpacity />

          <RegisterMusicBoardContainer>
            {/* 테이블 영역을 눌렀을 때는 cloud화면이 해제되지 않도록 Container의 onClick기능을 무효로 했습니다. */}
            <Table onClick={e => e.stopPropagation()}>
              <DeleteButtonSection>
                <Search value={q} onChange={setQ} />
                <div style={{ width: '12px' }} />
                <button onClick={removeMusicList}>Delete</button>
              </DeleteButtonSection>

              <TableHeader>
                <TableColumn setPercentColumnWidth={tableWidthPercentArray[0]}>
                  <input
                    ref={allCheckBoxRef}
                    type="checkbox"
                    onClick={allCheckDeleteCheckBoxOnClickHandle}
                  />
                </TableColumn>

                {/* 위에서 0번 테이블을 표시하였으므로, 인덱스는 1번부터 처리 */}
                {tableNameArray.map((value, index, array) => {
                  if (index === 0) return null;

                  return (
                    <TableColumn
                      setPercentColumnWidth={tableWidthPercentArray[index]}
                    >
                      <span>{tableNameArray[index]}</span>
                    </TableColumn>
                  );
                })}
              </TableHeader>

              <TableData>
                {list && list.length > 0
                  ? list
                      .filter(
                        x => x.title.includes(q) || x.description.includes(q),
                      )
                      .map((item: any, index: number) => {
                        return (
                          <TableRow
                            onClick={e => {
                              onClickApply(item);
                              e.stopPropagation();
                            }}
                            key={index}
                          >
                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[0]}
                            >
                              <input
                                type="checkbox"
                                ref={elem =>
                                  (checkBoxArrayRef.current[index] = elem!)
                                }
                                checked={checked.includes(item.videoId)}
                                onClick={e => {
                                  e.stopPropagation();
                                  rowListRemoveCheckOnClick(item.videoId);
                                }}
                              />
                            </TableColumn>

                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[1]}
                            >
                              <span>{index + 1}</span>
                            </TableColumn>
                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[2]}
                            >
                              <img
                                crossOrigin="anonymous"
                                src={item.thumbnailUrl}
                              />
                            </TableColumn>
                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[3]}
                            >
                              <span>{item.title}</span>
                            </TableColumn>
                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[4]}
                            >
                              <span>{item.description}</span>
                            </TableColumn>
                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[5]}
                              onClick={() => onClickBGM(item)}
                            >
                              <span>{item.bgm}</span>
                            </TableColumn>
                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[6]}
                            >
                              <span
                                title={DateTime.fromISO(
                                  item.created_at,
                                ).toLocaleString()}
                              >
                                {DateTime.fromISO(
                                  item.created_at,
                                ).toLocaleString()}
                              </span>
                            </TableColumn>
                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[7]}
                              onClick={() => {
                                // 참고: https환경에서만 동작 가능
                                // 참고2: 해당 링크를 클릭한경우, 동영상 파일을 바로 다운로드 진행.
                                // 동영상을 보여주기 위해서, 페이지를 제공할 필요가 있습니다.
                                navigator.clipboard
                                  .writeText(item.videoUrl)
                                  .then(() => {
                                    alert('복사가 완료되었습니다.');
                                  });
                              }}
                            >
                              <span
                                style={{ cursor: 'pointer', color: 'blue' }}
                              >
                                Link Copy
                              </span>
                            </TableColumn>
                            <TableColumn
                              setPercentColumnWidth={tableWidthPercentArray[8]}
                              style={{ cursor: 'pointer' }}
                            >
                              <CloudDownloadIcon
                                color="primary"
                                onClick={() => onClickDownload(item.videoUrl)}
                              />
                            </TableColumn>
                          </TableRow>
                        );
                      })
                  : ''}
              </TableData>
            </Table>
          </RegisterMusicBoardContainer>
        </Container>
      </Wrapper>
    ) : null,
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
`;
const Container = styled.div<{ show: boolean }>`
  position: absolute;
  left: 64px;
  top: 64px;

  width: calc(100% - 64px);
  height: calc(100% - 64px);
  max-width: 100%;
  min-width: 720px;

  z-index: 3;

  ${({ show }) =>
    show == false
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}

  justify-content: center;
`;

const RegisterMusicBoardContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;
const BacgroundOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.6;
`;

const Table = styled.div`
  background-color: rgba(128, 128, 128, 0.8);
  position: relative;
  width: 100%;
  height: calc(100% - 20px);
  min-height: calc(100% - 20px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 20px;
`;

const TableHeader = styled.div`
  width: 97%;
  height: 40px;
  background-color: black;

  display: flex;
  flex-direction: row;

  margin-bottom: 10px;
  border-radius: 5px;
`;

const TableRow = styled.div`
  width: 97%;
  height: 60px;
  min-height: 60px;

  display: flex;
  flex-direction: row;

  margin-bottom: 3px;

  border-bottom: thin solid white;

  background-color: #ffffff;
  border-radius: 5px;

  &:hover {
    width: 98%;
    height: 65px;
    box-shadow: 2px 2px 5px 1px #888282;
    z-index: 99990;
  }
`;

const TableColumn = styled.div<any>`
  width: ${props => {
    return props.setPercentColumnWidth;
  }}%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-weight: bold;
  font-size: 0.8rem;

  img {
      width: 50px;d
      height: 50px;
  }

  span {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TableData = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;

  span {
    color: black;
  }

  .download {
    color: blue;
  }
`;

const DeleteButtonSection = styled.div`
  width: 97%;

  display: flex;
  justify-content: right;

  button {
    width: 90px;
    height: 35px;
    margin-bottom: 10px;

    border: none;
    border-radius: 5px;

    background-color: #d33e3e;
    color: white;

    font-weight: bold;
    box-shadow: none;

    &:hover {
      cursor: pointer;
      background-color: red;
    }
  }
`;
