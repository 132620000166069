import styled from 'styled-components';

export const HeaderIcon = styled.img`
  width: 42px;
  height: 42px;

  margin-right: 10px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
