import Reverb from "@logue/reverb";
import { videoStore } from "store/video";
import Tuna from "tunajs";
import { getMaxVolume } from "./getPCM";
import { createEffectNodes, createEffects } from "./typed-webaudio";

let surroundSoundSpeakerMode = "5.1";

let currentPosition = { x: 0, z: 0 };
let targetPosition = { x: 0, z: 0 };
let ctx = null;
let splitter = null;

export let finalGain = null;
export let eqFilters = [];
let volume = 1.0;
let currentReverb = null;
let panners = [];
let far = false;
let listener = null;
let dst = null;
let reverbEx = null;
let effects = {};
let tuna;
let volumeLevel = 1;

let prefs = {
  reverb: "none",
  pannerPositions: [],
};
let prevSurround = {};

function disablePanner(idx) {
  panners[idx].setPosition(0, 0, 0);
}
function enablePanner(idx) {
  if (idx === 0) panners[0].setPosition(-10, 0, 1); // L
  if (idx === 1) panners[1].setPosition(10, 0, 1); // R
  if (idx === 2) panners[2].setPosition(0, 0, -10); // CENTER
  if (idx === 3) panners[3].setPosition(0, 0, 10);
  if (idx === 4) panners[4].setPosition(-5, 0, -10); // SL
  if (idx === 5) panners[5].setPosition(5, 0, -10); // SR
}

const applyPrefs = () => {
  //	prefs = {};//loadPrefs();

  if (prefs.reverb) setReverb(prefs.reverb);
  if (prefs.position) setListenerPosition(prefs.position.x, prefs.position.y);
  if (prefs.mode) changeSurroundMode(prefs.mode);
};

export const createLoopback = async (dest) => {
  let rtcConnection = null;
  let rtcLoopbackConnection = null;
  let loopbackStream = new MediaStream(); // this is the stream you will read from for actual audio output

  const offerOptions = {
    offerVideo: true,
    offerAudio: true,
    offerToReceiveAudio: false,
    offerToReceiveVideo: false,
  };

  let offer, answer;

  // initialize the RTC connections

  rtcConnection = new RTCPeerConnection();
  rtcLoopbackConnection = new RTCPeerConnection();

  rtcConnection.onicecandidate = (e) =>
    e.candidate &&
    rtcLoopbackConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
  rtcLoopbackConnection.onicecandidate = (e) =>
    e.candidate &&
    rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

  rtcLoopbackConnection.ontrack = (e) =>
    e.streams[0].getTracks().forEach((track) => loopbackStream.addTrack(track));

  // setup the loopback
  rtcConnection.addStream(dest); // this stream would be the processed stream coming out of Web Audio API destination node

  offer = await rtcConnection.createOffer(offerOptions);
  await rtcConnection.setLocalDescription(offer);

  await rtcLoopbackConnection.setRemoteDescription(offer);
  answer = await rtcLoopbackConnection.createAnswer();
  await rtcLoopbackConnection.setLocalDescription(answer);

  await rtcConnection.setRemoteDescription(answer);

  return loopbackStream;
};
export const saveState = () => {
  console.log("save state");

  prevSurround.dst = dst;
  prevSurround.ctx = ctx;
  prevSurround.listener = listener;
  prevSurround.finalGain = finalGain;
  prevSurround.panners = panners;
  prevSurround.eqFilters = eqFilters;
  prevSurround.reverbEx = reverbEx;
  prevSurround.currentReverb = currentReverb;
  prevSurround.tuna = ctx.tuna;
};
export const restoreState = () => {
  console.log("restore state");
  dst = prevSurround.dst;
  window.ctx = ctx = prevSurround.ctx;
  listener = prevSurround.listener;
  finalGain = prevSurround.finalGain;
  panners = prevSurround.panners;
  eqFilters = prevSurround.eqFilters;
  reverbEx = prevSurround.reverbEx;
  currentReverb = prevSurround.currentReverb;
  ctx.tuna = new Tuna(ctx);
};

export const applySurroundSound = async (_ctx, src, _dst, options = {}) => {
  const { defaultGain = 1 } = options;

  eqFilters = [];

  _ctx.tuna = new Tuna(_ctx);

  window.ctx = ctx = _ctx;
  dst = _dst;
  volumeLevel = 1.0; //await getMaxVolume(inputFile);
  listener = ctx.listener;
  listener.setOrientation(0, 0, -1, 0, 1, 0);

  reverbjs.extend(ctx);

  const merger = ctx.createGain();
  merger.gain.value = defaultGain;

  if (ctx.destination.maxChannelCount >= 6) {
    ctx.destination.channelCount = 6;
    ctx.destination.channelInterpretation = "speakers";
    ctx.destination.channelCountMode = "explicit";
  }

  splitter = ctx.createChannelSplitter(2);
  src.disconnect();

  src.connect(merger);
  merger.connect(dst);
  finalGain = merger;

  return;
};
export const restorePosition = () => {
  for (let i = 0; i < prefs.pannerPositions.length; i++) {
    const p = prefs.pannerPositions[i];
    if (!p) continue;
    setSpeakerPosition(i, p.x, p.y);
  }
  if (!!prefs.position) setListenerPosition(prefs.position.x, prefs.position.y);
};

export const setEffects = (subEffect, options = {}) => {
  console.log("SubEffect", subEffect, options);

  if (!subEffect || !finalGain) return;

  try {
    // const dst = videoStore.mixer.audioContext.destination;

    // finalGain.disconnect();

    // if (subEffect.length === 0) {
    //   finalGain.connect(dst);
    //   return;
    // }

    const effects = createEffectNodes(videoStore.mixer.audioContext, subEffect);
    videoStore.mixer.setEffects(effects)

    // finalGain.connect(effects[0]);
    // effects[effects.length - 1].connect(dst);

    // console.log("DST", dst);
  } catch (e) {
    console.error(e);
  }
};

export const setReverbEx = (obj) => {
  if (!obj) {
    reverbEx.mix(0.5);
    reverbEx.filterFreq(2200);
    reverbEx.decay(5);
    reverbEx.time(3);
    return;
  }

  reverbEx.mix(obj.mix);
  reverbEx.filterFreq(obj.filterFreq);
  reverbEx.decay(obj.decay);
  reverbEx.time(obj.time);
};

export const setEq = (ary) => {
  if (!ary) return;

  eqFilters.map((x, idx) => {
    x.gain.value = ary[idx];
  });
};

export const setVolumeLevel = (inputLevel) => {
  if (isNaN(+inputLevel)) inputLevel = 1;

  console.log(
    "setVolumeLevel " + inputLevel + " / " + +inputLevel / volumeLevel
  );
  finalGain.gain.value = +inputLevel / volumeLevel;
};

export const setReverb = (mode, onSuccess) => {
  console.log("reverb", mode);

  prefs.reverb = mode;

  const apply = (url) => {
    if (currentReverb) {
      currentReverb.disconnect();
      finalGain.connect(dst);
      currentReverb = null;
    }

    const reverbNode = ctx.createReverbFromUrl(
      url.startsWith("/themes") ? `https://www.nationalux.com${url}` : url,
      function () {
        onSuccess?.();
      }
    );
    reverbNode.connect(dst);
    finalGain.connect(reverbNode);
    currentReverb = reverbNode;
  };

  if (mode === "none" && currentReverb) {
    currentReverb.disconnect();
    finalGain.connect(dst);
    currentReverb = null;
  } else if (mode === "streo") {
    apply("/themes/youplay/reverb/DomesticLivingRoom.m4a");
  } else if (mode === "basement") {
    apply("/themes/youplay/reverb/Basement.m4a");
  } else if (mode === "small_hall") {
    apply("/reverb/HamiltonMausoleum.m4a");
  } else if (mode === "church") {
    apply("/themes/youplay/reverb/StAndrewsChurch.m4a");
  } else if (mode === "optimize") {
    apply("/themes/youplay/reverb/UndergroundCarPark.m4a");
  } else if (mode === "large_hall") {
    apply("/themes/youplay/reverb/YorkMinster.m4a");
  } else if (mode === "surround") {
    apply("/themes/youplay/reverb/ElvedenHallLordsCloakroom.m4a");
  }
};

export const setSpeakerPosition = (idx, x, y) => {
  prefs.pannerPositions[idx] = { x, y };
  panners[idx]?.setPosition((x - 150) * 0.1, 0, (y - 150) * 0.1);
};
export const setListenerPosition = (x, y) => {
  prefs.position = { x, y };

  window.setListenerPosition = setListenerPosition;

  listener.setPosition((x - 150) * 0.1, 0, (y - 150) * 0.1);
  console.log((x - 150) * 0.1, 0, (y - 150) * 0.1);

  const ax = x - 0;
  const ay = y - 0;
  const getDist = (elem) =>
    Math.sqrt(
      Math.pow(ax - elem[0].offsetLeft, 2) + Math.pow(ay - elem[0].offsetTop, 2)
    );
  const clamp = (v) => Math.min(Math.max(0, v), 0.5);

  const setDistanceEffect = (elem, v) => {
    elem.css("opacity", 1 - v);
    elem.css("width", `${38 + (0.5 - v) * 16}px`);
    elem.css("height", `${38 + (0.5 - v) * 16}px`);
  };

  /*
  setDistanceEffect($('.speaker-l'), clamp(0.004 * getDist($('.speaker-l'))));
  setDistanceEffect($('.speaker-r'), clamp(0.004 * getDist($('.speaker-r'))));
  setDistanceEffect($('.speaker-sr'), clamp(0.004 * getDist($('.speaker-sr'))));
  setDistanceEffect($('.speaker-sl'), clamp(0.004 * getDist($('.speaker-sl'))));
  setDistanceEffect($('.speaker-center'), clamp(0.004 * getDist($('.speaker-center'))));
  setDistanceEffect($('.speaker-rear'), clamp(0.004 * getDist($('.speaker-rear'))));
	*/
};

export const changeSurroundMode = (mode) => {
  prefs.mode = mode;
  surroundSoundSpeakerMode = mode;

  if (mode === "5.1") {
    $(".speaker-center").show();
    $(".speaker-sr").show();
    $(".speaker-sl").show();
    $(".speaker-rear").show();

    enablePanner(2);
    enablePanner(3);
    enablePanner(4);
    enablePanner(5);

    volume = 1.254;
  } else if (mode === "2.1") {
    $(".speaker-center").hide();
    $(".speaker-sr").hide();
    $(".speaker-sl").hide();
    $(".speaker-rear").show();

    disablePanner(2);
    disablePanner(4);
    disablePanner(5);
    enablePanner(3);

    volume = 0.85;
  } else if (mode === "streo") {
    $(".speaker-center").hide();
    $(".speaker-sr").hide();
    $(".speaker-sl").hide();
    $(".speaker-rear").hide();

    disablePanner(2);
    disablePanner(3);
    disablePanner(4);
    disablePanner(5);

    volume = 1.0;
  }

  finalGain.gain.value = (far ? 1.63 : 1.63) * volume;
};

window.exportValue = () => {
  console.log(
    JSON.stringify({
      eq: [
        eqFilters[0].gain.value,
        eqFilters[1].gain.value,
        eqFilters[2].gain.value,
        eqFilters[3].gain.value,
        eqFilters[4].gain.value,
      ],
    })
  );
};
