import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ShareIcon from 'asset/header/share.svg';

import { ShareModal } from 'component/popup';
import { HeaderIcon } from './HeaderIcon';

export const ShareButton = observer(() => {
  const [show, setShow] = useState(false);

  return (
    <>
      <HeaderIcon src={ShareIcon} onClick={() => setShow(true)} />
      <ShareModal show={show} onClose={() => setShow(false)} />
    </>
  );
});
