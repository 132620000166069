import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import 'react-timelines2/lib/css/style.css';
import { TopmostProvider } from 'util/topmost';
import { AppPage } from 'page/AppPage';

const App = () => {
  React.useEffect(() => {
    window.history.pushState({}, document.title, window.location.pathname);
  }, []);

  return (
    <GoogleOAuthProvider clientId="117217818595-0qr5bho7tp274i62ijgtaei27t9ms1mo.apps.googleusercontent.com">
      <TopmostProvider>
        <AppPage />
      </TopmostProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
