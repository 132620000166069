//@ts-check

/**
 * 타임라인 뷰 (시간 길이를 기준으로 사각형을 그려주는 뷰의 역할을 함)
 * 
 * 참고: 일정을 표시하는 도구가 아님.
 * 
 * @version 2024/06/05
 */
export class TimelineView {
  /**
   * TimelineView를 표시할 수 있는 div태그를 생성합니다.
   * 
   * 생성된 태그는 element 변수에 있으며, 이것을 직접 appendChild 함수를 통해 넣어야 합니다.
   * 
   * @param {string} cssWidth css 형식의 너비
   * @param {string} cssHeight css 형식의 높이
   */
  constructor (cssWidth = '100%', cssHeight = '100%') {
    /** 이 클래스의 데이터를 표시할 기본적인 element */
    this.element = document.createElement('div')
    this.element.style.width = cssWidth
    this.element.style.height = cssHeight
    this.element.style.display = 'flex'
    this.element.style.flexDirection = 'column'
    this.element.style.position = 'absolute'

    // 클래스 코드 자동완성용
    let Layer = class extends TimelineView.Layer {}

    /** 
     * TimelineView에서 내부적으로 관리하는 레이어 단위
     * @type {Layer[]}
     */
    this.layer = []

    /** 리사이즈옵저버, 크기 변경시, 레이어의 내부 좌표를 다시 측정하는데 사용 */
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let i = 0; i < this.layer.length; i++) {
        this._layerResizeProcess(i) // 모든 레이어의 내부 크기및 위치를 다시 설정함
      }
    })

    this.resizeObserver.observe(this.element)

    this.element.addEventListener('dragend', (e) => {
      // 드래그가 끝나면, 내부 엘리먼트가 멀티라인의 의하여 재배치됨
      for (let i = 0; i < this.layer.length; i++) {
        const currentLayer = this.layer[i]
        currentLayer._rearrange()
      }
    })

    /** 마지막으로 서브엘리먼트가 만들어진 번호 @private */
    this._lastCreateNumber = {
      layer: 0,
      subElement: 0,
    }

    /** 마지막으로 클릭한 대상의 데이터 
     * 
     * 주의: 이 값은 다른 서브엘리먼트를 누르기 전까지 갱신되지 않으므로, 
     * 
     * 이 값을 사용하고 난 다음 resetLastClickTargetData 함수를 사용해주세요.
    */
    this.lastClickTargetData = {
      id: '',
      textContent: '',
      percentPosition: 0,
      percentLength: 0,
      percentLengthMax: 0,
      percentLengthStart: 0,
      percentLengthEndback: 0,
      /** 레이어 번호 */ layerNumber: -1,
      /** 서브엘리먼트 번호: -1인경우 아무것도 눌리지 않음. */ subElementNumber: -1,
      /** 이 이벤트가 먼저 선언된 타겟의 타입 (timelineView 내부에서만 활용됨) */ elementType: '',
    }

    this.elementTypeList = {
      LAYER: 'layer',
      SUBELEMENT: 'subElement',
    }

    /** 콘텐츠 총 길이의 값 */
    this.contentValue = 100
  }

  /** 마지막으로 클릭한 타겟의 데이터를 지웁니다. 
   * (lastClick이 잘못된 처리를 한다고 생각하면, 이 함수를 통해 lastClick 데이터를 초기화해주세요.) 
   * 
   * 기본적으로 lastClick은 사용자가 값을 사용한 후에도 갱신되지 않습니다.
   * */
  resetLastClickTargetData () {
    this.lastClickTargetData.elementType = ''
    this.lastClickTargetData.id = ''
    this.lastClickTargetData.textContent = ''
    this.lastClickTargetData.percentLength = 0
    this.lastClickTargetData.percentLengthEndback = 0
    this.lastClickTargetData.percentLengthMax = 0
    this.lastClickTargetData.percentLengthStart = 0
    this.lastClickTargetData.percentPosition = 0
    this.lastClickTargetData.layerNumber = -1
    this.lastClickTargetData.subElementNumber = -1
  }

  /**
   * 레이어가 resize되면, 내부 크기 위치를 다시 설정합니다.
   * @param {number} layerNumber 
   * @private
   */
  _layerResizeProcess (layerNumber) {
    if (layerNumber < 0 && layerNumber >= this.layer.length) return
    let current = this.layer[layerNumber]
    let title = this.layer[layerNumber].titleElement
    let content = this.layer[layerNumber].contentElement
    
    for (let i = 0; i < current.subElement.length; i++) {
      let sub = current.subElement[i]
      sub.content.positionX = title.getBoundingClientRect().left + title.getBoundingClientRect().width
      sub.content.positionY = title.getBoundingClientRect().top
      sub.content.left = content.getBoundingClientRect().left
      sub.content.top = content.getBoundingClientRect().right
      sub.content.width = content.getBoundingClientRect().width
      sub.content.height = content.getBoundingClientRect().height
    }
  }

  static Layer = class {
    /**
     * 새로운 레이어를 생성합니다. (TimelineView에서 사용)
     * @param {string} cssWidth
     * @param {string} cssHeight 
     * @param {string | undefined} cssBackgroundColor 
     */
    constructor (cssWidth = '100%', cssHeight = '100%', cssBackgroundColor = undefined) {
      this.layerElement = document.createElement('div')
      this.layerElement.style.width = cssWidth
      this.layerElement.style.height = cssHeight
      this.layerElement.style.display = 'flex'
      this.layerElement.style.flexDirection = 'row'
      if (cssBackgroundColor) this.layerElement.style.backgroundColor = cssBackgroundColor

      /** 해당 레이어의 타이틀 표시 (무조건 왼쪽에 배치됨) */
      this.titleElement = document.createElement('div')
      this.layerElement.appendChild(this.titleElement) // 레이어 엘리먼트에 타이틀 영역 추가

      /** 해당 레리어의 컨텐츠 표시 (타이틀 오른쪽에 배치됨) */
      this.contentElement = document.createElement('div')
      this.contentElement.style.width = '100%'
      this.contentElement.style.position = 'relative'
      this.layerElement.appendChild(this.contentElement) // 레이어 엘리먼트에 콘텐츠 영역 추가

      /** 서브 엘리먼트에 대한 드래그 가능 여부 */
      this.dragPossible = true

      this.layerElement.addEventListener('mousemove', (e) => {

      })

      /** 
       * 이 레이어가 서브엘리먼트에 대해 몇개의 라인을 표시하는지에 대한 값 (기본값: 1)
       * 
       * 라인이 겹칠 경우, 멀티라인이 표시할 수 있는 만큼 겹치지 않게 표시됩니다.
       * 
       * 주의: isOverlap이 true일 때만, 이 값이 유효합니다.
       */
      this.multiLine = 1

      let SubElement = class extends TimelineView.SubElement {}
      /**
       * 콘텐츠 내부에서 사용하는 서브 엘리먼트
       * @type {SubElement[]} 
       */
      this.subElement = []

      /** 오버랩: 이 값이 true일경우, 겹치기가 허용됨 (주의: 이 옵션이 false일경우 멀티라인이 무시됨) */ 
      this.isOverlap = true
    }

    /**
     * 해당 레이어의 타이틀을 설정합니다.
     * @param {string} titleText 
     * @param {string} cssWidth 
     * @param {string} cssHeight 
     * @param {string | undefined} cssColor 
     * @param {string | undefined} cssBackgroundColor 
     */
    setTitle (titleText = '', cssWidth = '20%', cssHeight = '50%', cssColor = undefined, cssBackgroundColor = undefined) {
      this.titleElement.textContent = titleText
      this.titleElement.style.width = cssWidth
      this.titleElement.style.height = cssHeight
      this.titleElement.style.overflow = 'hidden'
      if (cssColor) this.titleElement.style.color = cssColor
      if (cssBackgroundColor) this.titleElement.style.backgroundColor = cssBackgroundColor
    }

    /**
     * 해당 레이어의 일부 영역을 표시할 새 엘리먼트를 만듭니다.
     * 
     * 주의: 이 함수를 외부에서 직접 호출해 사용하면, 타임라인 뷰 입장에서 뭐가 마지막으로 생성되었는지를 알지 못함.
     * 그리고, 이 함수는 서브엘리먼트에 대한 보정이 없으므로, 문제가 생길 수 있음.
     * 
     * @param {string} textContent 텍스트 콘텐츠 
     * @param {number} positionPercent 포지션이 위치할 퍼센트 값 구간
     * @param {number} positionLength 포지션에 대한 엘리먼트 길이
     * @param {string | undefined} cssColor 
     * @param {string | undefined} cssBackgroundColor
     */
    _addSubElement (textContent = '', positionPercent = 0, positionLength = 1, cssColor = undefined, cssBackgroundColor = undefined) {
      let target = new TimelineView.SubElement(textContent, positionPercent, positionLength, cssColor, cssBackgroundColor)
      this.subElement.push(target)
      this.contentElement.appendChild(target.element)
      this.contentElement.appendChild(target.shadowElement)

      return target
    }

    removeAllSubElement () {
      for (let i = 0; i < this.subElement.length; i++) {
        // 배열 내 엘리먼트 및 이벤트 전부 제거
        this.subElement[i].removeElement()
      }

      // 배열 초기화
      this.subElement = []
    }

    /** 서브 엘리먼트에 대한 충돌 개수를 가져옴 (x축 충돌만 조사함) */
    getCollisionCount () {
      let maxCount = 0

      // 모든 서브 엘리먼트 중 가장 많이 겹치는 개수를 리턴함
      for (let i = 0; i < this.subElement.length; i++) {
        let count = this._indexCollisionCheck(i)
        maxCount = count > maxCount ? count : maxCount
      }

      return maxCount
    }

    /** 
     * 오버랩이 false일 때에 대한, 비어있는 공간을 찾아서, 해당 값을 가져옴
     * 
     * 이 함수는 2개 이상의 서브엘리먼트를 처리할 때 문제가 있음을 알아냄.
     * 
     * 앞에 있는 공간이 비어있는경우, 임의의 수치로 서브엘리먼트의 위치를 지정할 때
     * 맨 앞의 공간으로 보내버리는 문제가 있어서, 원하는 결과가 제대로 나오지 않음.
     * 
     * @deprecated
     */
    getOverlapFalseBlankStart () {
      this._subElementSort() // 기존에 있는 모든 배열을 정렬 (정렬은 포지션순)
      let start = 0 // blankStart
      let end = 100 // blankEnd

      for (let i = 0; i < this.subElement.length; i++) {
        let time = this.subElement[i].percent.position // 각 서브엘리먼트의 퍼센트 포지션 위치
        if (time <= start && time - start < 0.01) { // 퍼센트 위치가 시작지점보다 작고, 그 오차범위가 0.01 이하인경우
          start = time + this.subElement[i].percent.length // 비어있지 않은 공간이면 다음으로 넘어감
        } else if (i === this.subElement.length - 1) { // i가 마지막 번호일경우
          end = time // 해당 서브 엘리먼트의 위치를 빈공간 끝부분으로 정의함
          break
        } else {
          end = this.subElement[i].percent.position // 현재 서브 엘리먼트의 위치를 빈공간 끝 부분으로 정의함
          break
        }
      }

      return {
        start, end
      }
    }

    /**
     * 해당 위치에, 충돌하는 서브엘리먼트가 있는지를 조사한다. (퍼센트 단위로 계산함)
     * 
     * 1개만 충돌하거나, 0개가 충돌한경우, 결과는 true로 리턴,
     * 2개 이상 충돌한경우 결과는 false로 리턴 (이 경우 새롭게 데이터를 삽입할 수 없음)
     * 
     * @param {number} percentPosition 퍼센트 위치
     * @param {number} percentLength 퍼센트 랭트
     */
    getOverlapCollision (percentPosition, percentLength) {
      let collisionCount = 0
      let result = true
      const inputStart = percentPosition
      const inputEnd = percentPosition + percentLength
      let blankStart = percentPosition
      let blankEnd = percentPosition + percentLength
      let blankLength = percentLength

      for (let i = 0; i < this.subElement.length; i++) {
        let current = this.subElement[i]
        const rectStart = current.percent.position
        const rectEnd = current.percent.position + current.percent.length

        if (inputStart < rectEnd && inputEnd > rectStart) {
          collisionCount++ // 충돌 개수 증가
          if (collisionCount === 1) {
            // 이 경우, 왼쪽에서 충돌된것인지, 오른쪽에서 충돌된것인지를 조사한다.
            if (blankStart < rectEnd) {
              blankStart = rectEnd
              blankEnd = inputEnd
              blankLength = blankEnd - blankStart
            } else if (blankEnd > rectStart) {
              blankStart = inputStart
              blankEnd = rectStart
              blankLength = blankEnd - blankStart
            }
          }
        }

        if (collisionCount >= 2) {
          blankEnd = 0
          blankStart = 0
          result = false
          break
        }
      }

      return {
        collisionCount,
        result,
        blankStart,
        blankEnd,
        blankLength
      }
    }

    /** 
     * 경고: 해당 함수는 서브엘리먼트를 강제 정렬합니다.
     * 
     * 순서에 의존하는 코드를 사용하기 전에 이 코드를 사용하지 마세요.
     * 
     * 멀티라인이 있을 때 내부 레이어 재배치용 함수 
     */
    _rearrange () {
      if (!this.isOverlap) {
        this._subElementSort() // 서브엘리먼트를 처음부터 정렬
        this._layerOverlapFalseProcess() // 레이어 오버랩 false시 상황 처리
        return // 그리고 함수 종료
      } else if (this.multiLine <= 1) {
        this._layerOverlapTrue() // 레이어 내부의 overlap값 변경
        return // 멀티라인 1이하인 경우, 변화는 없음
      }

      this._subElementSort() // 서브엘리먼트를 처음부터 정렬 (inputLine의 정확하고 편한 계산을 위하여)
      this._layerOverlapTrue() // 레이어 내부의 overlap값 변경

      /** @type {number[][]} */
      const inputLine = []
      for (let i = 0; i < this.multiLine; i++) {
        inputLine.push([])
      }

      // 0번과 1번 우선 배치
      if (this.subElement.length >= 1) {
        inputLine[0].push(0) // 0번라인에 0번 배치함
      }

      if (this.subElement.length >= 2) {
        let rectA = this.subElement[0].element.getBoundingClientRect()
        let rectB = this.subElement[1].element.getBoundingClientRect()
        if (rectA.left <= rectB.right && rectA.right >= rectB.left) {
          inputLine[1].push(1) // 0번이랑 충돌한경우 1번라인에 배치
        } else {
          inputLine[0].push(1) // 아닌경우 0번라인 배치
        }
      }
      
      // 2번부터 끝번까지 추가적인 배치
      for (let i = 2; i < this.subElement.length; i++) {
        const currentRect = this.subElement[i].element.getBoundingClientRect() // 현재 사각형
        let number = i
        let isTarget = false

        // inputLine내에 있는 모든 사각형이 충돌했을 때 다음 번호로 넘어간다.
        for (let j = 0; j < inputLine.length; j++) {
          const currentList = inputLine[j] // 현재 내부에 있는 엘리먼트 번호 리스트
          isTarget = true
          number = j

          // inputList에 있는 엘리먼트 번호의 모든 충돌여부를 조사하고,
          // 한개라도 충돌한다면, 다음 번호로 이동시킴
          // 모두 충돌하지 않았다면, 해당 번호를 입력하고 종료
          for (let k = 0; k < currentList.length; k++) {
            let rectA = currentRect
            let rectB = this.subElement[currentList[k]].element.getBoundingClientRect()
            if (rectA.left <= rectB.right && rectA.right >= rectB.left) {
              isTarget = false // 한개라도 충돌하면 다음 번호로
              break
            }
          }

          // 위에서 모두 충돌하지 않았다면, isTarget이 true이므로, 이 반복문을 빠져나감
          if (isTarget) break
        }

        inputLine[number].push(i)
      }

      let totalLine = 0 // 전체 라인 계산
      for (let i = 0; i < inputLine.length; i++) {
        // 내부에 데이터가있다면, 1줄씩 증가
        totalLine += inputLine[i].length === 0 ? 0 : 1
      }

      // 방금 입력된 inputLine을 이용하여, 실제로 보여지는 것을 배치함
      for (let i = 0; i < inputLine.length; i++) {
        const currentLine = inputLine[i]
        const currentLineLevel = i
        for (let j = 0; j < currentLine.length; j++) {
          const currentLineNumber = currentLine[j]
          let currentSub = this.subElement[currentLineNumber].element
          currentSub.style.top = (currentLineLevel / totalLine * 100).toFixed(2) + '%'
          currentSub.style.height = (1 / totalLine * 100).toFixed(2) + '%'
        }
      }

      // console.log(inputLine)
    }

    /** 서브엘리먼트를 재정렬합니다. (percentPosition이 앞에 있는 순서로) @private */
    _subElementSort () {
      this.subElement.sort((a, b) => {
        if (a.percent.position < b.percent.position) {
          return -1
        } else if (a.percent.position > b.percent.position) {
          return 1
        } else {
          return 0
        }
      })
    }

    /** 서브 엘리먼트 내부의 인덱스에 대한 충돌 개수를 확인하고 리턴함 (내부 계산용 함수) @private */
    _indexCollisionCheck (subElementIndex = 0) {
      if (subElementIndex < 0 && subElementIndex >= this.subElement.length) return 0

      const indexRect = this.subElement[subElementIndex].element.getBoundingClientRect()
      let count = 0
      for (let i = 0; i < this.subElement.length; i++) {
        if (subElementIndex === i) continue // 같은 번호끼리 충돌을 계산할 필요 없음

        const rect = this.subElement[i].element.getBoundingClientRect()
        if (indexRect.left <= rect.right && indexRect.right >= rect.left) {
          count++
        }
      }

      return count
    }

    /** 레이어의 isOverlap이 false일경우, 겹치지 않도록 설정하게 하는 함수를 동작시킴 @private */
    _layerOverlapFalseProcess () {
      if (this.isOverlap) return // 겹치기가 허용될경우 이 함수는 실행되지 않음.
      const MAX_POSITION = 100

      for (let i = 0; i < this.subElement.length; i++) {
        let sub = this.subElement[i]

        if (i === 0) { // 0번
          if (this.subElement.length >= 2) { // 내부 배열이 2개 이상 있을 때
            let next = this.subElement[i + 1]
            sub.overlap.maxPosition = next.percent.position
            sub.overlap.minPosition = 0
          } else { // 0번 1개만 존재하는 경우
            sub.overlap.maxPosition = MAX_POSITION
            sub.overlap.minPosition = 0
          }
        } else if (i >= 1 && i < this.subElement.length - 1) { // 1번 부터 마지막 마이너스 1번까지
          let prev = this.subElement[i - 1]
          let next = this.subElement[i + 1]
          sub.overlap.minPosition = prev.percent.position + prev.percent.length
          sub.overlap.maxPosition = next.percent.position
        } else if (i === this.subElement.length - 1) { // 마지막 번호
          let prev = this.subElement[i - 1]
          sub.overlap.minPosition = prev.percent.position + prev.percent.length
          sub.overlap.maxPosition = MAX_POSITION
        }
      }
    }

    /** 레이어의 overlap이 true인경우, overlap 값을 변경함 */
    _layerOverlapTrue () {
      if (!this.isOverlap) return
      const MAX_POSITION = 100

      for (let i = 0; i < this.subElement.length; i++) {
        let sub = this.subElement[i]
        sub.overlap.minPosition = 0
        sub.overlap.maxPosition = MAX_POSITION
      }
    }
  }

  static SubElement = class {
    /**
     * 해당 레이어의 일부 영역을 표시할 새 엘리먼트를 만듭니다.
     * @param {string} textContent 텍스트 콘텐츠 
     * @param {number} percentPosition 퍼센트 포지션 (레이어를 기준으로 한 현재 위치)
     * @param {number} percentLengthMax 퍼센트 랭트 최대 (레이어를 기준으로 한 퍼센트 최대길이)
     * @param {string | undefined} cssColor 
     * @param {string | undefined} cssBackgroundColor
     */
    constructor (textContent = '', percentPosition = 0, percentLengthMax = 1, cssColor = undefined, cssBackgroundColor = undefined) {
      /** 드래그 관련 정보 (주의: clientX, clientY를 제외하고는, content 내부의 영역을 기준으로 처리함) */
      this.drag = {
        /** 사용자가 드래그를 시작한 좌표 */ startX: 0, 
        /** 사용자가 드래그를 시작한 좌표 */ startY: 0, 
        /** 드래그에서 최소로 크기조절 할 수 있는 좌표 */ resizeMinX: 0, 
        /** 드래그에서 최대로 크기조절 할 수 있는 좌표 */ resizeMaxX: 0,
        /** 컨텐츠 내부의 상대적인 마우스 좌표 */ contentX: 0,
        /** 컨텐츠 내부의 상대적인 마우스 좌표 */ contentY: 0,
        /** 클라이언트 좌표 */ clientX: 0,
        /** 클라이언트 좌표 */ clientY: 0,
        /** 드래그 상태 */ state: '',
        /** 드래그 진행중인지 확인 */ isDrag: false,
        /** 드래그를 했을 때, 구역의 왼쪽 끝 좌표 */ areaLeft: 0,
        /** 드래그를 했을 때, 구역의 오른쪽 끝 좌표 */ areaRight: 0,
        /** 드래그를 했을 때, 구역의 start 값 */ prevPercentStart: 0,
        /** 드래그를 했을 때, 구역의 end 값 */ prevPercentEndback: 0,
      }

      /** 퍼센트 관련 정보 (모든 내부값은 퍼센트값임) */
      this.percent = {
        /** 콘텐츠 내부에서의 위치 */ position: percentPosition,
        /** 콘텐츠 내부에서의 최대길이 */ lengthMax: percentLengthMax,
        /** 콘텐츠 내부에서의 길이 */ length: percentLengthMax,
        /** 서브엘리먼트 내부에서 시작 영역 값 (단, lengthMax를 초과할 수 없음) */ start: 0,
        /** 서브엘리먼트 내부에서 끝 영역에서 빠진 값 (단, 0미만은 불가능하고, 총 길이가 0이되면 안됨) */ endback: 0,
      }

      /** 콘텐츠에 관련 정보 */
      this.content = {
        /** 레이어 내부 컨텐츠에 대한 시작 좌표값 (드래그 위치 조절시 사용) */ positionX: 0,
        /** 레이어 내부 컨텐츠에 대한 시작 좌표값 (드래그 위치 조절시 사용) */ positionY: 0,
        /** 레이어 내부 컨텐츠에 대한 기준 좌표 (이동 제한 용도) */ left: 0,
        /** 레이어 내부 컨텐츠에 대한 기준 좌표 (이동 제한 용도) */ top: 0,
        /** 레이어 내부 컨텐츠에 대한 최대 너비 (이동 제한 용도) */ width: 0,
        /** 레이어 내부 컨텐츠에 대한 최대 높이 (이동 제한 용도) */ height: 0,
      }

      /** 라인에 관한 정보 (여러줄 출력에 영향이 있음.) */
      this.line = {
        /** 콘텐츠 내의 현재 라인 번호 */ number: 0,
        /** 콘텐츠 내의 현재 라인에 대한 최대 번호 */ maxNumber: 0,
      }

      /** 오버랩에 관한 정보 (멀티라인이 0이하인경우, 오버랩이 금지됨 - 겹치기 금지) */
      this.overlap = {
        minPosition: 0,
        maxPosition: 100,
        minResizePosition: 0,
        maxResizePosition: 0,
      }

      /** 
       * 이 서브 엘리먼트에 대한 ID (서로를 구분하는 용도로 사용할 수 있음.)
       * 
       * ID는 중복되지 않도록 주의해야합니다.
       */
      this.id = ''

      // 엘리먼트 정보
      this.element = document.createElement('div')
      this.element.textContent =  textContent
      this.element.style.left = this.percent.position + '%'
      this.element.style.width = this.percent.length + '%'
      this.element.style.height = '100%'
      this.element.style.position = 'absolute'
      this.element.style.opacity = '0.8'
      this.element.style.overflow = 'hidden'
      this.element.style.textOverflow = 'ellipsis'
      if (cssColor) this.element.style.color = cssColor
      if (cssBackgroundColor) this.element.style.backgroundColor = cssBackgroundColor

      this.shadowElement = document.createElement('div')
      this.shadowElement.textContent = ''
      this.shadowElement.style.position = 'fixed'
      this.shadowElement.style.opacity = '0'
      // this.shadowElement.style.transition = '0.1s'
      this.shadowElement.style.backgroundColor = 'black'
      this.shadowElement.style.color = 'white'
      this.shadowElement.style.zIndex = '132'
      this.shadowElement.style.pointerEvents = 'none'
    }

    autoShadowElementPosition () {
      let rect = this.element.getBoundingClientRect()
      this.shadowElement.style.left = (rect.left + 2) + 'px'
      this.shadowElement.style.top = (rect.top + 2) + 'px'
    }

    autoShadowElementText () {
      this.shadowElement.textContent = `p:${this.percent.position.toFixed(1)}%, len:${this.percent.length.toFixed(1)}%/${this.percent.lengthMax.toFixed(1)}%, `
      this.shadowElement.textContent += `s:${this.percent.start.toFixed(1)}%, e:${this.percent.endback.toFixed(1)}%`
    }

    shadowElementShow () {
      this.shadowElement.style.opacity = '0.6'
    }

    shadowElementHide () {
      this.shadowElement.style.opacity = '0'
    }

    /**
     * 현재 퍼센트와 관련한 데이터를 수정합니다. (변수에 대한 설명은, 같은 이름의 맴버 변수 설명 참고)
     * @param {number} percentPosition 퍼센트 위치
     * @param {number} percentLength 퍼센트 길이
     * @param {number} percentStart 퍼센트 시작
     * @param {number} percentEndBack 퍼센트 엔드백 (엔드에서 값이 높아질수록 점점 멀어짐)
     */
    setPercentData (percentPosition, percentLength, percentStart, percentEndBack) {
      this.percent.position = percentPosition
      this.percent.lengthMax = percentLength
      this.percent.start = percentStart
      this.percent.endback = percentEndBack
    }

    /** 내부 엘리먼트, 이벤트 삭제 */
    removeElement () {
      this.removeEventListenerProcess()
      this.element.remove()
      this.shadowElement.remove()
    }

    /** css 커서값 - 잡기 */ static CURSOR_GRAB = 'grab'
    /** css 커서값 - 좌우늘리기 */ static CURSOR_EW_RESIZE = 'ew-resize'
    /** css 커서값 - 기본값 */ static CURSOR_DEFAULT = 'default'

    /** 상태: 드래그 중... */ static STATE_DRAG = 'state-drag'
    /** 상태: 일반 (아무것도 아님) */ static STATE_NORMAL = ''
    /** 상태: 왼쪽에서 사이즈 조절중 */ static STATE_RESIZE_LEFT = 'state-resize-left'
    /** 상태: 오른쪽에서 사이즈 조절중 */ static STATE_RESIZE_RIGHT = 'state-resize-right'

    /** 드래그 이벤트 등록용 함수 */
    autoEventListener () {
      this.element.draggable = true // 드래그 가능
      this.element.style.cursor = TimelineView.SubElement.CURSOR_GRAB

      // 추후, 터치 이벤트 구현 필요 (지금은 마우스 드래그로만 처리 가능함)
      this.element.addEventListener('mousemove', this.eventMouseMove.bind(this))
      this.element.addEventListener('dragstart', this.eventDragStart.bind(this))
      this.element.addEventListener('drag', this.eventDrag.bind(this))
      this.element.addEventListener('dragend', this.eventDragEnd.bind(this))
      this.element.addEventListener('touchstart', this.eventTouchStart.bind(this))
      this.element.addEventListener('touchmove', this.eventTouchMove.bind(this))
      this.element.addEventListener('touchend', this.eventTouchMove.bind(this))
    }

    /** 드래그 이벤트 삭제용 함수 */
    removeEventListenerProcess () {
      this.element.draggable = false
      this.element.style.cursor = TimelineView.SubElement.CURSOR_DEFAULT
      this.element.removeEventListener('mousemove', this.eventMouseMove.bind(this))
      this.element.removeEventListener('dragstart', this.eventDragStart.bind(this))
      this.element.removeEventListener('drag', this.eventDrag.bind(this))
      this.element.removeEventListener('dragend', this.eventDragEnd.bind(this))
      this.element.removeEventListener('touchstart', this.eventTouchStart.bind(this))
      this.element.removeEventListener('touchmove', this.eventTouchMove.bind(this))
      this.element.removeEventListener('touchend', this.eventTouchMove.bind(this))
    }

    /** 현재 커서를 변경함 */
    cursorChange () {
      const rect = this.element.getBoundingClientRect()
      const SIZE = rect.width < 36 ? 18 : 12 // 사각형의 길이가 12보다 작으면, 더 적은 범위로 적용함

      // 일정 영역 (양 끝을 기준으로) 이내일경우, 좌우화살표가 표시됨
      const rectInMouseX = this.drag.clientX - this.element.getBoundingClientRect().left
      if (rectInMouseX < SIZE || rectInMouseX > rect.width - SIZE) {
        this.element.style.cursor = TimelineView.SubElement.CURSOR_EW_RESIZE
      } else {
        this.element.style.cursor = TimelineView.SubElement.CURSOR_GRAB
      }
    }

    /** (드래그를 시작했을 때) 드래그 상태 변경 */
    dragStateChange () {
      const rect = this.element.getBoundingClientRect()
      const SIZE = rect.width < 36 ? 18 : 12 // 사각형의 길이가 12보다 작으면, 더 적은 범위로 적용함

      // 드래그 이동상태가 아니면 일반모드, 왼쪽 끝 또는 오른쪽 끝에서 드래그하면 크기조절, 그 외에는 드래그모드
      const rectInMouseX = this.drag.clientX - this.element.getBoundingClientRect().left
      if (this.drag.isDrag === false) {
        this.drag.state = TimelineView.SubElement.STATE_NORMAL
      } else if (rectInMouseX < SIZE) {
        this.drag.state = TimelineView.SubElement.STATE_RESIZE_LEFT
      } else if (rectInMouseX > rect.width - SIZE) {
        this.drag.state = TimelineView.SubElement.STATE_RESIZE_RIGHT
      } else {
        this.drag.state = TimelineView.SubElement.STATE_DRAG
      }
    }

    /**
     * 이벤트 함수 (이것은, 좌우 늘리는 기능을 넣기 위해 만들어짐)
     * @param {MouseEvent} e 
     */
    eventMouseMove (e) {
      // 이 함수의 목적은, 사용자가 마우스를 올렸을 때, 커서를 변경하는 기능이므로,
      // 드래그중인 상태에서는 적용하지 않음
      if (this.drag.isDrag) return 

      this.setDargClientXY(e.clientX, e.clientY) // 클라이언트 좌표 입력
      this.cursorChange() // 내부 표시 커서를 변경시킴 (마우스가 엘리먼트 내에 이동할 때)
      e.stopPropagation()
    }

    /**
     * 이벤트 함수
     * @param {DragEvent} e 
     */
    eventDragStart (e) {
      this.eventDragStartLogic(e.clientX, e.clientY)
      e.stopPropagation()
    }

    /**
     * 이벤트 드래그 기능 확장 함수
     * @param {number} clientX 
     * @param {number} clientY 
     */
    eventDragStartLogic (clientX, clientY) {
      this.drag.isDrag = true
      this.setDargClientXY(clientX, clientY) // 마우스 클라이언트 좌표 설정
      this.setAutoDragStartXY() // 마우스 좌표 기억

      this.dragStateChange()
      this.shadowElementShow() // 섀도우 엘리먼트 보여주기 (정보 표시 용도)
    }

    /** 드래그 시작 지점을 결정함 
     * 
     * (이 함수는 setDragClientXY를 사용한 이후에 사용해야합니다.)  */
    setAutoDragStartXY () {
      // 참고: 모든 좌표값 계산은 content.left 영역을 뺀 채로 계산됩니다. (그래야 0부터 좌표를 시작할 수 있음.)
      const rect = this.element.getBoundingClientRect()
      this.drag.startX = this.drag.clientX - rect.left - this.content.left
      this.drag.startY = this.drag.clientY - rect.top - this.content.left

      // 최대/최소 위치 설정
      // leftPerMax: 왼쪽으로 얼마나 더 많은 퍼센트를 갈 수 있는지에 대한 값
      // 크기 재조정 최소X = 엘리먼트왼쪽 - 스타트너비(contentWidth / 100 * startPercent) - (콘텐츠 왼쪽)
      const leftPerMax = this.percent.position - this.overlap.minPosition
      if (this.percent.start < leftPerMax) {
        this.drag.resizeMinX = rect.left - (this.content.width / 100 * this.percent.start) - this.content.left
      } else {
        this.drag.resizeMinX = rect.left - (this.content.width / 100 * leftPerMax) - this.content.left
      }

      // 최솟값이 0미만이 되지 않도록 변경
      if (this.drag.resizeMinX < 0) this.drag.resizeMinX = 0

      // rightPerMax: 오른쪽으로 얼마나 더 많은 퍼센트를 갈 수 있는지에 대한 값
      const rightPerMax = this.overlap.maxPosition - this.percent.position - this.percent.length
      if (this.percent.endback < rightPerMax) {
        this.drag.resizeMaxX = rect.left + rect.width + (this.content.width / 100 * this.percent.endback) - this.content.left
      } else {
        this.drag.resizeMaxX = rect.left + rect.width + (this.content.width / 100 * rightPerMax) - this.content.left
      }

      // 최댓값이 콘텐츠의 길이를 넘지 못하도록 변경
      if (this.drag.resizeMaxX > this.content.width) this.drag.resizeMaxX = this.content.width

      // 각 사각형 영역의 양쪽 끝의 값을 넣음
      this.drag.areaLeft = rect.left - this.content.left
      this.drag.areaRight = rect.right - this.content.left

      // 드래그 시작시점의 이전 설정값들을 저장함
      this.drag.prevPercentStart = this.percent.start
      this.drag.prevPercentEndback = this.percent.endback
    }

    /** 
     * 드래그 좌표의 clientX, clientY를 설정합니다. (Event를 통해 가져온 Client좌표를 입력해주세요.) 
     * 
     * 클라이언트 좌표에 따라 content(컨텐츠 내부의 상대 좌표)좌표도 같이 변경됨
     */
    setDargClientXY (clientX = 0, clientY = 0) {
      this.drag.clientX = clientX
      this.drag.clientY = clientY
      this.drag.contentX = clientX - this.content.left
      this.drag.contentY = clientY - this.content.top
    }

    /**
     * 이벤트 함수
     * @param {DragEvent} e 
     */
    eventDrag (e) {
      this.eventDragLogic(e.clientX, e.clientY)
      e.stopPropagation()
    }

    /**
     * 이벤트 드래그를 처리하기 위한 확장 함수
     * @param {number} clientX 
     * @param {number} clientY 
     */
    eventDragLogic (clientX, clientY) {
      if (!this.drag.isDrag || (clientX === 0 && clientY === 0)) return

      this.setDargClientXY(clientX, clientY) // 클라이언트 좌표 설정
      if (this.drag.state === TimelineView.SubElement.STATE_DRAG) {
        this._dragMove()
      } else if (this.drag.state === TimelineView.SubElement.STATE_RESIZE_LEFT) {
        this._dragResizeLeft()
      } else if (this.drag.state === TimelineView.SubElement.STATE_RESIZE_RIGHT) {
        this._dragResizeRight()
      }

      this.autoShadowElementPosition()
      this.autoShadowElementText()
    }

    /** percentPosition의 대한 결과값 처리 */
    _resultPercentPosition () {
      if (this.percent.position < 0) this.percent.position = 0
      else if (this.percent.position + this.percent.length > 100) this.percent.position = 100 - this.percent.length

      // 두번째 조건: max/min position에 맞게 처리 (이 값은 layer의 isOverlap 변수에 영향을 받음)
      if (this.percent.position < this.overlap.minPosition) {
        this.percent.position = this.overlap.minPosition
      }
      if (this.percent.position + this.percent.length > this.overlap.maxPosition) {
        this.percent.position = this.overlap.maxPosition - this.percent.length
      }
    }

    /** 드래그 이동 함수 */
    _dragMove () {
      // 퍼센트 포지션을 지정하고, 범위를 초과하는지 확인
      this.percent.position = (this.drag.contentX - this.drag.startX - this.content.left) / this.content.width * 100
      this._resultPercentPosition() 
 
      // 엘리먼트의 위치 조정
      this.element.style.left = this.percent.position.toFixed(2) + '%'
      this.element.style.width = this.percent.length.toFixed(2) + '%'
      // this.element.style.top = this.mouseY + 'px' // 아직 y축은 지원되지 않음
    }

    /** 드래그를 했을 때 왼쪽의 리사이즈 기능을 처리 */
    _dragResizeLeft () {
      // 크기조절 최소 이동 제한
      if (this.drag.contentX < this.drag.resizeMinX) {
        this.drag.contentX = this.drag.resizeMinX
      }

      // 이전px (왼쪽위치)와 diffpx (변경된 위치)의 차이값을 구하고
      // 이를 퍼센트로 변환하여 스타트 값 조정
      const prevPx = this.drag.areaLeft
      const diffPx = this.drag.contentX - prevPx
      const diffPercent = (diffPx / this.content.width * 100)
      this.percent.start = this.drag.prevPercentStart + diffPercent

      // 퍼센트의 최대, 최소 제한
      if (this.percent.start > this.percent.lengthMax - 1) {
        this.percent.start = this.percent.lengthMax - 1
      } else if (this.percent.start < 0.009) {
        // 소수점 오류를 보정하기 위해 일정 값 미만은 0으로 처리
        this.percent.start = 0
      }

      // 내부적으로 비어있는 비중을 다시 따져서, 이 값보다 이상이면 더이상 변화하지 않음
      const maxStart = this.percent.lengthMax - this.percent.endback
      if (this.percent.start > maxStart - 1) {
        this.percent.start = maxStart - 1
      } 
      
      // 퍼센트 랭트 재조정 및, 길이 조정
      this.percent.length = this.percent.lengthMax - this.percent.start - this.percent.endback
      this.element.style.width = this.percent.length.toFixed(2) + '%'

      // 왼쪽 위치는 다음과 같이 처리됨.
      // right부분을 통해 %로 변경한 후 재배치함 (start부분은 계산 중간에 변하므로 기준으로 정할 수 없음)
      const rightPercent = this.drag.areaRight / this.content.width * 100
      this.element.style.left = (rightPercent - this.percent.length).toFixed(2) + '%'

      // 퍼센트 포지션을 지정하고, 범위를 초과하는지 확인
      this.percent.position = rightPercent - this.percent.length
      this._resultPercentPosition()
      // console.log('remin:', this.drag.resizeMinX, 'remax: ', this.drag.resizeMaxX, 'start: ', this.percent.start, 'endback', this.percent.endback, 'diff: ' + diffPx)
    }

    /** 드래그를 했을 때, 오른쪽의 리사이즈 기능을 처리 */
    _dragResizeRight () {
      // dragResizeLeft랑 원리는 동일하지만, 이번엔 오른쪽 방향을 기준으로 처리합니다.
      // 크기조절 최대 이동 제한
      if (this.drag.contentX > this.drag.resizeMaxX) {
        this.drag.contentX = this.drag.resizeMaxX
      }

      // 이전px (오른쪽 px)와 diffpx (변경된 위치)를 서로 비교하여
      // 해당 값을 %로 변환하여 endback값 조정
      const prevPx = this.drag.areaRight
      const diffPx = this.drag.contentX - prevPx
      const diffPercent = (diffPx / this.content.width * 100)
      this.percent.endback = this.drag.prevPercentEndback - diffPercent

      // 퍼센트의 최대, 최소 제한
      if (this.percent.endback > this.percent.lengthMax - 1) {
        this.percent.endback = this.percent.lengthMax - 1
      } else if (this.percent.endback < 0.009) {
        // 소수점 오류를 보정하기 위해 일정 값 미만은 0으로 처리
        this.percent.endback = 0
      }

      // 내부적으로 비어있는 비중을 다시 따져서, 이 값보다 이상이면 더이상 변화하지 않음
      const maxEnd = this.percent.lengthMax - this.percent.start
      if (this.percent.endback > maxEnd - 1) {
        this.percent.endback = maxEnd - 1
      }

      // 퍼센트 랭트 재조정 및, 길이 조정
      this.percent.length = this.percent.lengthMax - this.percent.start - this.percent.endback
      this.element.style.width = this.percent.length.toFixed(2) + '%'

      // 오른쪽 위치는 다음과 같이 처리됨 (resizeLeft랑 원리 동일)
      const leftPercent = this.drag.areaLeft / this.content.width * 100
      this.element.style.left = leftPercent.toFixed(2) + '%'

      // 퍼센트 포지션을 지정하고, 범위를 초과하는지 확인
      this.percent.position = leftPercent
      this._resultPercentPosition() 
      // console.log('remin:', this.drag.resizeMinX, 'remax: ', this.drag.resizeMaxX, 'start: ', this.percent.start, 'endback', this.percent.endback, 'diff: ' + diffPx)
    }

    /** 
     * 이벤트 함수
     * @param {DragEvent} e
     */
    eventDragEnd (e) {
      this.eventDragEndLogic()
      // e.stopPropagation() 이 코드가 DragEnd에서 주석인 이유는, 상위 엘리먼트에게 해당 이벤트를 전달하여
      // 드래그 작업이 끝났다는 결과를 전송하기 위함
    }

    eventDragEndLogic () {
      this.drag.isDrag = false
      this.dragStateChange() // 드래그 상태 다시 원래대로 만듬
      this.shadowElementHide()
    }

    /**
     * 터치이벤트 함수
     * @param {TouchEvent} e 
     */
    eventTouchStart (e) {
      if (e.touches.length >= 1) {
        this.eventDragStartLogic(e.touches[0].clientX, e.touches[0].clientY)
      }
      e.stopPropagation()
    }

    /**
     * 터치이벤트 함수
     * @param {TouchEvent} e 
     */
    eventTouchMove (e) {
      if (e.touches.length >= 1) {
        this.eventDragLogic(e.touches[0].clientX, e.touches[0].clientY)
      }
      e.stopPropagation()
    }

    /**
     * 터치이벤트 함수
     * @param {TouchEvent} e 
     */
    eventTouchEnd (e) {
      this.eventDragEndLogic()
    }

    /**
     * 퍼센트들을 컨텐츠의 길이 비율로 환산한 값 ()
     * @param {number} contentValue 콘텐츠의 길이
     * @param {number} elementValue 엘리먼트의 lengthMax의 실제 길이
     * @returns {LayerReturnData}
     */
    getCalcuratePercentValue (contentValue = 100, elementValue = 100) {
      return {
        id: this.id,
        textContent: this.element.textContent ? this.element.textContent : '',
        percentLengthMax: contentValue * this.percent.lengthMax,
        percentLength: contentValue * this.percent.length,
        percentPosition: contentValue * this.percent.position,
        percentLengthStart: elementValue * (this.percent.start / this.percent.lengthMax),
        percentLengthEndback: elementValue * (this.percent.endback / this.percent.lengthMax)
      }
    }
  }

  /** 
   * TimelineView에서 사용하는 element 
   * 
   * 이것을 다른 엘리먼트에 appendChild함수로 추가해주세요.
   */
  getBaseElement () {
    return this.element
  }

  /**
   * 이 함수를 사용하기 전에, 서브엘리먼트를 정렬하지 말것 (안그러면 이벤트 등록 문제가 발생할 수 있음.)
   * 
   * 클릭 이벤트를 등록시킴, 그리고 클릭이 발생할경우, 마지막에 클릭한것이 무엇인지를 기록함
   * 
   * 클릭 이벤트는 드래그랑 별개이며, 이 이벤트는 취소할 수 없음.
   * @param {number} layerNumber 레이어 번호
   * @param {number} subElementNumber 서브엘리먼트 번호
   */
  _clickEventLastTarget (layerNumber, subElementNumber) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return
    
    const currentLayer = this.layer[layerNumber]
    if (subElementNumber < 0 || subElementNumber >= currentLayer.subElement.length) return

    const subElement = currentLayer.subElement[subElementNumber]
    subElement.element.addEventListener('click', (e) => {
      const targetSubElement = subElement // 다시 변수로 재등록 (그래야 갱신된 정보를 얻을 수 있음)
      this.lastClickTargetData.elementType = this.elementTypeList.SUBELEMENT // 엘리먼트 타입을 서브엘리먼트로 지정 (내부에서만 활용됨)
      this.lastClickTargetData.layerNumber = layerNumber
      this.lastClickTargetData.subElementNumber = subElementNumber
      this.lastClickTargetData.id = subElement.id
      this.lastClickTargetData.textContent = subElement.element.textContent != null ? subElement.element.textContent : ''
      this.lastClickTargetData.percentPosition = targetSubElement.percent.position
      this.lastClickTargetData.percentLength = targetSubElement.percent.length
      this.lastClickTargetData.percentLengthStart = targetSubElement.percent.start
      this.lastClickTargetData.percentLengthMax = targetSubElement.percent.lengthMax
      this.lastClickTargetData.percentLengthEndback = targetSubElement.percent.endback
    })
  }

  /**
   * 새로운 레이어를 생성합니다. (TimelineView에서 사용)
   * 
   * 레이어 번호는 생성한 순서대로 0번부터 시작합니다.
   * @param {number} [multiline=1] 멀티라인 개수 (서브 엘리먼트가 겹쳐졌을 때 최대 여러줄로 표시하는 개수), 이 값을 0이하나 음수로 처리하면, overlap을 금지시킴
   * @param {string} cssWidth
   * @param {string} cssHeight 
   * @param {string | undefined} cssBackgroundColor 
   */
  createLayer (multiline = 1, cssWidth = '100%', cssHeight = '50%', cssBackgroundColor = undefined) {
    let layer = new TimelineView.Layer(cssWidth, cssHeight, cssBackgroundColor)

    // 멀티라인은, 1이상의 값만 적용 가능하고, 만약 0이하라면, 멀티라인은 1로 고정하고 오버랩을 금지시킵니다.
    layer.multiLine = multiline >= 1 ? multiline : 1
    layer.isOverlap = multiline >= 1 ? true : false

    this.element.appendChild(layer.layerElement)
    this.layer.push(layer)
    const lastLayerNumber = this.layer.length - 1

    // 이벤트 추가 등록 @deprecated
    // layer.layerElement.addEventListener('click', (e) => {
    //   if (this.lastClickTargetData.elementType !== this.elementTypeList.SUBELEMENT) {
    //     // 레이어를 클릭한 것으로 간주함
    //     this.lastClickTargetData.elementType = this.elementTypeList.LAYER
    //     this.lastClickTargetData.layerNumber = lastLayerNumber
    //     this.lastClickTargetData.subElementNumber = -1
    //     this.lastClickTargetData.percentPosition = 0
    //     this.lastClickTargetData.percentLength = 0
    //     this.lastClickTargetData.percentLengthMax = 0
    //   }
    // })
  }

  /**
   * 해당 레이어의 타이틀을 설정합니다.
   * @param {number} layerNumber 레이어 번호 (배열 번호)
   * @param {string} titleText 
   * @param {string} cssWidth 
   * @param {string} cssHeight 
   * @param {string | undefined} cssColor 
   * @param {string | undefined} cssBackgroundColor 
   */
  setLayerTitle (layerNumber, titleText = '', cssWidth = '20%', cssHeight = '50%', cssColor = undefined, cssBackgroundColor = undefined) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return
    this.layer[layerNumber].setTitle(titleText, cssWidth, cssHeight, cssColor, cssBackgroundColor)
  }

  /**
   * 특정 레이어 내부의 일부 영역을 표시할 새 엘리먼트를 만듭니다. 배치는 제목과 별도의 영역으로 처리함.
   * 
   * 이 함수는 세부적인 값 설정이 불가능하므로, 대신 layerAddSubElementObj를 사용해주세요.
   * 
   * 이 함수는 제거될 예정
   * 
   * @param {number} layerNumber 레이어 번호
   * @param {string} textContent 텍스트 콘텐츠 
   * @param {number} positionPercent 포지션이 위치할 퍼센트 값 구간
   * @param {number} positionLength 포지션에 대한 엘리먼트 길이
   * @param {string | undefined} cssColor 
   * @param {string | undefined} cssBackgroundColor
   * @deprecated
   */
  layerAddSubElement (layerNumber, textContent = '', positionPercent, positionLength, cssColor = undefined, cssBackgroundColor = undefined) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return

    const currentLayer = this.layer[layerNumber]
    currentLayer._addSubElement(textContent, positionPercent, positionLength, cssColor, cssBackgroundColor)

    const lastNumber = currentLayer.subElement.length - 1
    const lastSubElement = currentLayer.subElement[lastNumber]
    if (currentLayer.dragPossible) {
      lastSubElement.autoEventListener()
    }

    this._clickEventLastTarget(layerNumber, lastNumber)
    this._layerResizeProcess(layerNumber)
    currentLayer._rearrange() // 레이어 내부 요소 재배치 (이 함수는 강제 정렬을 시도하므로 마지막에 배치해야함)

    // 마지막으로 생성한 레이어와 서브 엘리먼트의 번호 입력
    this._lastCreateNumber.layer = layerNumber
    this._lastCreateNumber.subElement = lastNumber
  }

  /**
   * @typedef AddSubElementContentData
   * @property {string | undefined} id 아이디
   * @property {string | undefined} textContent 텍스트의 내용
   * @property {string | undefined} cssColor
   * @property {string | undefined} cssBackgroundColor
   */

  /**
   * 콘텐츠 영역 내의 서브 엘리먼트의 퍼센트 데이터, 구조는 다음과 같습니다.
   * 
   * 0 or lengthStart ~ lengthMax or (lengthMax - lendthEndback)  
   * 그러므로, 시작부터 시작해서 끝이전 지점까지입니다.
   * 
   * @typedef AddSubElementPercentData
   * @property {number | undefined} position 콘텐츠 내의 위치를 %로 환산한 값
   * @property {number | undefined} length 콘텐츠 내의 엘리먼트 길이를 %로 환산한 값
   * @property {number | undefined} lengthMax 이 엘리먼트가 가질 수 있는 최대 길이%
   * @property {number | undefined} lengthStart 이 엘리먼트에서, 콘텐츠 길이 기준으로 잘린 %값
   * @property {number | undefined} lengthEndback 이 엘리먼트에서 콘텐츠 길이 기준으로 끝에서 잘린 %값
   */

  /**
   * 특정 레이어 컨텐츠 영역내에 표시할 새 엘리먼트를 만듭니다.
   * @param {number} layerNumber 레이어 번호
   * @param {AddSubElementContentData} contentData 콘텐츠 데이터
   * @param {AddSubElementPercentData} percentData 포지션 데이터
   */
  layerAddSubElementObj (layerNumber, contentData, percentData) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return
    const currentLayer = this.layer[layerNumber]

    // 퍼센트 데이터 값 초기화 (값이 지정되지 않으면 임의의 기본값을 처리)
    if (percentData.position === undefined) percentData.position = 0
    if (percentData.length === undefined) percentData.length = 10
    if (percentData.lengthMax === undefined) percentData.lengthMax = 10
    if (percentData.lengthStart === undefined) percentData.lengthStart = 0
    if (percentData.lengthEndback === undefined) percentData.lengthEndback = 0

    // 임의 값 보정 계산
    if (percentData.position >= 100) percentData.position = 99
    if (percentData.lengthMax > 100) percentData.lengthMax = 100
    if (percentData.position + percentData.length > 100) percentData.length = 100 - percentData.position

    // 포지션 시작값이 최대값을 초과한경우, 포지션 시작값을 변경하고 포지션 끝뒤로값을 변경함
    // 포지션 끝값도 마찬가지로 처리함
    if (percentData.lengthStart > percentData.lengthMax) {
      percentData.lengthStart = percentData.lengthMax - 1
      percentData.lengthEndback = 0
    } else if (percentData.lengthEndback > percentData.lengthMax) {
      percentData.lengthEndback = percentData.lengthMax - 1
      percentData.lengthStart = 0
    }

    // 만약, 입력된 퍼센트 + endback + start의 범위가 잘못된 경우, 이것을 수정함
    const totalLen = percentData.length + percentData.lengthStart + percentData.lengthEndback
    if (totalLen < percentData.lengthMax) { // 총합 길이가 최대 길이보다 작게 처리되면
      // endback 부분을 그만큼 늘림
      percentData.lengthEndback = percentData.lengthMax - percentData.lengthStart  - percentData.length 
    } else if (totalLen > percentData.lengthMax) { // 총합 길이가 최대보다 크게 처리되면
      // 1차: endback을 제거
      let downEndback = totalLen - percentData.lengthMax

      // 단, endback을 줄여야 하는 값이 기존에 넣었던 값을 초과할 수 없음
      if (downEndback > percentData.lengthEndback) downEndback = percentData.lengthEndback

      // 2차: start를 제거 (다만, downStart값이 필요없으면 이건 무시됨)
      let downStart = totalLen - percentData.lengthMax - downEndback
      if (downStart > percentData.lengthStart) downStart = percentData.lengthStart
      if (downStart < 0) downStart = 0

      // 3차: endback과 start를 다시 계산
      percentData.lengthEndback -= downEndback
      percentData.lengthStart -= downStart
    }

    // 먼저, 해당 레이어의 isOverlap을 판단하고, 이값이 false일경우, overlap이 되지 않는 수치를 얻어오고 재배치함.
    if (!currentLayer.isOverlap) {
      // let blank = currentLayer.getOverlapFalseBlankStart()
      let blank = currentLayer.getOverlapCollision(percentData.position, percentData.length)
      if (!blank.result) {
        console.warn('데이터를 추가할 수 없습니다. 서로의 공간이 너무 겹칩니다.')
        return
      }

      if (blank.collisionCount === 0) {
        // 그대로 진행
      } else if (blank.collisionCount === 1) {
        percentData.position = blank.blankStart
        percentData.length = blank.blankLength
      }

      // 연산결과에 따른 새로운 데이터를 넣는지 판단하고, 아닐경우 경고 표시
      if (percentData.position >= 100) {
        console.warn('데이터를 추가할 수 없습니다. 비어있는 공간이 필요합니다.')
        return // 처리 무효
      }
    }

    currentLayer._addSubElement(contentData.textContent, percentData.position, percentData.lengthMax, contentData.cssColor, contentData.cssBackgroundColor)
    
    // 마지막 엘리먼트 추가 설정 (최근에 넣은 엘리먼트가 마지막이므로)
    const lastNumber = currentLayer.subElement.length - 1
    const lastSubElement = currentLayer.subElement[lastNumber]
    lastSubElement.id = contentData.id != null ? contentData.id : '?' // 아이디 추가

    if (currentLayer.dragPossible) {
      lastSubElement.autoEventListener()
    }

    // 퍼센트 수정
    lastSubElement.percent.position = percentData.position
    lastSubElement.percent.length = percentData.length
    lastSubElement.percent.start = percentData.lengthStart
    lastSubElement.percent.endback = percentData.lengthEndback
    lastSubElement.percent.lengthMax = percentData.lengthMax

    // 퍼센트 수정에 따른 엘리먼트 크기 조정
    if (percentData.position) lastSubElement.element.style.left = percentData.position.toFixed(2) + '%'
    if (percentData.length) lastSubElement.element.style.width = percentData.length.toFixed(2) + '%'

    this._clickEventLastTarget(layerNumber, lastNumber)
    this._layerResizeProcess(layerNumber)
    currentLayer._rearrange() // 레이어 내부 요소 재배치 (이 함수는 강제 정렬을 시도하므로 마지막에 배치해야함)

    // 마지막으로 생성한 레이어와 서브 엘리먼트의 번호 입력
    this._lastCreateNumber.layer = layerNumber
    this._lastCreateNumber.subElement = lastNumber
  }

  /**
   * 레이어 안에 있는 모든 서브 엘리먼트를 삭제합니다.
   * @param {number} layerNumber 레이어 번호
   */
  layerSubElementDeleteAll (layerNumber = 0) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return
    this.layer[layerNumber].removeAllSubElement()
  }

  /**
   * 레이어 안에 있는 한개의 엘리먼트를 삭제합니다. 
   * 
   * (주의: splice를 통해 배열 내부를 삭제하기 때문에, 연속으로 이 함수를 사용한다면 subElementIndex가 잘못 지정될 수도 있음.)
   * @param {number} layerNumber 레이어 번호
   * @param {number} subElementIndex 서브엘리먼트 인덱스 번호
   */
  layerSubElementDelete (layerNumber = 0, subElementIndex = 0) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return
    let currentLayer = this.layer[layerNumber]

    if (subElementIndex < 0 || subElementIndex >= currentLayer.subElement.length) return
    
    currentLayer.subElement[subElementIndex].removeElement()
    currentLayer.subElement.splice(subElementIndex, 1)
  }

  /** 모든 레이어의 서브 엘리먼트를 제거합니다. */
  allLayerSubElementDelete () {
    for (let l of this.layer) {
      l.removeAllSubElement()
    }
  }

  /**
   * 해당 레이어의 서브 엘리먼트가 드래그가 가능한지에 대한 여부 설정
   * @param {number} layerNumber 레이어 번호
   * @param {boolean} dragPossible 드래그 가능 여부
   */
  setLayerSubElementDragPossible (layerNumber = 0, dragPossible = true) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return
    this.layer[layerNumber].dragPossible = dragPossible

    for (let i = 0; i < this.layer[layerNumber].subElement.length; i++) {
      let current = this.layer[layerNumber].subElement[i]
      if (this.layer[layerNumber].dragPossible) {
        current.autoEventListener()
      } else {
        current.removeEventListenerProcess()
      }
    }
  }

  /**
   * 해당 레이어의 서브 엘리먼트들이 서로 겹쳤을 때 여러줄로 출력하는 멀티라인을 설정합니다.
   * @param {number} layerNumber 레이어 번호
   * @param {number} multiline 멀티라인 최대 개수, 0이하로 설정한경우 overlap이 금지됨
   * 
   * (많을수록 더 많은 라인으로 출력됨, 이 숫자가 아무리 높아도 여러개가 겹치지 않으면 멀티라인으로 출력되지 않습니다.)
   */
  setLayerMultiline (layerNumber = 0, multiline = 1) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return

    const layer = this.layer[layerNumber]
    layer.multiLine = multiline >= 1 ? multiline : 1
    layer.isOverlap = multiline >= 1 ? true : false

    this.layer[layerNumber].multiLine = multiline
    this.layer[layerNumber]._rearrange()
  }

  /**
   * 레이어 내부의 퍼센트 값들의 데이터  
   * 모든 값의 기준점은 내부 콘텐츠의 총 길이를 기준으로 합니다. 사각형의 총 길이를 콘텐츠를 기준으로 환산한 값을 리턴합니다.
   * 
   * 사각형의 길이는 최대 percentLengthMax까지이고,  
   * 사각형의 범위는 lengthStart ~ lengthEnd입니다.
   * 
   * 사실, 이 복잡한 방식을 어떻게 설명해야 할지 모르겠음.
   * 
   * Class LayerReturnData 참고
   * @typedef LayerReturnData
   * @property {string} id 서브 엘리먼트가 가지고 있는 id (주의: 이 값은 서로 같을 수도 있음. 선택적인 옵션임)
   * @property {string} textContent 서브 엘리먼트에 작성되어있는 텍스트 콘텐츠
   * @property {number} percentPosition 퍼센트 포지션: 사각형이 표시되어있는 위치 (X축 값)
   * @property {number} percentLength 퍼센트 길이: 사각형의 길이
   * @property {number} percentLengthMax 퍼센트 최대 길이: 해당 콘텐츠 내에 표시된 사각형이 가질 수 있는 최대 길이. 최대 100을 초과할 수는 없습니다.
   * @property {number} percentLengthStart 퍼센트 길이 시작: 사각형의 start지점에 얼마나 떨어져있는지에 대한 값
   * @property {number} percentLengthEndback 퍼센트 길이 끝뒤로: 사각형의 end지점에 얼마나 떨어져있는지에 대한 값,  
   * 주의: start에서 떨어진 값이 아닙니다. 사각형의 오른쪽에서 줄어든 부분이라고 생각하세요.
   */

  /** 레이어 내부의 퍼센트 값들의 데이터 */
  static LayerReturnData = class {
    constructor (id = '', textContent = '', percentPosition = 0, percentLength = 0, percentLengthMax = 0, percentLengthStart = 0, percentLengthEndback = 0) {
      this.id = id
      this.textContent = textContent
      this.percentPosition = percentPosition
      this.percentLength = percentLength
      this.percentLengthMax = percentLengthMax
      this.percentLengthStart = percentLengthStart
      this.percentLengthEndback = percentLengthEndback
    }
  }

  /**
   * 해당 레이어의 데이터값들을 리턴합니다.
   * @param {number} layerNumber 
   * 
   * @returns {LayerReturnData[] | undefined} 포지션에 대한 정보
   */
  getLayerReturnData (layerNumber = 0) {
    if (layerNumber < 0 || layerNumber >= this.layer.length) return undefined

    let data = []
    let currentLayer = this.layer[layerNumber]
    if (currentLayer.subElement.length === 0) return undefined // 서브엘리먼트가 없으면 undefined가 리턴됨

    for (let i = 0; i < currentLayer.subElement.length; i++) {
      let target = currentLayer.subElement[i]
      let pushData = new TimelineView.LayerReturnData(
        target.id,
        target.element.textContent != null ? target.element.textContent : '',
        target.percent.position,
        target.percent.length,
        target.percent.lengthMax,
        target.percent.start,
        target.percent.endback,
      )

      data.push(pushData)
    }

    return data
  }

  /**
   * timelineView 내에 있는 서브 엘리먼트를 업데이트합니다.
   * 
   * 주의: 이 함수는 기능을 사용했을 때 의도대로 동작하지 않습니다.
   * 
   * @deprecated
   * @param {number} layerNumber 
   * @param {number} subElementIndex 
   * @param {number} percentPosition 
   * @param {number} percentLength 
   */
  layerSubElementUpdate (layerNumber, subElementIndex, percentPosition, percentLength) {
    // 잘못된 레이어 번호를 입력하면 처리하지 않음
    if (layerNumber < 0 || layerNumber >= this.layer.length) return
    const currentLayer = this.layer[layerNumber]
    
    // 잘못된 서브엘리먼트 인덱스를 입력하면 처리하지 않음
    if (subElementIndex < 0 || subElementIndex >= currentLayer.subElement.length) return

    const target = currentLayer.subElement[subElementIndex]
    target.percent.position = percentPosition
    target.percent.length = percentLength
    target.element.style.position = percentPosition + '%'
    target.element.style.width = percentLength + '%'

    this._layerResizeProcess(layerNumber)
    currentLayer._rearrange() // 요소 재배치
  }

  /**
   * 퍼센트들을 실제 길이로 환산한 값들
   * @typedef PercentToValueReturnData
   * @property {number} valuePosition 포지션의 값 (콘텐츠의 총 길이 기준) 
   * @property {number} valueLength 길이의 값 (콘텐츠의 총 길이 기준)
   * @property {number} valueLengthMax 길이의 최대 값 (콘텐츠의 총 길이 기준)
   * @property {number} valueElementStart 길이 시작의 값 (콘텐츠의 총 길이 기준)
   * @property {number} valueElementEndback 길이 끝의 값 (콘텐츠의 총 길이 기준)
   */

  /**
   * 콘텐츠의 길이와, 엘리먼트 길이를 기준으로, percent를 정의했을 때 예상되는 값을 가져옵니다.
   * 
   * 참고: percentStart, percentEndback은 elementValue를 기준으로 합니다. 나머지는 contentValue를 기준으로 합니다.
   * 
   * 예시: contentValue가 200이고, elementValue가 50일 때, percentLengthMax는 25로 지정됨  
   * 여기서, start가 10이고, endback이 10이면, percentLength는 50 - 10 - 10 = 30임
   * 
   * @param {number} contentValue 콘텐츠의 값
   * @param {number} percentPosition 퍼센트 포지션의 값
   * @param {number} percentLength 퍼센트의 길이 값
   * @param {number} percentLengthMax 퍼센트의 최대 길이 값
   * @param {number} percentStart 퍼센트 시작 값
   * @param {number} percentEndback 퍼센트 끝에서 떨어져있는 값
   * @returns {PercentToValueReturnData}
   */
  getCalcuratePercentToValue (contentValue, percentPosition, percentLength, percentLengthMax, percentStart, percentEndback) {
    return {
      valuePosition: percentPosition / 100 * contentValue,
      valueLength: percentLength / 100 * contentValue,
      valueLengthMax: percentLengthMax / 100 * contentValue,
      valueElementStart: (percentStart / percentLengthMax) * contentValue,
      valueElementEndback: (percentEndback / percentLengthMax) * contentValue,
    }
  }

  /**
   * @typedef ValueToPercentReturnData
   * @property {number} percentPosition 퍼센트 포지션: 사각형이 표시되어있는 위치 (X축 값)
   * @property {number} percentLength 퍼센트 길이: 사각형의 길이
   * @property {number} percentLengthMax 퍼센트 최대 길이: 해당 콘텐츠 내에 표시된 사각형이 가질 수 있는 최대 길이. 최대 100을 초과할 수는 없습니다.
   * @property {number} percentLengthStart 퍼센트 길이 시작: 사각형의 start지점에 얼마나 떨어져있는지에 대한 값
   * @property {number} percentLengthEndback 퍼센트 길이 끝뒤로: 사각형의 end지점에 얼마나 떨어져있는지에 대한 값,  
   */

  /**
   * 콘텐츠의 길이와 엘리먼트를 기준으로, value를 정의했을 때 예상되는 percent값을 가져옵니다.
   * 
   * 참고: 이것은 계산용 함수이기 때문에, textContent의 값은 공백입니다.
   * @param {number} contentValue 콘텐츠의 값
   * @param {number} valuePosition 포지션의 값
   * @param {number} valueLengthMax 최대 길이 값
   * @param {number} valueElementStart 시작 값
   * @param {number} valueElementEndback 끝에서 떨어져있는 값
   * @returns {ValueToPercentReturnData}
   */
  getCalcurateValueToPercent (contentValue, valuePosition, valueLengthMax, valueElementStart, valueElementEndback) {
    return {
      percentPosition: valuePosition / contentValue * 100,
      percentLengthMax: valueLengthMax / contentValue * 100,
      percentLengthStart: valueElementStart / contentValue * 100,
      percentLengthEndback: valueElementEndback / contentValue * 100,
      percentLength: (valueLengthMax - valueElementStart - valueElementEndback) / contentValue * 100
    }
  }

  /** 
   * 가장 마지막에 만들어진 서브엘리먼트를 가져옵니다. 
   * 
   * 주의: layerAddSubElement를 한 직후에 이 함수를 사용하지 않는다면,
   * 가장 마지막에 만들어진 서브엘리먼트라고 확신할 수 없음
   */
  getLastSubElement () {
    let layernum = this._lastCreateNumber.layer
    if (layernum >= this.layer.length) return null

    const currentLayer = this.layer[layernum]
    let subnum = this._lastCreateNumber.subElement
    if (subnum >= currentLayer.subElement.length) return null
    
    let data = currentLayer.subElement[subnum]
    if (data) return data
    else return null
  }
}

// test code
function debugFunction () {
  let timelineView = new TimelineView('98vw', '20vh')
  //@ts-ignore
  window.timelineView = timelineView
  document.body.appendChild(timelineView.element)
  timelineView.createLayer(1, '98vw', '4vh', 'skyblue')
  timelineView.setLayerTitle(0, 'Time', '10%', '4vh', 'black', 'pink')
  timelineView.layerAddSubElement(0, '0:00', 0, 10, 'blue', 'white')
  timelineView.layerAddSubElement(0, '1:00', 20, 10, 'blue', 'white')
  timelineView.layerAddSubElement(0, '2:00', 40, 10, 'blue', 'white')
  timelineView.layerAddSubElement(0, '3:00', 60, 10, 'blue', 'white')
  timelineView.layerAddSubElement(0, '4:00', 80, 10, 'blue', 'white')
  timelineView.createLayer(5, '98vw', '12vh', 'slateblue')
  timelineView.setLayerTitle(1, 'Mastring', '10%', '12vh', 'black', 'HotPink')
  timelineView.layerAddSubElement(1, 'superment', 0, 100, 'yellow', 'orange')
  timelineView.layerAddSubElement(1, 'ice cake', 0, 100, 'yellow', 'blue')
  timelineView.layerAddSubElement(1, '3d sound', 0, 100, 'yellow', 'green')
  timelineView.layerAddSubElement(1, 'bass boost', 0, 100, 'yellow', 'lime')
  timelineView.layerAddSubElement(1, 'sky stereo', 0, 100, 'yellow', 'Khaki')
  timelineView.createLayer(-1, '98vw', '4vh', 'cornflowerblue')
  timelineView.setLayerTitle(2, 'Music', '10%', '4vh', 'black', 'pink')
  timelineView.layerAddSubElement(2, 'funny level', 0, 58, 'pink', 'brown')
  timelineView.layerAddSubElementObj(2, {
    id: '7',
    textContent: '???',
    cssColor: 'red',
    cssBackgroundColor: 'lime'
  }, {
    position: 40,
    length: 32,
    lengthMax: 40,
    lengthStart: 11,
    lengthEndback: 4
  })

  let crazyButton = document.createElement('button')
  crazyButton.style.position = 'absolute'
  crazyButton.style.top = '40%'
  crazyButton.textContent = '눌러'
  document.body.appendChild(crazyButton)
  crazyButton.addEventListener('click', (e) => {
    timelineView.layerAddSubElementObj(2, {
      id: '7',
      textContent: '???',
      cssColor: 'ornage',
      cssBackgroundColor: 'yellowgreen'
    }, {
      position: 20,
      length: 32,
      lengthMax: 40,
      lengthStart: 11,
      lengthEndback: 4
    })
  })
}
// debugFunction()