import React from 'react';
import ReactDOM from 'react-dom';
import AWS from 'aws-sdk';
import { configure } from 'mobx';
import shutterStockAPI from 'shutterstock-api';

import './setup';
import { Routes } from './Routes';
import './rc-slider.css';
import './firebase-app';
import './index.css';
import './contextmenu.css';
import 'react-calendar-timeline/lib/Timeline.css';
import { isEmpty } from 'lodash-es';

if (window.location.href.includes('localhost') === false) {
  console.log = () => {};
}

if (
  !isEmpty(window.location.search) &&
  !window.location.pathname.includes('/render')
) {
  window.location.search = '';
}

shutterStockAPI.setAccessToken(process.env.REACT_APP_SHUTTERSTOCK_API_KEY);

AWS.config.update({
  region: 'ap-northeast-2',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

configure({
  enforceActions: 'never',
});

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root'),
);
