import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import { useStores } from 'store';
import { useSearchParamStore } from 'store/router';
import { EffectMenu } from '../EffectMenu';
import { EqMenu } from '../EqMenu';
import { ShutterStockMenu } from '../ShutterStockMenu';

interface SidebarMobileProps {}
export const SidebarMobile = observer(({}: SidebarMobileProps) => {
  const { videoStore, uiMobileEffectMenu, uiHistory, uiStore } = useStores();
  const [menu, setMenu] = useState<string | null>();
  const expand = uiStore.showSidebar;

  const toggleMenu = (name: string) => {
    if (name === 'history') {
      if (uiHistory.loading) {
        uiHistory.hideLoading();
      } else {
        uiHistory.showLoading();
      }
    } else {
      if (menu === name) {
        setMenu(null);
        uiStore.showSidebar = false;
      } else {
        setMenu(name);
        uiStore.showSidebar = true;
      }
    }
  };

  // history menu click
  const historyOpenOnClickHandle = () => {
    toggleMenu('history');
  };

  const videoEffectMenuOpenOnClickHandle = () => {
    if (uiMobileEffectMenu.loading == false) {
      uiMobileEffectMenu.showLoading();
    } else {
      uiMobileEffectMenu.hideLoading();
    }
  };

  const onClickChatGPT = () => {
    if (videoStore.isReady === false) {
      return;
    }

    uiStore.showChatPrompt = true;
  };

  useEffect(() => {
    if (videoStore.isReady) {
      setTimeout(() => {
        // setMenu("effect");
        uiMobileEffectMenu.showLoading();
      }, 1000);
    }
  }, [videoStore.isReady]);

  return (
    <Container expand={expand}>
      {expand && (
        <ExpandMenuContainer>
          {menu === 'effect' && <EffectMenu />}
          {menu === 'node' && <EqMenu />}
          {menu === 'shutterstock' && <ShutterStockMenu />}

          <FoldButton onClick={() => setMenu(null)} />
        </ExpandMenuContainer>
      )}

      <MenuContainer>
        <Icon
          src={require('asset/sidebar/effect.svg').default}
          onClick={() => toggleMenu('effect')}
        />

        <Icon
          src={require('asset/sidebar/clips.svg').default}
          onClick={() => toggleMenu('shutterstock')}
        />

        <Icon
          src={require('asset/sidebar/eq.svg').default}
          onClick={() => toggleMenu('effect')}
        />

        <IconContainer>
          <Icon
            src={require('asset/sidebar/history.svg').default}
            onClick={() => historyOpenOnClickHandle()}
            style={{ height: '36px' }}
          />
        </IconContainer>

        <IconContainer>
          <Icon
            src={require('asset/sidebar/robot.svg').default}
            onClick={() => onClickChatGPT()}
            style={{ height: '30px' }}
          />
        </IconContainer>

        {videoStore.isReady ? (
          <IconContainer>
            <Icon
              src={require('asset/sidebar/align-text-justify.svg').default}
              onClick={() => videoEffectMenuOpenOnClickHandle()}
              style={{ height: '36px' }}
            />
          </IconContainer>
        ) : (
          ''
        )}
      </MenuContainer>
    </Container>
  );
});

const Container = styled.div<any>`
  position: fixed;
  left: 0px;
  bottom: 0px;

  width: 100%;

  background: black;

  transition: all 0.13s ease;

  ${({ expand }) =>
    expand
      ? css`
          height: 64px;
        `
      : css`
          height: 64px;
        `}
`;
const MenuContainer = styled.div`
  display: flex;
  height: 100%;

  gap: 12px;

  align-items: center;
  justify-content: center;
  z-index: 9;
`;

const ExpandMenuContainer = styled.div`
  position: fixed;
  left: 0px;
  bottom: 64px;

  width: 100vw;
  height: 70vh;

  overflow: hidden;

  z-index: 7;
`;

const FoldButton = styled.div`
  position: absolute;

  right: -16px;
  top: calc(50vh - 30px - 32px);

  width: 16px;
  height: 60px;

  background: black;

  border-radius: 0px 10px 10px 0px;

  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;

  display: flex;

  align-items: center;
  justify-content: center;
`;
const Icon = styled.img`
  size: 48px;
  height: 48px;
  display: block;

  cursor: pointer;

  user-select: none;
  transition: all 0.25s ease;

  :hover {
    transform: scale(1.18);
  }
`;
