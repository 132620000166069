import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { IEffect, IEqEffect, ISubEffect } from 'model';
import { useStores } from 'store';
import styled from 'styled-components';

import BackIcon from '@/asset/icon/back.png';
import HeartIcon from '@/asset/icon/heart.png';
import HeartOnIcon from '@/asset/icon/heart_on.png';
import RefreshIcon from '@/asset/icon/refresh.png';

import { NonSelectable } from 'util/css';
import { isMobile } from 'util/mobile';
import { isTestMode } from 'util/testmode';
import { Search } from 'component/input';
import { useEffects } from 'store/hook';
import { useSearchParamStore } from 'store/router';
import { videoStore } from 'store/video';

/**
 * 오디오 파형 그래프를 그립니다.
 *
 * 아날라이저 노드 변수는 videoStore.analyser 또는
 * videoStore.mixer 내부에 있는 analyser 이름을 가진 변수를 살펴보세요.
 *
 * 이 함수는 더이상 사용되지 않습니다.
 * 이유는, effectMenu 내부에서 캔버스를 더이상 사용하지 않습니다.
 * @deprecated
 *
 * @param canvas 그래프를 그릴 캔버스 (콘텍스트는 자동으로 2d로 가져옵니다.)
 * @param analyser 오디오 파형 값이 있는 아날라이저 노드
 * @param oscilloscopeColor 파형의 색깔
 */
const drawAudioGraph = (
  canvas: HTMLCanvasElement,
  analyser: AnalyserNode,
  oscilloscopeColor = 'white',
) => {
  if (!canvas) return;

  /** 8비트 정수의 최댓값 (getByteTimeDomainData) */
  const MAX_NUM_UINT8 = 255;

  /** float 값의 최대값 (getFloatTimeDomainData) */
  const MAX_NUM_FLOAT = 1;

  // 새로운 버퍼 배열을 만든 후 거기다가 주파수 값에 해당하는 바이트를 복사합니다.
  let buffer = new Uint8Array(analyser.fftSize);
  analyser.getByteTimeDomainData(buffer);

  let context = canvas.getContext('2d');
  if (!context) return;

  // 캔버스를 지움
  context.clearRect(0, 0, canvas.width, canvas.height);

  // 검은색 배경으로 다시 칠하기
  context.fillStyle = '#222222';
  context.fillRect(0, 0, canvas.width, canvas.height);

  // 파형을 그릴 선의 색과 선너비 결정
  context.strokeStyle = oscilloscopeColor;
  context.lineWidth = 2;

  // 선 그리기 시작
  context.beginPath();
  context.moveTo(-1, canvas.height / 2);
  for (let i = 1; i < buffer.length; i++) {
    let x = (i / buffer.length) * canvas.width;
    let y = (buffer[i] / MAX_NUM_UINT8) * canvas.height;
    context.lineTo(x, y);
  }
  context.stroke();
};

interface EffectMenuProps {}
const mobile = isMobile();

export const EffectMenu = observer(({}: EffectMenuProps) => {
  const [params, setParams] = useSearchParamStore();
  const { uiStore, videoStore, timelineStore } = useStores();
  const q = params.q ?? '';
  const [expand, setExpand] = useState(false);
  const effects = useEffects();
  const originalAudioCanvas = useRef<HTMLCanvasElement>(null);
  const animationId = useRef<number>();

  const onClickEffect = async (x: IEffect) => {
    // const last = Math.max(0, ...timelineStore.effects.map(x => x.end));
    const last = 0; // 지금은 이펙트가 영상의 0초부터 시작해야 하므로, 이 값을 0으로 지정함.

    // if (!isTestMode()) {
    //   timelineStore.clearEffects();
    // }

    timelineStore.addEffect({
      effect: x!,
      group: 0,
      start: last,
      end: videoStore.getDuration() != null ? last + videoStore.getDuration() : last + 1, 
      // end: last + (isTestMode() ? 30 : videoStore.getDuration()),
    });

    if (mobile) {
      uiStore.showSidebar = false;
    }
  };

  useEffect(() => {
    if (!videoStore.isReady) {
      return;
    }

    if (timelineStore.hoveredEffect) {
      videoStore._updateMixer(
        timelineStore.backgroundSounds,
        [
          {
            id: '0',
            effect: timelineStore.hoveredEffect,
            group: 0,
            start: 0,
            end: videoStore.getDuration(),
          },
        ],
        true,
      );
    } else {
      videoStore._updateMixer(
        timelineStore.backgroundSounds,
        timelineStore.effects,
        true,
      );
    }
  }, [timelineStore.hoveredEffect]);

  const animation = () => {
    if (originalAudioCanvas.current && videoStore.mixer) {
      drawAudioGraph(
        originalAudioCanvas.current,
        videoStore.mixer.analyserOriginal,
        'white',
      );
    }

    // 캔버스가 있을 때만 에니메이션 호출되도록 변경
    if (originalAudioCanvas.current) {
      animationId.current = requestAnimationFrame(animation);
    }
  };

  useEffect(() => {
    animation();
    return () => {
      cancelAnimationFrame(animationId.current!);
    };
  }, []);

  return (
    <Container expand={expand}>
      {/* 이펙트의 그래프 기능은 제거되었습니다. 더이상 사용되지 않습니다. */}
      {/* {mobile ? null : 
        <OriginalAudioGraph style={{ display: mobile ? 'none' : 'block' }}>
          <canvas
            width={512}
            height={256}
            ref={originalAudioCanvas}
            style={{ width: '100%', height: '100%' }}
          />
        </OriginalAudioGraph>
      } */}
      <Header>
        <Search value={q} onChange={newQ => setParams({ q: newQ })} />
      </Header>
      <Depth1>
        {effects
          .filter(x => x.name.includes(q))
          .map((x, index) => (
            <EffectItem
              key={index}
              data={x}
              onClick={effect => onClickEffect(effect)}
            />
          ))}
      </Depth1>
    </Container>
  );
});

interface EffecItemProps {
  data: any;
  onClick: (effect: IEffect) => void;
}
const EffectItem = ({ data, onClick }: EffecItemProps) => {
  const { timelineStore } = useStores();
  const [hovered, setHovered] = useState(false);
  const [index, setIndex] = useState(0);

  const analyser = videoStore.analyser;
  const canvas = useRef<HTMLCanvasElement>(null);
  const ctx = canvas.current?.getContext('2d');
  const animationRef = useRef<number>(0);

  const onClickNext = () => {
    const nextIndex = (index + 1) % data.nodes.length;
    setIndex(nextIndex);

    if (hovered) {
      onHover({ ...data, nodes: data.nodes?.[nextIndex].nodes });
    }
  };

  const onHover = (effect: any) => {
    timelineStore.hoveredEffect = effect;
  };

  const onMouseEnter = () => {
    setHovered(true);
    onHover({ ...data, nodes: data.nodes?.[index].nodes });
  };
  const onMouseLeave = (e: any) => {
    setHovered(false);
    timelineStore.hoveredEffect = null;
  };

  /** 이 객체에서 사용하는 캔버스 내의 모든 내용을 지웁니다. */
  const canvasClear = () => {
    if (!canvas.current || !ctx) return;

    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
  };

  /**
   * 에니메이션을 지속할지를 결정합니다. 마우스에 올려진 순간동안만 애니메이션을 출력합니다.
   *
   * 에니메이션이 취소되면 캔버스에 그려져있던것은 모두 지워집니다.
   * @deprecated
   */
  const animationContinueCheck = () => {
    if (hovered) {
      let animation = () => {
        if (!canvas.current) return;
        if (!videoStore.mixer?.analyserEffect) return;

        drawAudioGraph(
          canvas.current,
          videoStore.mixer?.analyserEffect,
          'yellow',
        );
        animationRef.current = requestAnimationFrame(animation);
      };
      animation();
    } else {
      canvasClear();
      cancelAnimationFrame(animationRef.current);
    }
  };

  useEffect(() => {
    animationContinueCheck();
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [hovered]);

  return (
    <EffectContainer
      key={data.thumbnail}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <EffectImage
        src={data.nodes[index].thumbnail}
        onClick={() => onClick({ ...data, nodes: data.nodes[index].nodes })}
      />
      {/* <canvas
        ref={canvas}
        width={512}
        height={256}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          cursor: 'pointer',
          opacity: 0.72,
        }}
        onClick={() => onClick({ ...data, nodes: data.nodes[index].nodes })}
      /> */}
      <EffectName>{data.name}</EffectName>

      {/* {hovered && (
        <EffectHeader>
          <EffectIcon src={BackIcon} onClick={() => setIndex(0)} />
          <div style={{ flex: 1 }} />
          <EffectIcon src={RefreshIcon} onClick={onClickNext} />
          <EffectIcon src={HeartIcon} />
        </EffectHeader>
      )} */}
    </EffectContainer>
  );
};

const Container = styled.div<any>`
  position: relative;
  display: grid;
  grid-template-rows: max-content 1fr;

  height: 100%;

  overflow: hidden;
`;

/** 원본 오디오를 표시할 그래프 영역 */
const OriginalAudioGraph = styled.div`
  position: sticky;
  height: 180px;
`;

const Header = styled.div`
  background: black;

  padding: 8px;
  padding-bottom: 0px;
`;

const Depth1 = styled.div`
  width: 100%;
  height: 100%;

  gap: 8px;

  background: black;

  padding: 8px;

  overflow: auto;

  box-sizing: border-box;
`;

const EffectImage = styled.img`
  width: 100%;
  height: 180px;

  border-radius: 8px;

  object-fit: cover;
  transition: all 0.2s ease;
  cursor: pointer;

  ${NonSelectable}

  :hover {
    filter: contrast(1.2);
    transform: scale(0.995);
  }
`;
const EffectContainer = styled.div`
  position: relative;

  ${NonSelectable}
`;
const EffectName = styled.div`
  position: absolute;

  left: 8px;
  bottom: 8px;

  color: white;

  border-radius: 8px;
  background: rgba(0, 0, 0, 0.7);

  padding: 4px 8px;
`;
const EffectHeader = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 16px);

  display: flex;
  gap: 8px;

  padding: 8px;

  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  padding-bottom: 10px;

  z-index: 1;
`;

const EffectIcon = styled.img`
  width: 24px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
