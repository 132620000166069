import React from 'react';
import { Upload as UploadIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled, { css } from 'styled-components';

import { Graph } from 'component/graph';
import { Video } from 'component/video';
import { useSearchParamStore } from 'store/router';
import { Timeline } from 'component/timeline';

interface PlaygroundProps {}
export const Playground = observer(({}: PlaygroundProps) => {
  const { videoStore } = useStores();

  // Sidebar.Desktop의 expand여부에 따라 화면 크기를 조절하기 위해
  // 해당 변수값들을 받아오고 이를 css에 적용합니다.
  // 참고: 지금은 사용하지 않음
  /** @deprecated */
  const [params, setParams] = useSearchParamStore();
  const menu = params.menu;
  const expand = !!menu;

  const onDrop = (e: any) => {
    const files = e.dataTransfer.files;
    e.stopPropagation();
    e.preventDefault();

    // 파일이 제대로 등록된 경우에만, 비디오 변경 여부 표시
    if (files[0] == null) return;

    if (files[0].type === 'image/png') {
      // Music 미리듣기르 위한 드래그를 했을 때, png이미지가 드래그되므로
      // 동영상 전환 기능을 막기 위해, image/png면 아무 일도 없음.
      return;
    }

    if (!files[0].type.startsWith('video')) {
      alert(`'${files[0].name}' is not a valid video file!`);
      return;
    }
    
    if (videoStore.video != null) {
      if (
        confirm('Do you want to stash current changes and import new video?')
      ) {
        videoStore.importVideoFromFile(files[0]);
      }
    } else {
      videoStore.importVideoFromFile(files[0]);
    }
  };
  const onDragOver = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  return (
    <Container expand = {expand}>
      {!videoStore.video && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#999',
          }}
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <PleaseImportText>Please import video.</PleaseImportText>
          <IconButton
            size="large"
            color="inherit"
            title="Import"
            onClick={() => videoStore.importVideo()}
          >
            <UploadIcon />
          </IconButton>
        </div>
      )}
      {!!videoStore.video && (
        <>
          <div
            onDrop={onDrop}
            onDragOver={onDragOver}
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '100%',
            }}
          >
            <Graph />
            <Video />
            <Timeline />
          </div>
        </>
      )}
    </Container>
  );
});

// Sidebar.Desktop처럼 sidebar의 메뉴가 확장되어있는지 여부를 조사하여 가로 너비를 재설정합니다.
const Container = styled.div<any>`
  position: relative;
  display: flex;
  flex: 1;

  gap: 50px;

  align-items: center;
  justify-content: center;

  padding: 10px 10px;
`;
const PleaseImportText = styled.div`
  font-size: 20px;
  font-weight: bold;

  color: #999;
`;

// ${({ expand }) =>
//   expand
//     ? css`
//         width: calc(100% - 390px + 64px);
//       `
//     : css`
//         width: calc(100% - 64px + 64px);
//       `}
