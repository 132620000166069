import { isNil, uniqueId } from 'lodash-es';
import { DateTime } from 'luxon';
import { observable } from 'mobx';
import { EffectTypes, IBackgroundSound, IEffect } from 'model';
import { useStores } from 'store';

import { uiStore } from './ui';
import { videoStore } from './video';

export interface IAppliedEffect {
  id: string;

  effect: IEffect;

  group: number;
  start: number;
  end: number;
}

export interface ITimelineStore {
  effects: IAppliedEffect[];
  backgroundSounds: IBackgroundSound[];
  hoveredEffect: IEffect | null;

  _scheduledUpdate: boolean;

  getCurrentEffect(): EffectTypes | null;

  save(): object;
  load(json: any): void;

  addEffect(effect: Omit<IAppliedEffect, 'id'>): void;
  removeEffect(id: string): void;
  clearEffects(): void;

  addBackgroundSound(sound: Omit<IBackgroundSound, 'id'>): void;
  removeBackgroundSound(id: string): void;
  _updateBackgroundSound(): Promise<void>;

  reorder(): void;
}
export const timelineStore = observable({
  effects: [],
  backgroundSounds: [],
  hoveredEffect: null,

  _scheduledUpdate: false,

  getCurrentEffect() {
    const { videoStore } = useStores();
    const time = videoStore.time;

    for (const e of this.effects) {
      if (e.start <= time && e.end > time) return e;
    }
    return null;
  },

  save() {
    return {
      backgroundSounds: this.backgroundSounds,
      effects: this.effects,
    };
  },
  load(json: any) {
    if (isNil(json.backgroundSounds) || isNil(json.effects)) {
      return;
    }

    this.backgroundSounds = json.backgroundSounds;
    this.effects = json.effects;

    // 클라우드에서 음악과 이펙트를 불러오면 해당 내용을 갱신
    this._updateBackgroundSound();
  },

  addEffect(effect: Omit<IAppliedEffect, 'id'>) {
    this.effects = [...this.effects, { id: uniqueId(), ...effect }];
    this._updateBackgroundSound();
  },
  removeEffect(id: string) {
    this.effects = this.effects.filter(x => x.id !== id);
    this.reorder();
    this._updateBackgroundSound();
  },
  async clearEffects() {
    this.effects = [];

    await this._updateBackgroundSound();
  },

  addBackgroundSound(sound: Omit<IBackgroundSound, 'id'>) {
    this.backgroundSounds.push({ id: uniqueId(), ...sound });
    this._updateBackgroundSound();
  },
  removeBackgroundSound(id: string) {
    this.backgroundSounds = this.backgroundSounds.filter(x => x.id !== id);
    this._updateBackgroundSound();
  },

  async _updateBackgroundSound() {
    if (this._scheduledUpdate) {
      return false;
    }

    this._scheduledUpdate = true;

    queueMicrotask(async () => {
      this._scheduledUpdate = false;

      try {
        uiStore.showLoading();
        await videoStore._updateMixer(this.backgroundSounds, this.effects);
      } finally {
        uiStore.hideLoading();
      }
    });
  },

  reorder() {
    let offset = 0;
    for (const e of this.effects) {
      const len = e.end - e.start;
      e.start = offset;
      e.end = offset + len;
      offset = e.end;
    }
  },
} as ITimelineStore);
