import React, { useState } from 'react';
import {
  Person as LoginIcon,
  SwitchVideo,
  Upload as UploadIcon,
} from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled from 'styled-components';

import SaveIcon from 'asset/header/save.svg';
import LogoImage from 'asset/logo.png';

import { getCookie } from 'util/cookie';
import { request } from 'util/http-client';
import { isMobile } from 'util/mobile';
import { isTestMode } from 'util/testmode';
import { SaveModal } from 'component/popup';
import { HeaderIcon, ShareButton, YoutubeUploadButton } from './buttons';

interface HeaderProps {}

export const Header = observer(({}: HeaderProps) => {
  const { videoStore, timelineStore, userStore, uiLoading, uiToast } =
    useStores();
  const [saving, setSaving] = useState(false);

  const onUploadVideo = async () => {
    if (!!videoStore.video) {
      if (
        !confirm('Do you want to stash current changes and import new video?')
      ) {
        return;
      }
    }
    await videoStore.importVideo();
  };

  const onSaveVideo = async () => {
    await videoStore.exportVideo();
  };

  const onShareVideo = async () => {
    if (window.location.href.includes('web.app')) {
      onSaveVideo();
      return;
    }

    uiLoading.showLoading();

    try {
      const { fileId } = await videoStore.uploadVideo();

      if (isMobile()) {
        const url = `https://${window.location.host}/video/${fileId}`;
        uiToast.showLoading(url);

        return;
      }

      const memberId = getCookie('SU_TOKEN');

      if (!memberId) {
        alert('User Auth Invalid! Try Again Please.');
        window.location.href = 'https://surroundio.org';
      }

      const uploadedS3VideoUrl = `${process.env.REACT_APP_CF_ENDPOINT}/videos/${fileId}.mp4`;
      const thumbnailUrl = `${process.env.REACT_APP_CF_ENDPOINT}/thumbnails/${fileId}.png`;

      const data = await request('POST', '/history/register', {
        videoUrl: uploadedS3VideoUrl,
        title: videoStore.video.name,
        description: timelineStore.effects[0]?.effect?.name ?? '',
        bgm: timelineStore.backgroundSounds[0]?.name ?? '',
        data: JSON.stringify(timelineStore.save()),
        thumbnailUrl,
      });

      if (data.success) {
        const url = `https://${window.location.host}/video/${fileId}`;
        uiToast.showLoading(url);

        //window.open(`http://${window.location.host}/video/${fileId}`, "_blank");

        alert('Upload completed. Please check your Cloud');
      }
    } catch (error) {
      alert('A temporary error has occurred.');
      //window.location.reload();
    } finally {
      uiLoading.hideLoading();
    }
  };

  return (
    <Container>
      <SAppBar>
        <Toolbar>
          <AppTitle
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            onClick={() => (window.location.href = 'https://surroundio.org/')}
          >
            <img
              src={LogoImage}
              style={{ width: '180px', transform: 'translateY(5px)' }}
            />
          </AppTitle>

          <Box sx={{ flexGrow: 1 }} />

          <IconContainer>
            {isNil(userStore.user) ? (
              <IconButtonWithBackground
                size="large"
                color="inherit"
                title="Login"
                onClick={() =>
                  (window.location.href = 'https://surroundio.org/')
                }
              >
                <LoginIcon />
              </IconButtonWithBackground>
            ) : (
              <NicknameText
                onClick={() =>
                  (window.location.href = 'https://surroundio.org/')
                }
              >
                {userStore.user.name}
                {userStore.user.level !== 0 ? <b>&nbsp;(PRO)</b> : <></>}
              </NicknameText>
            )}

            {/* 비디오가 불러왔냐 아니냐에 따라, 이 아이콘의 모양과 설명이 import 또는 switch Video로 변경됩니다.*/}
            <IconButtonWithBackground
              size="large"
              color="inherit"
              title={videoStore.video == null ? 'Import' : 'Switch Video'}
              onClick={onUploadVideo}
            >
              {videoStore.video == null ? <UploadIcon /> : <SwitchVideo />}
            </IconButtonWithBackground>

            <ShareButton />

            <HeaderIcon
              src={SaveIcon}
              onClick={isTestMode() ? onSaveVideo : onShareVideo}
            />
          </IconContainer>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <YoutubeUploadButton />
          </Box>
        </Toolbar>

        <SaveModal show={saving} onClose={() => setSaving(false)} />
      </SAppBar>
    </Container>
  );
});

const Container = styled(Box).attrs({
  sx: { flexGrow: 1 },
})`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiToolbar-root {
    height: 100%;
  }
`;

const SAppBar = styled(AppBar).attrs({
  position: 'static',
})`
  height: 64px;
  background-color: #59cbfc;
`;

const AppTitle = styled(Typography).attrs({
  component: 'div',
})`
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  // gap: 18px;

  align-items: center;

  width: 90%;
  height: 100%;
  justify-content: end;
`;

const IconButtonWithBackground = styled(IconButton)`
  width: 42px;
  height: 42px;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  margin-right: 10px;
`;

const NicknameText = styled.div`
  color: white;

  font-size: 14px;
  font-weight: bold;

  cursor: pointer;

  margin-right: 20px;

  /*
  position: absolute;
  left: 20px;
  */
`;
