import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Slider,
  TextField,
  
} from '@mui/material';
import styled from 'styled-components';

export type audioClipSettingModalVer2ChangeData = {
  start: number, end: number, rangeStart: number, rangeEnd: number, fadeIn: number, fadeOut: number, volume: number
}

interface AudioClipSettingModalProps {
  /** 모달을 보여주는 용도 */ show: boolean;
  /** 오디오의 최대 시간 */ maxAudioTime: number;
  /** 비디오의 최대 시간 */ maxVideoTime: number;
  /** 음악의 재생이 시작하는 시점 (비디오 길이 기준) */ start: number;
  /** 음악의 재생이 끝나는 지점 (비디오 길이 기준) */ end: number;
  /** 음악의 재생을 시작할 내부 지점 (오디오 길이 기준) */ rangeStart: number;
  /** 음악의 재생을 끝낼 내부 지점 (오디오 길이 기준) */ rangeEnd: number;
  /** 페이드 인 시간 */ fadeIn: number;
  /** 페이드 아웃 시간 */ fadeOut: number;
  /** 음악의 볼륨 */ volume: number;
  onClose: () => void;
  onChange: (args: audioClipSettingModalVer2ChangeData) => void;
  onRemove: () => void;
}
export const AudioClipSettingModalVer2 = ({
  show,
  maxAudioTime: initialMaxAudioTime,
  maxVideoTime: initialMaxVideoTime,
  start: initialStart,
  end: initialEnd,
  rangeStart: initialRangeStart,
  rangeEnd: initialRangeEnd,
  volume: initialVolume,
  fadeIn: initialFadeIn,
  fadeOut: initialFadeOut,
  onClose,
  onChange,
  onRemove,
}: AudioClipSettingModalProps) => {
  // 상태 갱신용 및 데이터 전달용
  const maxAudioTime = useRef(initialMaxAudioTime);
  const maxVideoTime = useRef(initialMaxVideoTime);
  const [start, setStart] = useState(initialStart);
  const [end, setEnd] = useState(initialEnd);
  const [volume, setVolume] = useState(initialVolume ?? 1);
  const [rangeStart, setRangeStart] = useState(initialRangeStart);
  const [rangeEnd, setRangeEnd] = useState(initialRangeEnd);
  const [fadeIn, setFadeIn] = useState(initialFadeIn);
  const [fadeOut, setFadeOut] = useState(initialFadeOut);

  const durationToMinuteSecond = (value: number) => {
    let minute = Math.floor(value / 60);
    let second = value % 60;

    return minute + ':' + second.toFixed(0).padStart(2, '0');
  };

  /** 시작과 끝 값을 동시에 입력합니다. 이 함수는 값을 보정하기 위해 사용합니다. */
  const setStartEndValue = (start: number, end: number) => {
    if (start < 0) start = 0
    if (end < 0) end = 0
    if (start > maxVideoTime.current) start = maxVideoTime.current
    if (end > maxVideoTime.current) end = maxVideoTime.current

    if (start < end) {
      setStart(start)
      setEnd(end)
    } else {
      setStart(end)
      setEnd(end)
    }
  }

  /** 음악 재생의 시작과 끝 값을 동시에 입력합니다. 이 함수는 값을 보정하기 위해 사용합니다. */
  const setRangeValue = (rangeStart: number, rangeEnd: number) => {
    if (rangeStart < 0) rangeStart = 0
    if (rangeEnd < 0) rangeEnd = 0
    if (rangeStart > maxAudioTime.current / 2) rangeStart = Math.floor(maxAudioTime.current / 2)
    if (rangeEnd > maxAudioTime.current / 2) rangeEnd = Math.floor(maxAudioTime.current / 2)

    if (rangeStart < rangeEnd) {
      setRangeStart(rangeStart)
      setRangeEnd(rangeEnd)
    } else {
      setRangeStart(rangeEnd)
      setRangeEnd(rangeStart)
    }
  }

  /** 페이드 시간을 설정합니다. */
  const setFadeValue = (fadeIn: number, fadeOut: number) => {
    if (fadeIn < 0) fadeIn = 0
    if (fadeIn > 5) fadeIn = 5
    if (fadeOut < 0) fadeOut = 0
    if (fadeOut > 5) fadeOut = 5
    
    setFadeIn(fadeIn)
    setFadeOut(fadeOut)
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={{ width: 400, background: 'white' }}>
      <div>
        <div>Video Time {durationToMinuteSecond(start)} ~ {durationToMinuteSecond(end)} | max: {maxVideoTime.current} </div>
        <div style={{padding: '8px'}}>
          <TextField 
            type='number' 
            size='small' 
            color='secondary' 
            focused
            onChange={(e) => setStartEndValue(Math.floor(+e.target.value), end)}
            value={start}
            sx={{width:'30%'}}
          />
          <TextField 
            type='number' 
            size='small' 
            color='secondary' 
            focused
            onChange={(e) => setStartEndValue(start, Math.floor(+e.target.value))}
            value={end}
            sx={{width:'30%'}}
          />
        </div>

        <div>Muisc Time {durationToMinuteSecond(rangeStart)} ~ {durationToMinuteSecond(rangeEnd)} | max: {maxAudioTime.current}</div>
        <div style={{padding: '8px'}}>
          <TextField 
            type='number' 
            size='small' 
            color='secondary' 
            focused
            onChange={(e) => setRangeValue(Math.floor(+e.target.value), rangeEnd)}
            sx={{width:'30%'}}
            value={rangeStart}
          />
          <TextField 
            type='number' 
            size='small' 
            color='secondary' 
            focused
            onChange={(e) => setRangeValue(rangeStart, Math.floor(+e.target.value))}
            sx={{width:'30%'}}
            value={rangeEnd}
          />
        </div>

        <div>Fade In / out Time | max: 5 </div>
        <div>not exceed 1/2 musicTime</div>
        <div style={{padding: '8px'}}>
          <TextField
            type='number'
            size='small'
            color='info'
            focused
            onChange={(e) => setFadeValue(Math.floor(+e.target.value), fadeOut)}
            sx={{width:'30%'}}
            value={fadeIn}
          />
          <TextField
            type='number'
            size='small'
            color='info'
            focused
            onChange={(e) => setFadeValue(fadeIn, Math.floor(+e.target.value))}
            sx={{width:'30%'}}
            value={fadeOut}
          />
        </div>

        <div>Volume</div>
        <div style={{ padding: '8px' }}>
          <Slider
            value={volume}
            onChange={(e, newVolume) => setVolume(+newVolume)}
            min={0}
            max={1}
            step={0.01}
          />
        </div>

        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ flex: 1 }} />
            <Button
              color="error"
              variant="text"
              onClick={() => {
                onRemove();
                onClose();
              }}
            >
              Delete
            </Button>
          <div style={{ width: 12 }} />
            <Button
              variant="text"
              onClick={() => {
                onChange({
                  start: start,
                  end: end,
                  rangeStart: rangeStart,
                  rangeEnd: rangeEnd,
                  fadeIn: fadeIn,
                  fadeOut: fadeOut,
                  volume: volume
                });
                onClose();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
