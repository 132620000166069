import React from 'react';
import {
  Routes as ReactRoutes,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';

import { RenderPage } from 'page/RenderPage';
import { VideoPlayerPage } from 'page/VideoPlayerPage';
import App from './App';

export const Routes = () => {
  return (
    <Router>
      <ReactRoutes>
        <Route path="/" element={<App />} />
        <Route path="/render" element={<RenderPage />} />
        <Route path="/video/:id" element={<VideoPlayerPage />} />
      </ReactRoutes>
    </Router>
  );
};
