import { get } from 'lodash-es';
import { observable } from 'mobx';
import { IEffect, IUser } from 'model';

import { getCookie } from 'util/cookie';
import { request } from 'util/http-client';

export interface IUserStore {
  user: IUser | null;

  isFreeUser: boolean;

  getUserInfo(): Promise<IUser>;
}
export const userStore = observable({
  user: null,

  get isFreeUser() {
    const type = get(this, 'user.paytype');
    return !type || type === 'free';
  },

  async getUserInfo() {
    const memberId = getCookie('SU_TOKEN');
    if (!memberId) {
      return null;
    }

    const data = await request('GET', '/user/info', {});
    const user = data.data;

    this.user = {
      ...user,
      name: user.name ?? user.email.split('@')[0],
    };

    return this.user;
  },
} as IUserStore);
