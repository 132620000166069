import React, { useEffect, useRef, useState } from 'react';
import { useObserver } from 'mobx-react';
import { useStores } from 'store';
import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as Close } from '../../asset/icon/close-outline.svg';

interface ToastPropsInter {
  title?: string;
  block?: boolean;
  buttonText?: string;
  copyButton?: string;
  url?: string;
}

const Toast = (setting: ToastPropsInter) => {
  /**
   *  Component ToastComplete Height 값을 조절하시면 팝업 전체 높이를 조절하실 수 있습니다.
   *
   *
   */
  const { uiHistory, uiToast } = useStores();

  const urlInputRef = useRef<HTMLInputElement>(null);

  const {
    title = 'Upload Completed',
    block = false,
    buttonText = 'Check it at the Cloud',
    copyButton = 'URL Copy',
    url,
  } = setting;

  const [isBlock, setIsBlock] = useState(false);
  const [open, setIsOpen] = useState(false);

  const closeOnClickHandle = () => {
    uiToast.hideLoading();
  };
  const copyUrlOnClickHandle = () => {
    copyClipBoar();
  };
  const openHistoryOnClickHandle = () => {
    uiToast.hideLoading();
    uiHistory.showLoading();
  };

  function copyClipBoar() {
    const text = urlInputRef.current ? urlInputRef.current!.value : '';

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setIsOpen(true);
          setTimeout(() => {
            setIsOpen(false);
          }, 1000);
        })
        .catch(() => {
          console.log('failed');
        });
    } else {
      fallback(text);
      setIsOpen(true);
      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    }
  }

  const fallback = (url: string) => {
    const isIos = navigator.userAgent.match(/ipad|iphone/i);
    const textarea = document.createElement('textarea');

    // create textarea
    textarea.value = url;

    // ios will zoom in on the input if the font-size is < 16px
    textarea.style.fontSize = '20px';
    document.body.appendChild(textarea);

    // select text
    if (isIos) {
      const range = document.createRange();
      range.selectNodeContents(textarea);

      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
      textarea.setSelectionRange(0, 999999);
    } else {
      textarea.select();
    }

    // copy selection
    document.execCommand('copy');

    // cleanup
    document.body.removeChild(textarea);
  };

  useEffect(() => {
    if (uiToast.loading == true && uiHistory.loading == true) {
      uiHistory.hideLoading();
    }

    setIsBlock(block);
  });

  return (
    <Container isBlock={isBlock}>
      <BackgroundBox />
      {open ? (
        <ToastCompletePopup isOpen={open}>
          <span>Copy !</span>
        </ToastCompletePopup>
      ) : (
        ''
      )}

      <ToastComplete>
        <CloseButton onClick={closeOnClickHandle} />

        <ContentBox>
          <h3>{title}</h3>

          <button onClick={openHistoryOnClickHandle}>{buttonText}</button>

          <div className="content" onClick={copyUrlOnClickHandle}>
            <input type="text" value={url || ''} ref={urlInputRef} readOnly />
            <span>{copyButton}</span>
          </div>
        </ContentBox>
      </ToastComplete>
    </Container>
  );
};

export default Toast;

const Container = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  justify-content: center;

  z-index: 99;

  ${({ isBlock }) =>
    isBlock == true
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

const CloseButton = styled(Close)`
  width: 30px;
  position: absolute;
  top: 10px;
  right: 20px;
`;

const BackgroundBox = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: black;
  opacity: 0.5;
`;

const ToastComplete = styled.div`

    position: absolute;
    bottom: 0;

    width: 100%;
    height: 30%;
    background-color: #223349;

    z-index: 999;

    border-radius: 30px 30px 0 0;

    display; flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

`;

const ContentBox = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    width: 20%;
    height: 25px;
    background-color: #556975;
    border-radius: 30px;

    margin-top: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: white;
      font-weight: bold;
    }

    input {
      display: none;
    }
  }

  span {
    font-weight: bold;
    font-size: 12px;
    color: #59cbfc;
  }

  h3 {
    color: white;
  }

  button {
    width: 80%;
    height: 50px;
    border: none;
    border-radius: 30px;
    background-color: #3ea3ce;
    color: white;

    box-shadow: 0px 0px 20px 0px #6c6c6c;

    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const animationShow = keyframes`
    from {
        opacity: 0;
        transform: translateY(0);
    }
    to { opacity: 1 }
`;

const animationHidden = keyframes`
    from {
        opacity: 1;
    }
    to { opacity: 0 }
`;

const ToastCompletePopup = styled.div<any>`
  display: none;

  width: fit-content;
  height: 5px;

  padding: 10px;

  display: flex;

  justify-content: center;
  align-items: center;

  border-radius: 30px;
  color: white;

  font-weight: bold;
  font-size: 0.9rem;

  background-color: #50ba50;

  transform: translateY(100%);

  z-index: 9;

  margin-top: 40px;

  ${({ isOpen }) =>
    isOpen == true
      ? css`
          display: flex;
          animation-name: ${animationShow};
          animation-duration: 1s;
        `
      : css`
          animation-name: ${animationHidden};
          animation-duration: 0.4s;
        `}

  animation-fill-mode: both;
`;
