import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Header } from 'component/header';
import { HistoryDesktop } from 'component/history/HistoryDesktop';
import { Playground } from 'component/playground';
import { Sidebar } from 'component/sidebar';
import { Timeline } from 'component/timeline';
import 'react-timelines2/lib/css/style.css';
import { isEmpty } from 'lodash-es';
import { useStores } from 'store';

import { ChatPrompt } from 'component/chat';
import { useSearchParamStore } from 'store/router';
import { Overlay } from './app/Overlay';

export const AppDesktop = observer(() => {
  const { uiHistory, uiStore } = useStores();
  const [{ menu }] = useSearchParamStore();

  return (
    <Container>
      <Header />
      <AppContainer expandSidebar={!isEmpty(menu)}>

        <Sidebar />
        <RightContainer>
          <Playground />
        </RightContainer>
      </AppContainer>

      <HistoryDesktop show={uiHistory.loading} />
      <Overlay />

      {uiStore.showChatPrompt && <ChatPrompt />}
    </Container>
  );
});

const Container = styled.div`
  height: calc(100vh - 64px);
`;
const AppContainer = styled.div<{ expandSidebar: boolean }>`
  position: relative;
  height: calc(100vh - 64px);

  display: grid;
  grid-template-columns: ${({ expandSidebar }) =>
    expandSidebar ? '390px 1fr' : '64px 1fr'};

  overflow: hidden;

  > div {
    height: calc(100vh - 64px);
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
