import { useEffect, useState } from 'react';
import { useStores } from 'store';
import styled, { css } from 'styled-components';

import { uiPopup } from 'store/ui';

interface PopupPropsInter {
  header?: string;
  content?: string;
  block: boolean;
  confirmButtonOnClick?(type: boolean): void;
}

const Confirm = (setting: PopupPropsInter) => {
  const { header, content, block, confirmButtonOnClick } = setting;
  const [isBlock, setIsBlock] = useState(false);

  useEffect(() => {
    setIsBlock(block);
  }, [block]);

  return (
    <Container isBlock={isBlock}>
      <BackgroundOpacity onClick={() => confirmButtonOnClick!(false)}/>

      
      <LightBox>
        <Header>
          <span>{uiPopup.title ? uiPopup.title : header}</span>
        </Header>

        <Content>
          <span>{uiPopup.content ? uiPopup.content : content}</span>
        </Content>

        <Bottom>
          <button onClick={() => confirmButtonOnClick!(true)}>Ok</button>
          <button onClick={() => confirmButtonOnClick!(false)}>Cancle</button>
        </Bottom>
      </LightBox>
    </Container>
  );
};

export default Confirm;

const Container = styled.div<any>`
  width: 100%;
  height: 100%;

  z-index: 4;

  position: absolute;

  display: flex;
  justify-content: center;

  ${({ isBlock }) =>
    isBlock == true
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

const BackgroundOpacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.5;
`;

const LightBox = styled.div`
  position: absolute;
  top: 20%;

  width: 300px;
  height: 200px;
  background-color: white;
  border-radius: 10px;

  z-index: 5;
`;

const Header = styled.div`
  width: 100%;
  height: 50px;
  background-color: #484e51;

  border-radius: 5px 5px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: white;
    font-weight: bold;
    font-size: 1rem;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 120px;
  background-color: #222425;

  white-space: break-spaces;

  box-sizing: border-box;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 1rem;
  font-weight: bold;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    text-align: center;
  }
`;

const Bottom = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e6875;
  border-radius: 0 0 5px 5px;
  border-top: thin solid white;

  button {
    width: 48%;
    height: 70%;
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 1rem;
    color: white;

    &:hover {
      cursor: pointer;
      color: #d78080;
    }
    &:first-child {
      &:hover {
        color: #8383db;
      }
    }
    &:last-child {
      border-left: 2px solid white;
    }
  }
`;
