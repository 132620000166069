import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash-es';

export interface ISearchParamStore {
  q: string;
  menu: string | undefined;
}

export const useSearchParamStore = (): [
  ISearchParamStore,
  (newValue: Partial<ISearchParamStore>) => void,
] => {
  const [params, setParams] = useSearchParams();

  const value = useMemo(() => {
    //@ts-ignore
    return [...params.keys()].reduce((obj, value) => {
      obj[value] = params.get(value);
      return obj;
    }, {});
  }, [params]);

  return [
    value,
    (newValue: Partial<ISearchParamStore>) => {
      // 정황상 테스트 코드로 추정되지만, error로 정의한 의도를 알 수 없습니다.
      // 따라서 warn로 변경하겠습니다.
      console.warn('change', newValue);

      setParams(omitBy({ ...value, ...newValue }, x => x === undefined));
    },
  ];
};
